import { Action, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
interface CounterState {
    cardDetails: any,
    tripDetails: any
}

const initialState: CounterState = {
    cardDetails: {},
    tripDetails: {}
}

const tripCardSlice = createSlice({
    name: 'trip_card_details',
    initialState: initialState,
    reducers: {
        setCardDetails: (state, action) => {
            state.cardDetails = action.payload
        },
        setTripCardDetails: (state, action) => {
            state.tripDetails = action.payload
        }
    }
});

export default tripCardSlice.reducer;

export const {
    setCardDetails,
    setTripCardDetails
} = tripCardSlice.actions;
