export const LIVE_API = 'https://scm-api.abisaio.com/api/v1/'
export const QA_API = 'https://scm-api-qa.abisaio.com/api/v1/'
export const DEV_API = 'https://scm-api.abisaio-dev.com/api/v1/'
export const S2_API = 'https://s2api.abisaio.com/api/v1/'

export const SocketUrlDev = 'http://sthanave.com:3003/'
export const SocketUrlQa = 'https://shipmentsocket-qa.abisaio.com/'
export const SocketUrlPrd = 'https://shipso.abisaio.com/'

export const SocketUrl = window.location.origin == 'https://tportal.abisaio-dev.com' ? SocketUrlDev : (window.location.origin == 'https://tportal-qa.abisaio.com' ? SocketUrlQa : (window.location.origin == 'https://tms.abisaio.com' ? SocketUrlPrd : SocketUrlDev));

// export const PROXY = 'https://scm-api-qa.abisaio.com/'
// export const PROXY = 'https://scm-api.abisaio-dev.com/'
// export const PROXY = 'https://tportal.abisaio-dev.com/'
// export const PROXY = 'https://s2api.abisaio.com/'