import React, { useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { RootState } from "../../../../store/Index";
import { useSelector } from "react-redux";
import { get, post } from "../../../../config/services/api/ApiServices";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Col, Modal, Row } from "react-bootstrap";
import CommonColors from "../../../../common/colors/CommonColor";

const ReassignVehicle = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);

    var assignDetails = props.flag != 'assign' ? props.assignDetails : props.assignDetails?.item

    const [showError, setShowError] = React.useState(false)
    const [error, setError] = React.useState('')

    const [vehicleList, setVehicleList] = React.useState([]);
    const [driverNames, setDriversName] = React.useState([]);
    const [driverMobileNumber, setDriverMobileNumber] = React.useState([]);
    const [remark, setRemark] = React.useState(assignDetails?.remark || '')
    const [vehicleCapacity, setVehicleCapacity] = useState([])

    const [disable, setDisable] = React.useState(false)

    var vehicleDetails = {
        vehicleNumber: assignDetails?.vehicleNumber || '',
        driverName: assignDetails?.driverName || '',
        driverMobileNumber: assignDetails?.driverPhone || '',
        alternateDriverName: assignDetails?.alternativeDriver || '',
        alternateDriverMobileNumber: assignDetails?.alternativePhone || '',
        vehicleSize: assignDetails?.vehicleSize || ''
    }

    const [selectedVehicleNumber, setSelectedVehicleNumber] = React.useState<any>(vehicleDetails?.vehicleNumber != '' ? { name: vehicleDetails?.vehicleNumber } : {});
    const [selectedDriverName, setSelectedDriverName] = React.useState<any>(vehicleDetails?.driverName != '' ? { name: vehicleDetails?.driverName } : {});
    const [selectedAlternateDriverName, setSelectedAlternateDriverName] = React.useState<any>(vehicleDetails?.alternateDriverName != '' ? { name: vehicleDetails?.alternateDriverName } : {});
    const [selectedDriverMobileNumber, setSelectedDriverMobileNumber] = React.useState<any>(vehicleDetails?.driverMobileNumber != '' ? { name: vehicleDetails?.driverMobileNumber } : {});
    const [selectedDriverAlternateMobileNumber, setSelectedDriverAlternateMobileNumber] = React.useState<any>(vehicleDetails?.alternateDriverMobileNumber != '' ? { name: vehicleDetails?.alternateDriverMobileNumber } : {});

    const [selectedVehicleCapacity, setSelectedVehicleCapacity] = useState<any>({
        id: '',
        name: vehicleDetails?.vehicleSize || ''
    })

    React.useEffect(() => {
        getVehiclesNumbers()
        getDriverNames()
        getDriverMobileNumber()
    }, [])

    async function getVehiclesNumbers() {
        const path = 'AutoComplete/GetVehicleNumbers';
        const body = {
            "UserType": user.userType,
            "Page": 1,
            "Search": '',
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        if (response.succeeded) {
            var data = response.data.results;
            data.forEach((element: any) => {
                element.name = element.text
                element.id = element.id
            });
            setVehicleList(data);
        }
    }

    async function getDriverNames() {
        const path = 'AutoComplete/GetDriverNames';
        const body = {
            "TransporterID": user.transporterID,
            "Page": 1,
            "Name": '',
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        if (response.succeeded) {
            var data = response.data.results;
            data.forEach((element: any) => {
                element.name = element.text
                element.id = element.id
            });
            setDriversName(data);
        }
    }

    async function getDriverMobileNumber() {
        const path = 'AutoComplete/GetDriverMobileNumbers';
        const body = {
            "TransporterID": user.transporterID,
            "Page": 1,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        if (response.succeeded) {
            var data = response.data.results;
            data.forEach((element: any) => {
                element.name = element.text
                element.id = element.id
            });
            setDriverMobileNumber(data);
        }
    }


    const formatResult = (item: any) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const assignDriver = async () => {

        setDisable(true)

        const path = props.flag == 'assign' ? 'Trips/CreateTrip' : 'Trips/EditDriver';

        const body = props.flag == 'assign' ? {
            "biddingId": assignDetails.id,
            "transporterId": user.transporterID,
            "committedQuantity": assignDetails.totalDispatchQuantity,
            "balanceQuantity": assignDetails.balance,
            "requestType": "Requisition",
            "ApplicationType": "TMS",
            "vehicleSize": assignDetails.vehicleSize,
            "driverName": selectedDriverName.name || "",
            "phone": selectedDriverMobileNumber.name || "",
            "vehicleNumber": selectedVehicleNumber.name || "",
            "alternativeDriver": selectedAlternateDriverName ? (selectedAlternateDriverName.name || "") : "",
            "alternativePhoneNumber": selectedDriverAlternateMobileNumber ? (selectedDriverAlternateMobileNumber.name || "") : "",
        } : {
            "tripId": assignDetails.id,
            "userID": user.userID,
            "userType": user.userType,
            "requestType": "",
            "driverName": selectedDriverName?.name || '',
            "phone": selectedDriverMobileNumber?.name || '',
            "vehicleNumber": selectedVehicleNumber?.name || '',
            "alternativeDriver": selectedAlternateDriverName?.name || '',
            "alternativePhoneNumber": selectedDriverAlternateMobileNumber?.name || '',
            "remark": remark || '',
            "SpecProcessCode": selectedVehicleCapacity?.id || '',
            "Vehiclecapchange" : "Y"
        }

        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }

        var response = await post(path, body, header);
        setDisable(false)
        if (response.succeeded) {
            window.alert(response.message)
            props.closeModal()
        } else {
            window.alert(response.message)
        };
    }

    const handleClick = () => {
        if (!selectedVehicleNumber.hasOwnProperty('name') || selectedVehicleNumber?.name?.length != 10) {
            setError('Please enter valid vehicle number')
            setShowError(true)
        } else if (!selectedDriverName.hasOwnProperty('name')) {
            setError('Please enter driver\'s name')
            setShowError(true)
        } else if (!selectedDriverMobileNumber.hasOwnProperty('name') || selectedDriverMobileNumber?.name?.length != 10) {
            setError('Please enter valid driver\'s mobile number')
            setShowError(true)
        } else if (selectedDriverAlternateMobileNumber.hasOwnProperty('name') && selectedDriverAlternateMobileNumber?.name?.length != 10) {
            setError('Please enter valid alternate driver\'s mobile number')
            setShowError(true)
        } else {
            if (props.flag == 'assign') {
                assignDriver()
            } else {
                if (!remark) {
                    setError('Please enter a remark ')
                    setShowError(true)
                }else{
                    assignDriver()
                }
            }
        }
        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const RedDot = (title: any) => (
        <div className="d-flex mb-1">
            <h6 className="p-0 m-0 ">{title.title}</h6>
            <p className="p-0 m-0 text-danger">&nbsp;*</p>
        </div>
    )

    return (
        <div>
            {showError ? <div className="alert alert-danger">{error}</div> : <></>}
            <Row>
                <Col md={6} xs={12} className="mb-2">
                    <RedDot title={'Vehicle number'} />
                    <ReactSearchAutocomplete
                        items={vehicleList}
                        onSearch={(text: any) => setSelectedVehicleNumber({ name: text })}
                        onSelect={(text: any) => setSelectedVehicleNumber(text)}
                        formatResult={formatResult}
                        showIcon={false}
                        maxLength={10}
                        inputSearchString={selectedVehicleNumber?.name || ''}
                        placeholder='Enter vehicle number'
                        styling={{ borderRadius: '10', boxShadow: '0', zIndex: 10 }}
                    />
                </Col>
                <Col md={6} xs={12} className="mb-2">
                    <label htmlFor="vehicleNumber"> <RedDot title={'Enter driver\'s name'} /></label>
                    <ReactSearchAutocomplete
                        items={driverNames}
                        onSearch={(text: any) => setSelectedDriverName({ name: text })}
                        onSelect={(text: any) => setSelectedDriverName(text)}
                        formatResult={formatResult}
                        showIcon={false}
                        inputSearchString={selectedDriverName?.name || ''}
                        placeholder='Enter driver name'
                        styling={{ borderRadius: '10', boxShadow: '0', zIndex: 9 }}
                    />
                </Col>
                <Col md={6} xs={12} className="mb-2">
                    <label htmlFor="vehicleNumber"> <RedDot title={'Enter driver\'s mobile number'} /></label>
                    <ReactSearchAutocomplete
                        items={driverMobileNumber}
                        onSearch={(text: any) => {
                            const checkNumber = /^[0-9]+$/;
                            if (checkNumber.test(text)) {
                                setSelectedDriverMobileNumber({ name: text })
                            } else {
                                text.replace(/[a-z,A-Z,,./=+-_(*&^%$#)]/, ' ')
                            }
                        }}
                        onSelect={(text: any) => setSelectedDriverMobileNumber(text)}
                        formatResult={formatResult}
                        showIcon={false}
                        maxLength={10}
                        inputSearchString={selectedDriverMobileNumber?.name || ''}
                        placeholder='Enter driver mobile number'
                        styling={{ borderRadius: '10', boxShadow: '0', zIndex: 8}}
                    />
                </Col>
                <Col md={6} xs={12} className="mb-2">
                    <h6>Enter alternate driver's name</h6>
                    <ReactSearchAutocomplete
                        items={driverNames}
                        onSearch={(text: any) => setSelectedAlternateDriverName({ name: text })}
                        onSelect={(text: any) => setSelectedAlternateDriverName(text)}
                        formatResult={formatResult}
                        showIcon={false}
                        inputSearchString={selectedAlternateDriverName?.name || ''}
                        placeholder='Enter alternate driver name'
                        styling={{ borderRadius: '10', boxShadow: '0', zIndex: 7}}
                    />
                </Col>
                <Col md={6} xs={12} className="mb-2">
                    <h6>Enter alternate driver's mobile number</h6>
                    <ReactSearchAutocomplete
                        items={driverMobileNumber}
                        onSearch={(text: any) => {
                            const checkNumber = /^[0-9]+$/;
                            if (checkNumber.test(text)) {
                                setSelectedDriverAlternateMobileNumber({ name: text })
                            } else {
                                text.replace(/[a-z,A-Z,,./=+-_(*&^%$#)]/, ' ')
                            }
                        }}
                        onSelect={(text: any) => setSelectedDriverAlternateMobileNumber(text)}
                        formatResult={formatResult}
                        showIcon={false}
                        inputSearchString={selectedDriverAlternateMobileNumber?.name || ''}
                        placeholder='Enter alternate driver mobile number'
                        styling={{ borderRadius: '10', boxShadow: '0', zIndex: 6}}
                    />
                </Col>
                <Col md={6} xs={12} className="mb-2">
                    <h6>Enter vehicle capacity</h6>
                    <ReactSearchAutocomplete
                        items={vehicleCapacity}
                        onSearch={(text: any) => {
                            setSelectedVehicleCapacity({
                                name: text
                            })
                        }}
                        onSelect={(text: any) => setSelectedVehicleCapacity(text)}
                        formatResult={formatResult}
                        showIcon={false}
                        inputSearchString={selectedDriverAlternateMobileNumber?.name || ''}
                        placeholder='Enter vehicle capacity'
                        styling={{ borderRadius: '10', boxShadow: '0', zIndex: 5}}
                    />
                </Col>
            </Row>
            {props.flag != 'assign' ? (
                <Row className="mb-4 p-0">
                    <RedDot title='Remark'/>
                    <Col className="ms-0 ps-0" md={6}>
                        <input type="text" onChange={(e: any) => setRemark(e.target.value)} value={remark} placeholder="Write something ..." className="form-control ms-2" />
                    </Col>
                </Row>
            ) : (<></>)}

            <Modal.Footer className="p-0 m-0 border-0 ">
                <input type="button" value="Assign Vehicle" onClick={handleClick} disabled={disable} className="btn text-white" style={{ backgroundColor: disable ? CommonColors.GRAY : CommonColors.PRIMARY }} />
            </Modal.Footer>
        </div>
    )
}

export default ReassignVehicle