import { Action, createSlice } from "@reduxjs/toolkit";
import type { RootState } from '../../store/Index';
interface CounterState {
    homeCount: number,
    biddingsCount: number,
    contractsCount: number,
    assignCount: number,
    tripsCount: number,
    accountCount: number
}

const initialState: CounterState = {
    homeCount: 0,
    biddingsCount: 0,
    contractsCount: 0,
    assignCount: 0,
    tripsCount: 0,
    accountCount: 0
}

const menuCountSlice = createSlice({
    name: 'menuCount',
    initialState : initialState,
    reducers: {
        setHomeCount: (state, action) => {
            state.homeCount = action.payload;
        },
        setBiddingsCount: (state, action) => {
            state.biddingsCount = action.payload;
        },
        setContractsCount: (state, action) => {
            state.contractsCount = action.payload;
        },
        setAssignCount: (state, action) => {
            state.assignCount = action.payload;
        },
        setTripsCount: (state, action) => {
            state.tripsCount = action.payload;
        },
        homeCountIncrement: (state,action) => {
            state.homeCount++;
        },
        homeCountDecrement: (state) => {
            state.homeCount--;
        },
        biddingsCountIncrement: (state) => {
            state.biddingsCount++;
        },
        biddingsCountDecrement: (state) => {
            state.biddingsCount--;
        },
        contractsCountIncrement: (state) => {
            state.contractsCount++;
        },
        contractsCountDecrement: (state) => {
            state.contractsCount--;
        },
        assignCountIncrement: (state) => {
            state.assignCount++;
        },
        assignCountDecrement: (state) => {
            state.assignCount--;
        },
        tripsCountIncrement: (state) => {
            state.tripsCount++;
        },
        tripsCountDecrement: (state) => {
            state.tripsCount--;
        },
    }
});

export default menuCountSlice.reducer;
export const selectHomeCount = (state: RootState) => state.bottomMenuCount.homeCount;
export const selectBidCount = (state:RootState) => state.bottomMenuCount.biddingsCount;
export const selectAssignCount = (state: RootState) => state.bottomMenuCount.assignCount;
export const selectContractsCount = (state: RootState) => state.bottomMenuCount.contractsCount;
export const selectTripsCount = (state: RootState) => state.bottomMenuCount.tripsCount;

export const {
    setHomeCount, setBiddingsCount, setAssignCount, setTripsCount, setContractsCount,

    homeCountIncrement, homeCountDecrement,
    biddingsCountIncrement, biddingsCountDecrement,
    contractsCountIncrement, contractsCountDecrement,
    assignCountIncrement, assignCountDecrement,
    tripsCountIncrement, tripsCountDecrement,
} = menuCountSlice.actions;
