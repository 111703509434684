import { loadingDocuments, RegistrationCertificate, InsuranceCertificate, DrivingLicense, Stepney, VehicleFirsking, BreathAlcoholic, Inflamable, Covering, ForFloor, WindScreen, EngineSpillage, NumberPlate, BodyCondition, DispatchDocument, RoadTax, FitnessCertificate, Puc, Permit } from '../../../../assets/temp/RadioButtonsList'

export const ArrayListForGateIn = [
    {
        title: 'Driving Licence',
        name: 'DrivingLicense',
        selected: DrivingLicense
    },
    {
        title: 'Loading Documents',
        name: 'LoadingDocuments',
        selected: loadingDocuments
    },
    {
        title: 'Aziz Trust condition slip',
        name: 'azizTrustContributionSlip',
        selected: loadingDocuments
    },
    {
        title: 'Stepny at gate',
        name: 'stepneySurrenderedAtGate',
        selected: Stepney
    },
    {
        title: 'Vehicle Firsking',
        name: 'vehicleFrisking',
        selected: VehicleFirsking
    },
    {
        title: 'Breathe alcoholic analyser test',
        name: 'breathAlcoholicAnalyzerTest',
        selected: BreathAlcoholic
    },
    {
        title: 'Inflamable Material Carrying',
        name: 'inflammableMaterialCarrying',
        selected: Inflamable
    },
    {
        title: 'For Covering',
        name: 'ForCovering',
        selected: DrivingLicense
    },
    {
        title: 'For Floor',
        name: 'ForFloor',
        selected: ForFloor
    }
]

export const ArrayListForParkIn = [
    {
        title: 'Driving Licence',
        name: 'DrivingLicense',
        selected: DrivingLicense
    },
    {
        title: 'Loading Documents',
        name: 'LoadingDocuments',
        selected: loadingDocuments
    },
    {
        title: 'For covering',
        name: 'ForCovering',
        selected: Covering
    },
    {
        title: 'For floor',
        name: 'ForFloor',
        selected: ForFloor
    },
    {
        title: 'Insurance Certificate',
        name: 'InsuranceCertificate',
        selected: InsuranceCertificate
    },
    {
        title: 'Stepny and Tools',
        name: 'StepneyAndTools',
        selected: Stepney
    },
    {
        title: 'Fitness Certificate',
        name: 'FitnessCertificate',
        selected: FitnessCertificate
    },
    {
        title: 'Road Tax',
        name: 'RoadTax',
        selected: RoadTax
    },
    {
        title: 'PUC',
        name: 'Puc',
        selected: Puc
    },
    {
        title: 'Permit',
        name: 'Permit',
        selected: Permit
    },
    {
        title: 'Wind Screen',
        name: 'WindScreen',
        selected: WindScreen
    },
    {
        title: 'Engine Spillage',
        name: 'EngineSpillage',
        selected: EngineSpillage
    },
    {
        title: 'Number Plate',
        name: 'NumberPlate',
        selected: NumberPlate
    },
    {
        title: 'Body Condition',
        name: 'BodyCondition',
        selected: BodyCondition
    },
    {
        title: 'Registration Certificate',
        name: 'RegistrationCertificate',
        selected: RegistrationCertificate
    }
]