import React from "react";
import { Row, Table } from "react-bootstrap";
import BidCard from "../components/bidcard/BidCard";
import NotFound from "../../notfound/Indesx";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/Index";
import { get } from "../../../config/services/api/ApiServices";
import Loader from "../../trips/components/loader/Index";
import { FetchUserData } from "../../../common/functions/CommonFunctions";
import TableHeader from "../components/Tableheader";

const Closed = (props: any) => {

    const dispatch = useAppDispatch();
    FetchUserData(dispatch)

    const user = useAppSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);

    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        getClosedBids()
    }, [])

    const getClosedBids = async () => {
        setLoading(true)
        const path = 'Auctions/GetBidListNew'
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "Type": "closed",
            "Page": 1,
            "Search": '',
            "Sort": "",
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        const response = await get(path, body, header)
        setLoading(false)
        if (response.succeeded == true) {
            setClosedBids(response.data)
        }
    }

    const [closedBids, setClosedBids] = React.useState([])
    return (
        <Row className="contentArea p-0 m-0 ">
            {loading ? <Loader /> : (
                <>
                    {closedBids.length > 0 ? (
                        <>
                            <Table className="table">
                                <TableHeader />
                                <tbody>
                                    {closedBids.map((bids: any) => <BidCard data={bids} key={bids.id} tabName='closed' />)}
                                </tbody>
                            </Table >
                        </>
                    ) : <NotFound />}
                </>)
            }
        </Row>
    )
}

export default Closed