const TableHeader = () => {
    return (
        <thead>
            <tr>
                <th>Rank</th>
                <th>Bid Time</th>
                <th>Bid Number</th>
                <th>Unloading Area</th>
                <th>Delivery Date</th>
                <th>Material Type (Quantity)</th>
                <th>Delivery points (No)</th>
                <th></th>
            </tr>
        </thead>
    )
}

export default TableHeader