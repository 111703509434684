const TableHeader = (props: any) => {
    return (
        <thead>
            <tr>
                <th>Shipment No</th>
                <th>Vehicle No</th>
                <th>Trip Date</th>
                <th>Unloading Points</th>
                <th>Unloading Area</th>
                <th>Material Type (Quantity)</th>
                <th>Delivery Date</th>
                <th>Status</th>
            </tr>
        </thead>
    )
}

export default TableHeader