export const Style = {
    header_profile_image:{
        width: 30,
        height: 30,
        backgroundSize: 'cover',
        cursor: 'pointer'
    },
    center:{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center'
    },
    logo:{
        height: 40,
        paddingRight: 10
    }
}