import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Header } from "../../components/header/Index";
import SideBarMenu from "../../components/sideBarMenu/Index";
import { useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ReportingPendingForTransporter from "./transporter/reporting_pending/Index";
import UnderLoadingForTransporter from "./transporter/under_loading/Index";
import InTransitForTransporter from "./transporter/In_transit/Index";
import ReportingPendingForPlantUser from "./securityOfficer/reporting_pending/Index";
import ReportedForPlantUser from "./securityOfficer/reported/Index";
import GateInPendingForparkingUser from "./parkingUser/gate_in_pending/Index";
import GateOutPendingForparkingUser from "./parkingUser/gate_out_pending/Index";
import UnderLoadingForParkingUser from "./parkingUser/under_loading/Index";
import { FetchUserData } from "../../common/functions/CommonFunctions";

const Trips = (props: any) => {

    const dispatch = useAppDispatch();
    FetchUserData(dispatch)

    const user = useAppSelector((state: any) => state.user);

    const [tabs, setTabs] = React.useState<any>([])
    const index = useLocation()
    const [tabIndex, setTabIndex] = React.useState(index?.state?.index || 0)
    const [selectedTabComponent, setSelectedTabComponent] = React.useState<any>(null)

    const tripTabsForTransporter = [
        {
            title: 'Reporting Pending',
            path: 'reporting-pending',
            tabIndex: 0,
            component: <ReportingPendingForTransporter tabIndex={0} />
        },
        {
            title: 'Under Loading',
            path: 'under-loading',
            tabIndex: 1,
            component: <UnderLoadingForTransporter tabIndex={1} />
        },
        {
            title: 'In transit',
            path: 'in-transit',
            tabIndex: 2,
            component: <InTransitForTransporter tabIndex={2} />
        }
    ]

    const tripTabsForParkingUser = [
        {
            title: 'Reporting Pending',
            path: 'reporting-pending',
            tabIndex: 0,
            component: <ReportingPendingForPlantUser tabIndex={0} />
        },
        {
            title: 'Reported',
            path: 'reported',
            tabIndex: 1,
            component: <ReportedForPlantUser tabIndex={1} />
        }
    ]

    const tripTabsForSecurityOfficer = [
        {
            title: 'Gate In Pending',
            path: 'gate-in-pending',
            tabIndex: 0,
            component: <GateInPendingForparkingUser tabIndex={0} />
        },
        {
            title: 'Under Loading',
            path: 'under-loading',
            tabIndex: 1,
            component: <UnderLoadingForParkingUser tabIndex={1} />
        },
        {
            title: 'Gate Out Pending',
            path: 'gate-out-pending',
            tabIndex: 2,
            component: <GateOutPendingForparkingUser tabIndex={2} />
        }
    ]

    React.useEffect(() => {
        if (user.userType == 'Transporter') {
            setTabs(tripTabsForTransporter)
            setSelectedTabComponent(index?.state?.index == 1 ? <UnderLoadingForTransporter /> : (index?.state?.index == 2 ? <InTransitForTransporter /> : <ReportingPendingForTransporter />))
        } else if (user.userType == 'SecuirityOfficer') {
            setTabs(tripTabsForParkingUser)
            setSelectedTabComponent(index?.state?.index == 1 ? <ReportedForPlantUser /> : <ReportingPendingForPlantUser />)
        } else {
            setTabs(tripTabsForSecurityOfficer)
            setSelectedTabComponent(index?.state?.index == 1 ? <UnderLoadingForParkingUser /> : (index?.state?.index == 2 ? <GateOutPendingForparkingUser /> : <GateInPendingForparkingUser />))
        }
    }, [index?.state?.index])

    const changeTab = (items: any) => {
        setSelectedTabComponent(items.component)
        setTabIndex(items.tabIndex)
    }

    return (
        <Container fluid style={{ width: '100%', height: '100%' }}>

            <Header breadCrumb='Trips' />
            <Row>
                <SideBarMenu selectedPath='Trips' />
                <Col className="p-3" style={{ backgroundColor: '#f5f5f5' }}>
                    <Row className="px-2">
                        <Card className="border-0 p-0 m-0">
                            <Card.Body>
                                <Row className="p-0 m-0 ">
                                    <Col className="d-flex p-0 m-0">
                                        {tabs.map((items: any, index:any) => {
                                            return (
                                                <p className="me-2 px-2 py-1 text-center " style={{ backgroundColor: tabIndex == items.tabIndex ? 'green' : '#ebebeb', color: tabIndex == items.tabIndex ? 'white' : 'black', cursor: 'pointer', fontSize: 16, borderRadius: 4 }} onClick={() => changeTab(items)} key={index}>{items.title}</p>
                                            )
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ height: '83vh', overflow: 'scroll' }} className="contentArea">
                                        {selectedTabComponent}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Trips