import React, { useState } from "react";
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { Style } from "./Style";
import moment from 'moment';
import { get, post, postSocketApi } from "../../../../config/services/api/ApiServices";
import { FaCopy } from "react-icons/fa";
import { useAppSelector } from "../../../../store/hooks";
import { RootState } from "../../../../store/Index";
import { CommonStyles } from "../../../../common/styles/CommonStyles";
import InputView from "../../../trips/components/input_disable_view/Index";
import Confirm from "../../../../components/confirm/Index";

const BidCard = (props: any) => {

    var tabName = props.tabName
    const [data, setData] = useState(props.data)
    const [bidDetails, setBidDetails] = React.useState<any>({})
    const user = useAppSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const socketAuth = useAppSelector((state: RootState) => state.socket.socketAuth);
    const socketObj = useAppSelector((state: RootState) => state.socket.socketObj);

    const [timeEnd, setTimeEnd] = React.useState(true)
    const [remainingTime, setRemainingTime] = React.useState(moment(data.auctionEndTime).diff(moment(), 'seconds'));
    const [amount, setAmount] = React.useState(data.lastBidRate ? (data.lastBidRate).toString() : '')
    const [oldAmount, setOldAmount] = React.useState(data.lastBidRate ? (data.lastBidRate).toString() : '')
    const [minimumPriceAlertMessage, setMinimumPriceAlertMessage] = React.useState(false)
    const [bidLimit, setBidLimit] = React.useState(tabName == 'upcoming' ? 100 : data.biddingLimit);
    const [submitDisable, setSubmitDisable] = React.useState(false)
    const [diffenceAmount, setDifferenceAmount] = React.useState(0)
    const [noOfAttempts, setNoOfAttempts] = React.useState(data.bidssubmttedcount);
    const [isModalVisible, setIsModalVisible] = React.useState(false)

    const [bidStartTime, setBidStartTime] = React.useState(moment(data.auctionStartTime).diff(moment(), 'seconds'))
    const [auctionStartTime, setAuctionStartTime] = React.useState(moment(data.auctionStartTime).format('hh:mm a'));

    const [showCopied, setShowCopied] = React.useState(false)
    const [regions, setRegions] = React.useState(null)
    const [unloadingPointsCount, setUnloadingPointsCounts] = React.useState(0)

    React.useEffect(() => {
        if (tabName != 'closed') {
            setInterval(() => {
                setRemainingTime(moment(data.auctionEndTime).diff(moment(), 'seconds'))
                if (moment(data.auctionEndTime).diff(moment(), 'seconds') <= 0 || (moment(data.auctionStartTime).diff(moment(), 'seconds') <= 0 && tabName == 'upcoming')) {
                    setTimeEnd(false)
                }
            }, 1000.00)
        }
    }, []);

    const checkLimit = () => {
        if (noOfAttempts >= bidLimit && tabName != 'upcoming') {
            setSubmitDisable(true)
        }
    }

    React.useEffect(() => {
        checkLimit()
    }, [noOfAttempts])

    const validate = () => {
        if (amount) {
            var transportersdifferenceAmount = data.overallTransportersLastBidRate - data.transporterQuotePriceDifference;
            var procced = transportersdifferenceAmount <= 0 ? true : (parseInt(amount) >= transportersdifferenceAmount ? (setDifferenceAmount(transportersdifferenceAmount), setMinimumPriceAlertMessage(true), setAmount(oldAmount)) : true)

            if (procced) {
                if (tabName == 'upcoming' && parseInt(amount) < data.lastBidRate) {
                    setMinimumPriceAlertMessage(false)
                    setAmount(amount)
                    placeBid()
                } else if (data.lastBidRate > 0) {
                    if (parseInt(amount) < data.lastBidRate && data.rank != null) {
                        setMinimumPriceAlertMessage(false)
                        setAmount(amount)
                        placeBid()
                    } else {
                        setMinimumPriceAlertMessage(true)
                    }
                } else {
                    setMinimumPriceAlertMessage(false)
                    setAmount(amount)
                    placeBid()
                }
            }
            setTimeout(() => {
                setMinimumPriceAlertMessage(false)
            }, 5000)
        } else {
            window.alert('Please enter amount')
        }
    }

    const placeBid = () => {
        // if (remainingTime <= 2) {
        //     setTimeout(() => {
        //       window.alert('Sorry, The bidding time has been closed')
        //     }, 3000)
        //   } else {
        submitBid()
        //   }
    }

    const submitBid = async () => {
        setSubmitDisable(true)
        const path = 'auction/submitbid';

        const body = {
            "userID": user.userID,
            "biddingID": data.id,
            "transporterID": user.transporterID,
            "vehicleSize": data.vehicleSize,
            "price": amount,
            "committedQuantity": data.totalQuantity,
            "remarks": "",
            "applicationType": "TMS",
        }

        var response = await postSocketApi(path, body, socketAuth);
        if (response.status == 200) {
            window.alert(response.message)
            data.lastBidRate = amount
            data.overallTransportersLastBidRate = amount
            setOldAmount(amount)
            if (tabName === "notParticipated" && data.rank === null) {
                setTimeEnd(false);
            }
            if (noOfAttempts == bidLimit) {
                setSubmitDisable(true)
            } else {
                if(tabName != 'upcoming'){
                    setNoOfAttempts(noOfAttempts + 1)
                }
                setSubmitDisable(false)
            }
            hideModal()
        } else {
            if (response.message && response.message == 'Sorry, The bidding time has been closed') {
                setTimeout(() => {
                    window.alert(response.message)
                }, 4000)
            } else {
                window.alert(response.message || 'Something went wrong.')
            }
            setSubmitDisable(false)
        }
    }

    const hideModal = () => setIsModalVisible(false)
    const showModal = () => {
        getBidDetails()
        setIsModalVisible(true)
    }

    const getBidDetails = async () => {
        const path = 'Auctions/getBiddingDetailsNew';
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "BiddingID": data.id,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        if (response.succeeded) {
            let responseData = response.data;
            let newPoints: any = {}
            let newRegions: any = {}

            if (responseData.stateName.length > 0) {
                responseData.stateName.map((item: any) => {
                    newRegions[item.unloadingPoint.replaceAll(' ', '_')] = item.region
                })
            } else if (responseData.regionName.length > 0) {
                responseData.regionName.map((item: any) => {
                    newRegions[item.unloadingPoint.replaceAll(' ', '_')] = item.stateName
                })
            }

            setRegions(newRegions)

            responseData.points.map((items: any) => {
                let keyPoint = items.deliveryStation.replaceAll(' ', '_') + '#' + items.unloadingPoint

                let obj = {
                    deliveryStation: items.deliveryStation,
                    totalQuantity: parseFloat(items.totalQuantity),
                    materialType: items.materialType,
                    unloadingPoint: items.unloadingPoint,
                    noOfDeliveryAddress: items.noOfDeliveryAddress
                }

                if (newPoints[keyPoint]) {
                    newPoints[keyPoint].map((points: any) => {
                        if (points.unloadingPoint == obj.unloadingPoint) {
                            points.totalQuantity += obj.totalQuantity
                        } else {
                            newPoints[keyPoint].push(obj)
                        }
                    })
                } else {
                    newPoints[keyPoint] = [obj]
                }
            })

            responseData.points = newPoints;
            setUnloadingPointsCounts(Object.values(newPoints).length)
            setBidDetails(responseData)
        }
    }

    React.useEffect(() => {
        if (socketObj) {
            socketObj.on("message", (message: any) => {
                if (message.transporterID == user.transporterID && data.id == message.BiddingId && data.tabstatus != 'upcoming') {
                    // data.rank = message.rank;
                    // data.rankColor = message.rankColor;
                    // data.rankTextColor = message.rankTextColor;
                    // data.lastBidRate = message.lastBidRate;
                    // data.overallTransportersLastBidRate = message.lastBidRate
                    // console.log(data)
                    setData((prev:any) => ({...prev, 
                        rank: message.rank,
                        rankColor: message.rankColor,
                        rankTextColor: message.rankTextColor,
                        lastBidRate: message.lastBidRate,
                        overallTransportersLastBidRate: message.lastBidRate
                    }))
                }
            });
        }
        return () => {
            socketObj.off('message');
        };
    }, []);

    return (
        <>
            {timeEnd ? (
                <tr className="hoverTr" style={{ cursor: 'pointer' }}>
                    <td onClick={showModal}>
                        {tabName == 'upcoming' || !data.rank ? <p className="p-0 m-0">-</p> : (
                            <div style={{ ...Style.rankBox, backgroundColor: data.rankColor || 'gray' }}>
                                <small className="m-0" style={{ color: data.rankTextColor || 'black' }}>{data.rank}</small>
                            </div>
                        )}
                    </td>
                    <td onClick={showModal}>
                        {tabName == 'closed' ? <strong className="p-0 m-0 text-danger">Bid Closed</strong> : <strong className="p-0 m-0 text-danger">{bidStartTime <= 0 ? moment().startOf('day').seconds(remainingTime).format('HH:mm:ss') : auctionStartTime}</strong>}
                    </td>
                    <td onClick={showModal}><small style={{ cursor: 'pointer', color: 'blue' }}>{data.auctionNumber}</small></td>
                    <td onClick={showModal}><small>{data.unloadingArea}</small></td>
                    <td onClick={showModal}><small>{data.deliveryDate}</small></td>
                    <td onClick={showModal}><small>{data.materialType} ( {data.totalQuantity} MT)</small> </td>
                    <td onClick={showModal}><small>{data.unloadingPointCount}</small> </td>
                    <td>
                        {tabName == 'closed' ? <></> : (
                            <Row>
                                <Col className="p-0 m-0">
                                    <Form.Control type="number" placeholder="Enter amount" value={amount} onChange={(e) => setAmount(e.target.value.replace(/[[a-z][A-Z]-+#*;,.=+@!%$&<>\{\}\[\]\\\/]/gi, ''))} maxLength={6} disabled={submitDisable} size="sm" />
                                </Col>
                                <Col xl={5} lg={5} md={4} sm={6} xs={6} className="m-0">
                                    <Button variant="success" className="w-100 btn-sm" onClick={validate} disabled={submitDisable}>{tabName == 'upcoming' ? '' : `${noOfAttempts}/${bidLimit}`} <small>Submit</small></Button>
                                </Col>
                            </Row>
                        )}
                        {minimumPriceAlertMessage && diffenceAmount ? (
                            <small className="text-danger" style={{ fontSize: 10 }}>
                                please enter lesser price
                            </small>
                        ) : (<></>)}
                    </td>
                </tr>
            ) : (<></>)}

            <Modal show={isModalVisible} onHide={hideModal} animation={true} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton>
                    {tabName == 'upcoming' || !data.rank ? <></> : (
                        <div style={{ ...Style.rankBox, backgroundColor: data.rankColor || 'gray' }} className="me-3" >
                            <Card.Title className="m-0" style={{ color: data.rankTextColor || 'black' }}>{data.rank}</Card.Title>
                        </div>
                    )}
                    <Modal.Title>{bidDetails?.biddingNumber} </Modal.Title>
                    <FaCopy size={15} color="green" onClick={() => {
                        navigator.clipboard.writeText(bidDetails?.biddingNumber)
                        setShowCopied(true)
                    }} className="mx-2" />
                    <p className="p-0 m-0">{showCopied ? 'Copied to Clipboard' : ''}</p>
                </Modal.Header>
                <Modal.Body className="contentArea">
                    <Row>
                        <InputView label={'Bid Date'} value={bidDetails?.auctionDate} />
                        {data.isCeilingPriceAvailable ? <InputView label={'Ceiling price'} value={'Rs. ' + bidDetails?.ceilingPrice} /> : <></>}
                        <InputView label={'Loading point'} value={bidDetails?.loadingPoint} />
                        <InputView label={'Loading Date'} value={bidDetails?.expectedLoadingDate} />
                        <InputView label={'Vehicle Type'} value={bidDetails?.vehicleType} />
                        <InputView label={'Vehicle Capacity'} value={bidDetails.vehicleSize} />
                        <InputView label={'No of delivery stations'} value={unloadingPointsCount} />
                        <InputView label={'Expected delivery date'} value={moment(bidDetails?.committedDeliveryDate).format('DD MMM, YYYY')} />

                        <div>
                            <h6 className="p-0 m-0">Note</h6>
                            <p className="p-0 m-0">{bidDetails?.notes || '-'}</p>
                        </div>

                    </Row>

                    <Row className="mt-2"><h6>Delivery Stations ( {bidDetails?.points && Object.keys(bidDetails?.points).length} )</h6></Row>

                    <Card className="p-2">
                        <Row className='p-0 m-0'>
                            <Col><h6>Unloading Point Name</h6></Col>
                            <Col><h6>Material Types</h6></Col>
                            <Col><h6>Unloading Points (No)</h6></Col>
                            <Col><h6>Quantity</h6></Col>
                        </Row>
                        <hr className='p-0 m-1' />

                        {/* {bidDetails?.points?.map((items: any) => {
                            return (
                                <Row className='p-0 m-0 mb-1'>
                                    <Col className='d-flex align-items-center'><p className='p-0 m-0 me-2' style={CommonStyles.circle}></p>{items.deliveryStation}</Col>
                                    <Col>{items.materialType}</Col>
                                    <Col>{items.noOfDeliveryAddress}</Col>
                                    <Col>{items.totalQuantity}</Col>
                                </Row>
                            )
                        })} */}

                        {bidDetails.hasOwnProperty('points') && Object.keys(bidDetails?.points).map((pointskey: any) => {
                            return (
                                <Row className='p-0 m-0 mb-1'>
                                    <Col className="d-flex align-items-center">
                                        <p className='p-0 m-0 me-2' style={CommonStyles.circle}></p>
                                        <p className='p-0 m-0 me-2'>{pointskey.split('#')[0]}</p>
                                        <p className='px-2 m-0 mx-2 rounded-pill text-white' style={{ backgroundColor: 'orange', fontSize: 12 }}>{regions && regions[pointskey.split('#')[0]]}</p>
                                    </Col>
                                    {bidDetails?.points.hasOwnProperty(pointskey) && bidDetails?.points[pointskey].map((item: any) => {
                                        return (
                                            <>
                                                <Col>{item.materialType}</Col>
                                                <Col>{item.noOfDeliveryAddress}</Col>
                                                <Col>{item.totalQuantity} MT</Col>
                                            </>
                                        )
                                    })}
                                </Row>
                            )
                        })}
                    </Card>

                    <Row className="mt-2"><h6>Unloading Points ( {[new Set(bidDetails?.unloadingPoints)].length} )</h6></Row>

                    <Card className="mb-1 p-2">
                        {bidDetails.hasOwnProperty('unloadingPoints') && [new Set(bidDetails?.unloadingPoints)].map((items: any) => (
                            <strong className="p-0 m-0" style={CommonStyles.font_14}>* {items}</strong>
                        ))}
                    </Card>

                </Modal.Body>
                {tabName == 'closed' ? <></> : (
                    <Modal.Footer>
                        <Row className="w-50">
                            <Col>
                                <Form.Control type="number" placeholder="Enter amount" value={amount} onChange={(e) => setAmount(e.target.value.replace(/[[a-z][A-Z]-+#*;,.=+@!%$&<>\{\}\[\]\\\/]/gi, ''))} maxLength={6} disabled={submitDisable} size="sm" />
                            </Col>
                            <Col md={3}>
                                <Button variant="success" className="w-100" size="sm" onClick={validate} disabled={submitDisable}>{tabName == 'upcoming' ? '' : `${noOfAttempts}/${bidLimit}`} Submit</Button>
                            </Col>
                            {minimumPriceAlertMessage && diffenceAmount ? (
                                <small className="text-danger" style={{ fontSize: 10 }}>
                                    please enter lesser price
                                </small>
                            ) : (<></>)}
                        </Row>
                    </Modal.Footer>
                )}
            </Modal >
        </>
    )
}

export default BidCard