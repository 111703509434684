import React from 'react'
import { CommonStyles } from '../../../../common/styles/CommonStyles'
import { Col, Row, Spinner, Form, Card, ToastContainer, Toast } from 'react-bootstrap'
import { loadingDocuments, RegistrationCertificate, InsuranceCertificate, DrivingLicense, Stepney, VehicleFirsking, BreathAlcoholic, Inflamable, Covering, ForFloor, WindScreen, EngineSpillage, NumberPlate, BodyCondition, FitnessCertificate, RoadTax, Permit, Puc } from '../../../../assets/temp/RadioButtonsList'
import CommonColors from '../../../../common/colors/CommonColor'
import { useSelector } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import { RootState } from '../../../../store/Index'
import { post, uploadMedia } from '../../../../config/services/api/ApiServices'
import { ArrayListForGateIn, ArrayListForParkIn } from './DefaulData'
import InputView from '../input_disable_view/Index'

const CheckBoxPrint = React.memo((props: any) => {

    const [keyItem, setKeyItem] = React.useState<any>(props.label || '')
    const [selectedValue, setSelectedValue] = React.useState<any>(props?.radioArray[0].label || '')
    const [imageRequired, setImageRequired] = React.useState(props?.isImageRequired || false)

    const handleRadioChange = (e: any) => {
        setSelectedValue(e)
        props.changeData(keyItem, 'radio', e, imageRequired)
    }

    const handleImageChange = (e: any) => {
        props.changeData(keyItem, 'image', { file: e.target.files[0], uri: e.target.value }, imageRequired)
    }

    return (
        <Row className='mt-2 px-1'>
            <Card className='p-2'>
                <h6>{props?.title}</h6>
                <Col>
                    {props?.radioArray.map((radios: any) => {
                        return (
                            <Form.Check
                                label={radios?.label}
                                type='radio'
                                style={{ paddingRight: 10, marginRight: 20 }}
                                checked={selectedValue == radios?.label ? true : false}
                                onChange={() => handleRadioChange(radios?.label)}
                            />
                        )
                    })}
                </Col>
                {props?.isImageRequired ? (
                    <Row className='align-items-center'>
                        <Col md={4}><h6>Image</h6></Col>
                        <Col><Form.Control type='file' onChange={(e: any) => handleImageChange(e)} /></Col>
                    </Row>
                ) : <></>}
            </Card>
        </Row>
    )
})

const Inspection = (props: any) => {

    var tripStatus = props.status == 'parkInInspection' ? true : false

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);

    const [loading, setLoading] = React.useState(false)

    const [tripData, setTripData] = React.useState<any>(props?.data || {})

    const [remark, setRemark] = React.useState('')
    const [disable, setDisable] = React.useState(false)

    const [msg, setMsg] = React.useState('')
    const [showMsg, setShowMsg] = React.useState(false)
    const [color, setColor] = React.useState('')

    const [selectedData, setSelectedData] = React.useState<any>([])

    var arryNames: any = [];
    React.useEffect(() => {

        if (props?.status == 'Gate In Inspection') {
            arryNames = ArrayListForGateIn
        } else {
            arryNames = ArrayListForParkIn
        }

        arryNames.map((item: any) => {
            selectedData[item?.name] = {
                image: {},
                selectedValue: item?.selected[0].label,
                name: item?.name,
                imageRequired: tripStatus ? true : false
            }
        })
        setSelectedData(selectedData)
    }, [])

    const validate = (isApproved: any) => {
        if (window.confirm('Are you sure to upload documents') == true) {
            submit(isApproved)
        }
    }

    const uploadImages = async (value: any) => {
        var image: any = await uploadMedia('Parking In Inspection', value, { 'Content-Type': 'multipart/form-data' })
        return image.succeeded ? image.data[0].url : ''
    }

    const submit = async (isApproved: any) => {
        setDisable(true)

        const path = props?.status == 'Gate In Inspection' ? 'Trips/GateInInspection' : 'Trips/ParkInInspection'
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }

        const body = props?.status != 'Gate In Inspection' ? {
            "bodyCondition": selectedData['BodyCondition'].selectedValue || '',
            "bodyConditionDocuments": await uploadImages(selectedData['BodyCondition'].image),

            "drivingLicense": selectedData['DrivingLicense'].selectedValue || '',
            "drivingLicenseDocuments": await uploadImages(selectedData['DrivingLicense'].image),

            "engineSpillage": selectedData['EngineSpillage'].selectedValue || '',
            "engineSpillageDocuments": await uploadImages(selectedData['EngineSpillage'].image),

            "fitnessCertificate": selectedData['FitnessCertificate'].selectedValue || '',
            "fitnessCertificateDocuments": await uploadImages(selectedData['FitnessCertificate'].image),

            "forCovering": selectedData['ForCovering'].selectedValue || '',
            "forCoveringDocuments": await uploadImages(selectedData['ForCovering'].image),

            "forFloor": selectedData['ForFloor'].selectedValue || '',
            "forFloorDocuments": await uploadImages(selectedData['ForFloor'].image),

            "insuranceCertificate": selectedData['InsuranceCertificate'].selectedValue || '',
            "insuranceCertificateDocuments": await uploadImages(selectedData['InsuranceCertificate'].image),

            "loadingDocumentFiles": await uploadImages(selectedData['LoadingDocuments'].image),
            "loadingDocuments": selectedData['LoadingDocuments'].selectedValue || '',

            "numberPlate": selectedData['NumberPlate'].selectedValue || '',
            "numberPlateDocuments": await uploadImages(selectedData['NumberPlate'].image),

            "permit": selectedData['Permit'].selectedValue || '',
            "permitDocuments": await uploadImages(selectedData['Permit'].image),

            "puc": selectedData['Puc'].selectedValue || '',
            "pucdocuments": await uploadImages(selectedData['Puc'].image),

            "registrationCertificate": selectedData['RegistrationCertificate'].selectedValue || '',
            "registrationCertificateDocuments": await uploadImages(selectedData['RegistrationCertificate'].image),

            "remark": remark,
            "roadTax": selectedData['RoadTax'].selectedValue || '',
            "roadTaxDocuments": await uploadImages(selectedData['RoadTax'].image),

            "stepneyAndTools": selectedData['StepneyAndTools'].selectedValue || '',
            "stepneyDocuments": await uploadImages(selectedData['StepneyAndTools'].image),

            "tripID": tripData.id,
            "userID": user.userID,
            "userType": user.userType,
            "vehicleAcceptedForLoading": isApproved ? "Yes" : "No",

            "windScreen": selectedData['WindScreen'].selectedValue || '',
            "windScreenDocuments": await uploadImages(selectedData['WindScreen'].image)
        } : {
            "tripId": tripData?.id,
            "loadingDocuments": selectedData['LoadingDocuments'].selectedValue || '',
            "azizTrustContributionSlip": selectedData['azizTrustContributionSlip'].selectedValue || '',
            "drivingLicense": selectedData['DrivingLicense'].selectedValue || '',
            "vehicleFrisking": selectedData['vehicleFrisking'].selectedValue || '',
            "stepneySurrenderedAtGate": selectedData['stepneySurrenderedAtGate'].selectedValue || '',
            "breathAlcoholicAnalyzerTest": selectedData['breathAlcoholicAnalyzerTest'].selectedValue || '',
            "inflammableMaterialCarrying": selectedData['inflammableMaterialCarrying'].selectedValue || '',
            "forCovering": selectedData['ForCovering'].selectedValue || '',
            "forFloor": selectedData['ForFloor'].selectedValue || '',

            "vehicleAcceptedForLoading": isApproved,
            "remarks": remark,

            "vehicleFriskingUploadDocuments": await uploadImages(selectedData['vehicleFrisking'].image),

            "stepneyUploadDocuments": await uploadImages(selectedData['stepneySurrenderedAtGate'].image),

            "breathAlcoholicAnalyzerTestUploadDocuments": await uploadImages(selectedData['breathAlcoholicAnalyzerTest'].image),

            "inflammableMaterialUploadDocuments": await uploadImages(selectedData['inflammableMaterialCarrying'].image),

            "uploadedLoadingDocuments": await uploadImages(selectedData['vehicleFrisking'].image),

            "forCoveringUploadDocuments": await uploadImages(selectedData['ForCovering'].image),

            "forFloorUploadDocuments": await uploadImages(selectedData['ForFloor'].image)
        }
        var response = await post(path, body, header)
        setDisable(false)
        if (response.succeeded) {
            setColor('success')
            setMsg(response.message)
            setShowMsg(true)

        } else {
            setColor('danger')
            setMsg(response.message)
            setShowMsg(true)
        }
        setTimeout(() => {
            setColor('')
            props.closeModal()
        }, 2000)
    }

    const changeSelectedData = React.useCallback((keyIndex: any, type: any, value: any, imageRequired: boolean) => {
        if (selectedData.hasOwnProperty(keyIndex)) {
            if (type == 'radio') {
                selectedData[keyIndex].selectedValue = value
            } else if (type == 'image') {
                selectedData[keyIndex].image = value
            }
            selectedData[keyIndex].imageRequired = imageRequired
            setSelectedData(selectedData)
        }
    }, [])

    return (
        <>
            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Row>
                    {showMsg ? (
                        <ToastContainer position="bottom-start" className="p-0 text-white" style={{ zIndex: 1, position: 'fixed' }}>
                            <Toast className={`p-2 border-0 bg-${color} text-white`} style={{ width: 400 }}>
                                <Toast.Header className={`border-0 bg-${color}`}>
                                    <h6 className="me-auto text-white p-0 m-0">{msg}</h6>
                                    <small className="text-muted"></small>
                                </Toast.Header>
                            </Toast>
                        </ToastContainer>
                    ) : <></>}

                    <Col>
                        <Row>
                            <InputView label='Trip Number' value={tripData?.tripNumber || '-'}/>
                            <InputView label='Bid / Contract Number' value={tripData?.biddingNumber || '-'}/>
                        </Row>

                        <Row>
                            <Col>
                                <Row className='mt-1 p-0 pt-1 mx-1' style={{ backgroundColor: 'lightgray' }}>
                                    <Col>
                                        <h6>Documents</h6>
                                    </Col>
                                </Row>

                                <Row className='mx-1'>
                                    <Col>
                                        <CheckBoxPrint title={'Driving Licence'} label={'DrivingLicense'} radioArray={DrivingLicense} changeData={changeSelectedData} isImageRequired={tripStatus ? true : false} />
                                        {tripStatus ? (
                                            <>
                                                <CheckBoxPrint title={'Insurance Certificate'} radioArray={InsuranceCertificate} changeData={changeSelectedData} label={'InsuranceCertificate'} isImageRequired={tripStatus ? true : false} />
                                                <CheckBoxPrint title={'Fitness Certificate'} radioArray={FitnessCertificate} changeData={changeSelectedData} label={'FitnessCertificate'} isImageRequired={tripStatus ? true : false} />
                                                <CheckBoxPrint title={'PUC'} radioArray={Puc} changeData={changeSelectedData} label={'Puc'} isImageRequired={tripStatus ? true : false} />
                                            </>
                                        ) : <></>}
                                    </Col>
                                    <Col>
                                        <CheckBoxPrint title={'Loading Documents (DO, Biliti, Gate-In)'} radioArray={loadingDocuments} changeData={changeSelectedData} label={'LoadingDocuments'} isImageRequired={tripStatus ? true : false} />

                                        {tripStatus ? (
                                            <>
                                                <CheckBoxPrint title={'Registtration Certificate'} radioArray={RegistrationCertificate} changeData={changeSelectedData} label={'RegistrationCertificate'} isImageRequired={true} />
                                                <CheckBoxPrint title={'Road Tax'} radioArray={RoadTax} changeData={changeSelectedData} label={'RoadTax'} isImageRequired={true} />
                                                <CheckBoxPrint title={'Permit'} radioArray={Permit} changeData={changeSelectedData} label={'Permit'} isImageRequired={true} />
                                            </>
                                        ) : (
                                            <CheckBoxPrint title={'Aziz Trust condition slip'} radioArray={loadingDocuments} changeData={changeSelectedData} label={'azizTrustContributionSlip'} isImageRequired={tripStatus ? true : false} />
                                        )}
                                    </Col>
                                </Row>

                                {tripStatus ? <></> : (
                                    <>
                                        <Row className='mt-3 p-0 pt-1 mx-1' style={{ backgroundColor: 'lightgray' }}>
                                            <Col>
                                                <h6>Roadworthiness</h6>
                                            </Col>
                                        </Row>

                                        <Row className='mx-1'>
                                            <Col><CheckBoxPrint title={'Breathe alcoholic analyser test'} radioArray={BreathAlcoholic} isImageRequired={false} changeData={changeSelectedData} label={'breathAlcoholicAnalyzerTest'} /></Col>
                                            <Col><CheckBoxPrint title={'Vehicle Firsking (As per guidlines)'} radioArray={VehicleFirsking} isImageRequired={true} changeData={changeSelectedData} label={'vehicleFrisking'} /></Col>
                                        </Row>
                                    </>
                                )}
                            </Col>
                            <Col>
                                {tripStatus ? (
                                    <>
                                        <Row className='mt-1 p-0 pt-1 mx-1' style={{ backgroundColor: 'lightgray' }}>
                                            <Col>
                                                <h6>Roadworthiness</h6>
                                            </Col>
                                        </Row>

                                        <Row className='mx-1'>
                                            <Col>
                                                <CheckBoxPrint title={'Wind Screen'} radioArray={WindScreen} isImageRequired={true} changeData={changeSelectedData} label={'WindScreen'} />
                                                <CheckBoxPrint title={'Engine Spillage'} radioArray={EngineSpillage} isImageRequired={true} changeData={changeSelectedData} label={'EngineSpillage'} />
                                                <CheckBoxPrint title={'Stepny & Tools (Dala & Floor)'} radioArray={Stepney} isImageRequired={true} changeData={changeSelectedData} label={'stepneySurrenderedAtGate'} />
                                            </Col>
                                            <Col>
                                                <CheckBoxPrint title={'Number Plate'} radioArray={NumberPlate} isImageRequired={true} changeData={changeSelectedData} label={'NumberPlate'} />
                                                <CheckBoxPrint title={'Body Condition (Dala & Floor)'} radioArray={BodyCondition} isImageRequired={true} changeData={changeSelectedData} label={'BodyCondition'} />
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <Row className='mt-3 p-0 pt-1 mx-1' style={{ backgroundColor: 'lightgray' }}>
                                            <Col>
                                                <h6>Driver and helper</h6>
                                            </Col>
                                        </Row>

                                        <Row className='mx-1'>
                                            <Col><CheckBoxPrint title={'Stepny & Tools (Dala & Floor)'} radioArray={Stepney} isImageRequired={true} changeData={changeSelectedData} label={'stepneySurrenderedAtGate'} /></Col>
                                            <Col><CheckBoxPrint title={'Inflamable material carrying'} radioArray={Inflamable} isImageRequired={true} changeData={changeSelectedData} label={'inflammableMaterialCarrying'} /></Col>
                                        </Row>
                                    </>
                                )}

                                <Row className='mt-3 p-0 pt-1 mx-1' style={{ backgroundColor: 'lightgray' }}>
                                    <Col>
                                        <h6>Trampaulin Compailance</h6>
                                    </Col>
                                </Row>

                                <Row className='mx-1'>
                                    <Col><CheckBoxPrint title={'For covering (As per season guidlines)'} radioArray={Covering} isImageRequired={tripStatus ? true : false} changeData={changeSelectedData} label={'ForCovering'} /></Col>
                                    <Col><CheckBoxPrint title={'For floor'} radioArray={ForFloor} isImageRequired={tripStatus ? true : false} changeData={changeSelectedData} label={'ForFloor'} /></Col>
                                </Row>

                                <Row className='my-2 mx-1'>
                                    <Col>
                                        <h6>Remark</h6>
                                        <Form.Control type='text' placeholder='remark' value={remark} onChange={(e: any) => setRemark(e.target.value)} />
                                    </Col>
                                </Row>

                                <Row className='my-1 mx-1'>
                                    <Col className='d-flex justify-content-end'>
                                        {tripStatus ? (
                                            <>
                                                <input type="button" value="Reject" style={{ backgroundColor: disable ? 'gray' : 'red' }} className='btn text-white px-5 mt-3 ' onClick={() => validate(true)} disabled={disable} />
                                                <input type="button" value="Accept" style={{ backgroundColor: disable ? 'gray' : CommonColors.PRIMARY }} className='btn text-white px-5 mt-3 ms-2' onClick={() => validate(true)} disabled={disable} />
                                            </>
                                        ) : (
                                            <input type="button" value="Submit" style={{ backgroundColor: disable ? 'gray' : CommonColors.PRIMARY }} className='btn text-white px-5 mt-3 ' onClick={() => validate('')} disabled={disable} />
                                        )}
                                    </Col>
                                </Row>

                            </Col>
                        </Row >

                    </Col >
                </Row >
            )}
        </>
    )
}

export default Inspection