import { Action, createSlice } from "@reduxjs/toolkit";
import type { RootState } from '../../store/Index';
interface CounterState {
    saveToken: boolean
}

const initialState: CounterState = {
    saveToken: false
}

const saveTokenSlice = createSlice({
    name: 'saveToken',
    initialState : initialState,
    reducers: {
        enableSaveDeviceToken: (state, action) => {
            state.saveToken = action.payload
            
        }
    }
});

export default saveTokenSlice.reducer;

export const {
    enableSaveDeviceToken
} = saveTokenSlice.actions;