import React from "react";
import { BrowserRouter, Routes, Route, Navigate, HashRouter } from "react-router-dom";
import Home from "../../../screens/home/Index";
import Login from "../../../screens/login/Index";
import NotFound from "../../../screens/notfound/Indesx";
import Contract from "../../../screens/contract/Index";
import Assign from "../../../screens/assign/Index";
import Bidding from "../../../screens/bidding/Index";
import Trips from "../../../screens/trips/Index";
import Participated from "../../../screens/bidding/ongoing/participated/Index";
import NotParticipated from "../../../screens/bidding/ongoing/not_participated/Index";

import 'bootstrap/dist/css/bootstrap.min.css';
import Upcoming from "../../../screens/bidding/upcoming/Index";
import Closed from "../../../screens/bidding/closed/Index";
import Archieve from "../../../screens/bidding/archieve/Index";
import Ongoing from "../../../screens/bidding/ongoing/Index";
import { NoPageFound } from "../../../components/NoPageFound/Index";
import AccountStatement from "../../../screens/account-statement/Index";

const AppContainer = (props: any) => {

    const [isAuthenticated, setIsAuthenticated] = React.useState(false)

    React.useEffect(() => {
        getUserData()
    }, [])

    const getUserData = async () => {
        var getUserDetails: any = await sessionStorage.getItem('user');
        if (getUserDetails) {
            var user: any = JSON.parse(getUserDetails);
            if (user?.userID != 0) {
                setIsAuthenticated(true)
            }
        }
    }

    return (
        <HashRouter basename="/">
            <Routes>
                <Route index path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />

                {/* home */}
                <Route path="/home" element={<Home />} />

                {/* bidding routes */}
                <Route path="/bidding" element={<Bidding />} />
                <Route path="/bidding/upcoming" element={<Upcoming />} />

                <Route path="/bidding/ongoing" element={<Ongoing />} />
                <Route path="/bidding/ongoing/notParticipated" element={<NotParticipated />} />
                <Route path="/bidding/ongoing/participated" element={<Participated />} />

                <Route path="/bidding/closed" element={<Closed />} />
                <Route path="/bidding/archieve" element={<Archieve />} />

                {/* contract */}
                <Route path="/contracts" element={<Contract />} />

                {/* assign */}
                <Route path="/assigns" element={<Assign />} />

                {/* trips */}
                <Route path="/trips" element={<Trips />} />
                <Route path="/account-statement" element={<AccountStatement />} />

                {/* not found */}
                <Route path="*" element={<NoPageFound />} />
            </Routes>
        </HashRouter>
    )
}

export default AppContainer