import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/user';
import menuCountSlice from './slices/menuCount';
import searchSlice from './slices/search';
import participatedBidsSlice from './slices/participatedBids';
import logoutUserOnDeviceChangeSlice from './slices/logout';
import authSlice from './slices/authentication';
import saveTokenSlice from './slices/saveToken';
import applyFilterSlice from './slices/applyFilter';
import tripCardSlice from './slices/tripCardDetails';
import socketSlice from './slices/socketSlice';

const store = configureStore({
    reducer: {
        user: userSlice,
        auth: authSlice,
        bottomMenuCount: menuCountSlice,
        search: searchSlice,
        participatedData: participatedBidsSlice,
        logoutUserData: logoutUserOnDeviceChangeSlice,
        saveToken: saveTokenSlice,
        apply: applyFilterSlice,
        tripCard: tripCardSlice,
        socket: socketSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['socket/setSocket'],
                ignoredPaths: ['socket.socketObj'],
            },
        }),
});
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch