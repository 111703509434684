import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const InputView = (props: any) => {
    return (
        <Col md={3} className='mb-1'>
            <h6>{props.label}</h6>
            <Form.Control value={props.value || '-'} disabled size='sm'/>
        </Col>
    )
}

export default InputView