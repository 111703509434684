import React from 'react'
import { Card, Col, Container, Row, Spinner, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/Index';
import { useAppSelector } from '../../../../store/hooks';
import { get } from '../../../../config/services/api/ApiServices';
import TripCard from '../../components/trip_card/Index';
import { CommonStyles } from '../../../../common/styles/CommonStyles';
import NotFound from '../../../notfound/Indesx';
import { OpenToast } from '../../../../components/toast/Index';
import ReassignVehicle from '../../components/reassignVehicle/Index';
import ParkingIn from '../../components/parking_in/Index';

export const TripBaseCard = (props: any) => {

    const item = props.data

    const [tripStatus, setTripStatus] = React.useState('')
    const [checkStatus, setCheckeStatus] = React.useState(false)

    const openStatusModal = (status: any) => {
        setTripStatus(status)
        setCheckeStatus(true)
        setTimeout(() => {
            setCheckeStatus(false)
        }, 2000)
    }

    const openReassignModal = (tripStatus:any, data: any) => {
        props.openReassignModalModal(tripStatus, data)
    }

    return (
        <Col xl={4} md={6} className='mb-1 p-1'>
            {checkStatus ? <OpenToast text={tripStatus} /> : <></>}
            <Card className='p-2'>
                <Row>
                    <TripCard data={item} openStatusModal={openStatusModal} />
                </Row>
                <Row className='mt-2'>
                    <Col className='d-flex justify-content-end'>

                        <input type="button" value='Re-Assign Vehicle' className='btn btn-sm ms-1 text-black' style={{ border: `1px solid gray` }} onClick={() => {
                            openReassignModal('reassign', item)
                        }} />

                        <input type="button" value='Parking In' className='btn btn-sm ms-1 text-black' style={{ border: `1px solid gray` }} onClick={() => {
                            openReassignModal('parkIn', item)
                        }} />

                    </Col>
                </Row>
            </Card>
        </Col>
    )
}

const ReportingPendingForPlantUser = (props: any) => {
    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const searchText = useAppSelector((state: RootState) => state.search.text);

    const [trips, setTrips] = React.useState<any>([])
    const [tripDetails, setTripDetails] = React.useState<any>({})
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        getAllTrips()
    }, [searchText])

    const getAllTrips = async () => {
        setLoading(true)
        const path = 'Trips/GetTrips';
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "UserType": user.userType,
            "Type": 'Reporting Pending',
            "Page": 1,
            "search": searchText,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        setLoading(false)
        if (response.succeeded) {
            // var data = response.data.allTrips;
            // response.data.allTrips.map((item: any) => {
            //     var today = moment(new Date())
            //     var expectedDate = moment(item.expectedLoadingDate, 'DD MMM, YYYY')
            //     expectedDate = moment(expectedDate, 'DD MM YYYY').add(24, 'hours')
            //     item.isDelayed = today.isSameOrAfter(expectedDate)
            // })
            setTrips(response.data.allTrips);
        }
    }

    const [reAssignVehicleModalVisible, setReassignVehicleModalVisible] = React.useState(false);
    const [showParkinModal, setShowParkinModal] = React.useState(false)

    const handleCloseReassignVehicle = () => setReassignVehicleModalVisible(false)
    const handleOpenReassignVehicle = () => setReassignVehicleModalVisible(true)

    const handleCloseParkinModal = () => setShowParkinModal(false)
    const handleOpenParkinModal = () => setShowParkinModal(true)

    const openReassignModalModal = React.useCallback((type:any, tripDetails: any) => {
        setTripDetails(tripDetails)
        if(type == 'reassign'){
            handleOpenReassignVehicle()
        }else{
            handleOpenParkinModal()
        }
    }, [])

    const closeModal = React.useCallback(() => {
        handleCloseReassignVehicle()
        handleCloseParkinModal()
        getAllTrips()
    }, [])

    return (
        <Container fluid>

            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Row>
                    {trips.length > 0 ? (
                        <>
                            {trips.map((item: any) => <TripBaseCard data={item} key={item.id} openReassignModalModal={openReassignModalModal} />)}
                        </>
                    ) : <NotFound />}
                </Row>
            )}

            <Modal show={reAssignVehicleModalVisible} onHide={handleCloseReassignVehicle} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton>
                    <Modal.Title>Re-Assign Vehicle</Modal.Title>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    <ReassignVehicle assignDetails={tripDetails} flag='reassign' closeModal={closeModal} />
                </Modal.Body>
            </Modal>

            <Modal show={showParkinModal} onHide={handleCloseParkinModal} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton>
                    <Modal.Title>Parking In</Modal.Title>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    <ParkingIn closeModal={closeModal} data={tripDetails} status={'parkIn'} />
                </Modal.Body>
            </Modal>

        </Container>
    )
}

export default ReportingPendingForPlantUser