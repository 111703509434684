import { Action, createSlice } from "@reduxjs/toolkit";
import type { RootState } from '../../store/Index';
interface CounterState {
    checkLogout: boolean
}

const initialState: CounterState = {
    checkLogout: false
}

const logoutUserOnDeviceChangeSlice = createSlice({
    name: 'logout',
    initialState : initialState,
    reducers: {
        logoutUser: (state) => {
            state.checkLogout = !state.checkLogout
        }
    }
});

export default logoutUserOnDeviceChangeSlice.reducer;

export const {
    logoutUser
} = logoutUserOnDeviceChangeSlice.actions;
