import React from 'react'
import { Card, Col, Container, Row, Spinner, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/Index';
import { useAppSelector } from '../../../../store/hooks';
import { get, post } from '../../../../config/services/api/ApiServices';
import TripCard from '../../components/trip_card/Index';
import { CommonStyles } from '../../../../common/styles/CommonStyles';
import NotFound from '../../../notfound/Indesx';
import Inspection from '../../components/gate_and_parkIn_inspection/Index';
import { OpenToast } from '../../../../components/toast/Index';

export const TripBaseCard = (props: any) => {

    const item = props.data

    const [tripStatus, setTripStatus] = React.useState('')
    const [checkStatus, setCheckeStatus] = React.useState(false)

    const openStatusModal = (status: any) => {
        setTripStatus(status)
        setCheckeStatus(true)
        props.openParkInInspectionModal(status, item)
        setTimeout(() => {
            setCheckeStatus(false)
        }, 2000)
    }

    return (
        <Col xl={4} md={6} className='mb-1 p-1'>
            {checkStatus ? <OpenToast text={tripStatus} /> : <></>}
            <Card className='p-2'>
                <Row>
                    <TripCard data={item} openStatusModal={openStatusModal} />
                </Row>
            </Card>
        </Col>
    )
}

const ReportedForPlantUser = (props: any) => {
    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const searchText = useAppSelector((state: RootState) => state.search.text);

    const [trips, setTrips] = React.useState<any>([])
    const [tripData, setTripData] = React.useState<any>(null)
    const [loading, setLoading] = React.useState(false)

    const [msg, setMsg] = React.useState('')
    const [showMsg, setShowMsg] = React.useState(false)
    const [color, setColor] = React.useState('danger')

    const [showParkInInspectionModal, setShowParkInInspectionModal] = React.useState(false)

    React.useEffect(() => {
        getAllTrips()
    }, [searchText])

    async function getAllTrips() {
        const path = 'Trips/GetTrips';
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "UserType": user.userType,
            "Type": 'reported',
            "Page": 1,
            "search": searchText,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        setLoading(true);
        var response = await get(path, body, header);
        setLoading(false);

        if (response.succeeded) {
            setTrips(response.data.allTrips);
        }
    }

    const openParkInInspectionModal = React.useCallback((status: any, data: any) => {
        if (status == 'Parking In') {
            setTripData(data)
            setShowParkInInspectionModal(true)
        } else if (status == 'Parking Out') {
            if (window.confirm('Are you sure to exit parking') == true) {
                exitParking(data?.id)
            }
        }
    }, [])

    async function exitParking(id: any) {
        setLoading(true)
        const path = 'Trips/ParkingOut';
        const body = {
            "tripID": id,
            "userID": user.userID,
            "userType": user.userType
        }

        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }

        var response = await post(path, body, header);
        setLoading(false)
        if (response.succeeded) {
            setColor('success')
            setMsg(response.message)
            setShowMsg(true)
            getAllTrips()
        } else {
            setColor('danger')
            setMsg(response.message || 'Something went wrong')
            setShowMsg(true)
        }
        setTimeout(() => {
            setShowMsg(false)
        }, 3000)
    }
    const closeParkInInspectionModal = () => {
        getAllTrips()
        setShowParkInInspectionModal(false)
    }

    const handleCloseParkinModal = () => setShowParkInInspectionModal(false)

    return (
        <Container fluid>
            {showMsg ? <div className={`alert alert-${color} text-center p-1`}>{msg}</div> : <></>}
            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Row>
                    {trips.length > 0 ? (
                        <>
                            {trips.map((item: any) => <TripBaseCard data={item} key={item.id} openParkInInspectionModal={openParkInInspectionModal} />)}
                        </>
                    ) : <NotFound />}
                </Row>
            )}

            <Modal show={showParkInInspectionModal} onHide={closeParkInInspectionModal} fullscreen style={{ marginTop: 80, borderRadius: 20, paddingBottom: 80 }}>
                <Modal.Header closeButton>
                    <Modal.Title>Parking In Inspection</Modal.Title>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    <Inspection data={tripData} closeModal={closeParkInInspectionModal} status={'parkInInspection'} />
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default ReportedForPlantUser