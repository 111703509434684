import React from "react";
import { Card, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { Style } from "./Style";
import { CommonStyles } from "../../common/styles/CommonStyles";
import { useNavigate } from "react-router-dom";
import { post } from "../../config/services/api/ApiServices";
import { useAppDispatch } from "../../store/hooks";
import { setAuth } from "../../store/slices/authentication";
import { addUser } from "../../store/slices/user";
import { FaCheck } from "react-icons/fa";
import OtpInput from 'react-otp-input';
import { enableSaveDeviceToken } from "../../store/slices/saveToken";

const Login = (props: any) => {

    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        var user: any = sessionStorage.getItem('user')
        if (user && user.userID != 0) {
            navigate('/home')
        }
    }, [])


    const dispatch = useAppDispatch();

    const [show, setShow] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [color, setColor] = React.useState('danger');
    const [error, seterror] = React.useState('Incorrect Mobile number !')
    const [mobile, setMobile] = React.useState('')
    const [otp, setOtp] = React.useState('')

    const [switchToOtp, setSwitchToOtp] = React.useState(0)
    const [resendTime, setResendTime] = React.useState(0)

    const [disable, setDisable] = React.useState(false);

    React.useEffect(() => {
        const timer: any = resendTime > 0 && setInterval(() => setResendTime(resendTime - 1), 1000);
        return () => clearInterval(timer);
    }, [resendTime]);

    const hideAlert = () => {
        setTimeout(() => {
            setShowError(false)
        }, 4000)
    }

    const getOtp = async () => {

        setResendTime(30)
        setDisable(true)
        setShowError(false)

        const path = 'Account/getOtp'
        const body = {
            number: mobile,
            code: '',
            enablePreviewFeatures: true
        }
        const header = {
            "Content-Type": "application/json",
            'Accept': '*/*'
        }

        const response = await post(path, body, header)
        if (response.succeeded) {
            seterror(response.message)
            setColor('success')
            setShowError(true)
            setSwitchToOtp(1)
        } else {
            seterror(response.message || 'Incorrect mobile number')
            setShowError(true)
            setColor('danger')
            setShowError(true)
            console.log(response.message || 'Something went wrong')
        }
        hideAlert()
        setDisable(false)
    }

    const verifyOtp = async () => {
        setDisable(true)
        const path = 'Account/verifyOtp'
        const body = {
            number: mobile,
            code: otp,
            enablePreviewFeatures: true
        }
        const header = {
            "Content-Type": "application/json",
            'Accept': '*/*'
        }

        const response = await post(path, body, header)
        if (response.succeeded) {
            seterror(response.message)
            dispatch(enableSaveDeviceToken(true))
            setColor('success')
            setShowError(true)
            let authorization = {
                jwToken: response.data.jwToken,
                refreshToken: response.data.refreshToken,
                jwTokenExpiryDate: response.data.jwTokenExpiryDate,
                refreshTokenExpiryDate: response.data.refreshTokenExpiryDate,
            }
            sessionStorage.setItem('authorization', JSON.stringify(authorization));
            sessionStorage.setItem('user', JSON.stringify(response.data));
            dispatch(setAuth(authorization));
            dispatch(addUser(response.data));
            setTimeout(() => {
                navigate('home')
            }, 2000)
        } else {
            seterror(response.message)
            setColor('danger')
            setShowError(true)
            console.log(response.message || 'Something went wrong')
        }
        hideAlert()
        setTimeout(() => {
            setDisable(false)
        }, 2000)
    }

    const validateMobile = () => {
        if (mobile == '' || mobile.length != 10) {
            setColor('danger')
            setShowError(true)
        } else {
            getOtp()
        }
    }

    const validateOtp = () => {
        if (otp == '' || otp.length != 4) {
            seterror('Incorrect OTP please try again')
            hideAlert()
            setColor('danger')
            setShowError(true)
        } else {
            verifyOtp()
        }
    }

    const setMobileNumber = (event: any) => {

        let value = event.target.value.length

        if (value > 10) {
            setShowError(false)
        } else {
            if (!value) {
                seterror('Invalid mobile number')
                setShow(false)
                setMobile(event.target.value)
            } else {
                if (value == 10) {
                    setShow(true)
                } else {
                    setShow(false)
                }
                setMobile(event.target.value)
            }
        }
    }

    const changeNumber = () => {
        setShowError(false)
        setMobile('')
        setShow(false)
        setSwitchToOtp(0)
    }

    const handleClickSubmitMobile = (event: any) => {
        if (event.key == 'Enter') {
            validateMobile()
        }
    }

    return (
        <>
            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status" >
                    </Spinner>
                </div>
            ) : (
                <Container fluid className="p-0 m-0 min-vh-100" style={{ overflow: 'hidden', position: 'relative' }}>

                    <div className="sideCard left" style={{ position: 'absolute', width: 500, height: window.innerHeight * 0.5, borderRadius: 100, rotate: '45deg', left: -200, bottom: -50, background: 'linear-gradient(to bottom, green, #54c700)' }}></div>

                    <Container className="d-flex justify-content-center align-items-center min-vh-100">
                        <Card style={{ zIndex: 10, width: 550, boxShadow: '1px 2px 20px 0px #f3f3f3' }} className="p-5 align-items-center border-0">

                            {showError ? <div className={`alert alert-${color} w-100 text-center`}>{error}</div> : <></>}

                            <Row>
                                <Col className="justify-content-center">
                                    <Image src={require('../../assets/images/ib_logo.png')} style={CommonStyles.loginLogos} />
                                    <Image src={require('../../assets/images/abis_logo.png')} style={{ ...CommonStyles.loginLogos, height: 40 }} />
                                </Col>
                            </Row>
                            <Row className="pt-3">
                                <h3 style={{ color: 'gray' }} className="text-center">Welcome to ABIS SHIPMENT</h3>

                                {!switchToOtp ? (
                                    <p className="text-secondary text-center m-0">To keep connected with us please login with your mobile no and otp.</p>
                                ) : (
                                    <p className="text-secondary text-center m-0">Entered wrong number <a onClick={changeNumber} className="text-decoration-underline" style={{ cursor: 'pointer' }}>change number?</a></p>
                                )}

                                <span className="px-3">

                                    {!switchToOtp ? (
                                        <Row className="p-0 m-0 mt-3 position-relative">
                                            <label htmlFor="mobile" className="p-0 m-0"><h5 style={{ color: 'gray' }}>Enter mobile number</h5></label>
                                            <input type="number" value={mobile} placeholder="mobile number" className="mobile border-0 rounded p-3 ps-4 phone validate" onChange={setMobileNumber} style={Style.input} maxLength={10} onKeyDown={handleClickSubmitMobile} />

                                            {show ? <p style={{ position: 'absolute', top: 53, right: 10, width: '10%' }} className="text-success text-right d-flex justify-content-end"><FaCheck /></p> : <></>}
                                            {/* {showError ? <p className="m-0 p-0" style={{ color: 'red' }}>{error}</p> : <></>} */}
                                        </Row>
                                    ) : (
                                        <Row className="p-0 m-0 mt-3 text-center">
                                            <span className="d-flex justify-content-center p-0 align-items-center text-center">
                                                {resendTime > 0 ? (
                                                    <p className="m-0 p-0 d-flex">Don't recieve an SMS resend in&nbsp; <a> {resendTime} </a> sec</p>
                                                ) : (
                                                    <a className="m-0 p-0 d-flex justify-content-end" onClick={getOtp} style={{ cursor: 'pointer' }}>Resend</a>
                                                )}
                                            </span>

                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={4}
                                                placeholder="0000"
                                                shouldAutoFocus={true}
                                                inputType={'number'}
                                                inputStyle={Style.otpBox}
                                                containerStyle={{ justifyContent: 'center' }}
                                                renderInput={(props) => <input {...props} onKeyDown={() => {
                                                    if (otp.length > 3) {
                                                        validateOtp()
                                                    }
                                                }} />}
                                            />
                                        </Row>
                                    )}

                                    <Row className="p-0 m-0 my-3 mt-5">
                                        <Col className="p-0 m-0 text-center">
                                            {!switchToOtp ? (
                                                <input type="button" value="GET OTP" className={`btn rounded text-white p-2 px-4`} style={{ backgroundColor: disable ? 'gray' : 'green' }} onClick={validateMobile} />
                                            ) : (
                                                <input type="button" value="LOGIN" className={`btn rounded text-white p-2 px-4`} style={{ backgroundColor: disable ? 'gray' : 'green' }} onClick={validateOtp} />
                                            )}
                                        </Col>
                                    </Row>

                                    {/* <p className="m-0 text-center pt-3">For more details contact us</p> */}
                                </span>
                            </Row>
                        </Card>
                    </Container>

                    <div className="sideCard left" style={{ position: 'absolute', width: 500, height: window.innerHeight * 0.5, backgroundColor: 'green', borderRadius: 100, rotate: '45deg', right: -200, top: -50, background: 'linear-gradient(to top, #54c700, green)' }}></div>

                </Container>
            )}
        </>
    )
}

export default Login