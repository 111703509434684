export const Style = {
    loginImage: {
        height: '100%',
        width: '100%'
    },
    loginBox: {
        borderWidth: 1,
        borderColor: 'gray',
        borderStyle: 'solid',
        borderRadius: 10
    },
    sideCard: {
        position: 'absolute',
        width: window.innerWidth * 0.3,
        height: window.innerHeight * 0.5,
        backgroundColor: 'green',
        borderRadius: 100,
        rotate: '45deg',
        left: -200,
        bottom: -50
    },
    input: {
        backgroundColor: '#f9f9f9',
        outline: 0
    },
    otpBox: {
        padding: 10,
        margin: 10,
        width: 50,
        backgroundColor: 'lightgray',
        border: 0,
        borderRadius: 5,
        outline: 0
    }
}