import React, { useEffect } from "react";
import { Header } from "../../components/header/Index";
import { Container, Row, Col, Card, Image, Alert } from "react-bootstrap";
import SideBarMenu from "../../components/sideBarMenu/Index";
import { get, post, postSocketApi } from "../../config/services/api/ApiServices";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/Index";
// import { getDeviceToken } from "../../firebase";
import { useNavigate } from "react-router-dom";
import CommonColors from "../../common/colors/CommonColor";
import rpBg from '../../assets/images/yellow-grain.png'
import { FaArrowRight, FaArrowUp, FaStar, FaTruckPickup } from "react-icons/fa";
import { FetchUserData, getMenu } from "../../common/functions/CommonFunctions";
import { CommonStyles } from "../../common/styles/CommonStyles";
import { enableSaveDeviceToken } from "../../store/slices/saveToken";
import { io } from "socket.io-client";
import { setSocket, setSocketAuth } from "../../store/slices/socketSlice";
import { SocketUrl } from "../../config/constants/Constants";

const Home = (props: any) => {

    React.useEffect(() => {
        var user: any = sessionStorage.getItem('user')
        if (user && user.userID == 0) {
            navigate('/login')
        }
    })

    var navigate = useNavigate()

    const dispatch = useAppDispatch();
    FetchUserData(dispatch)

    const user = useAppSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const enableSaveToken = useAppSelector((state: any) => state.saveToken)
    const [homeData, setHomeData] = React.useState<any>({});

    const first_card_level = user.userType === "Plant Secuirity Officer" ? `Gate in\nPending` : `Reporting\nPending`;
    const secend_card_level = user.userType === 'SecuirityOfficer' ? `Reported\n` : `Under\nLoading`;
    const third_card_level = user.userType === 'Transporter' ? `In\nTransit` : `Gate out\nPending`;

    const first_card_text = '';
    const secend_card_text = '';
    const third_card_text = '';

    const [callSupportNumber, setCallSupportNumber] = React.useState('');
    const [emailSupportAddress, setEmailSupportAddress] = React.useState('');

    const [disableFeedbackBtn, setDisableFeedbackBtn] = React.useState(true)
    const [show, setShow] = React.useState(false);
    const [showAlertMsg, setShowAlertMsg] = React.useState('');

    const [loading, setLoading] = React.useState(false)
    const [preparing, setPreparing] = React.useState(false)

    const getSocketAuthorization = async () => {
        const path = 'auth'
        const body = {
            user_id: user.userID
        }
        const res = await postSocketApi(path, body, {})
        if (res.status == 200) {
            localStorage.setItem('socketAuth', JSON.stringify(res.authorization))
            dispatch(setSocketAuth(res.authorization))
        }
    }

    async function initializeSocketIO() {
        const socket = await io(SocketUrl);
        if (socket) {
            dispatch(setSocket(socket));
            socket.emit("join", { "name": user.userName, "id": user.userID, "TransporterID": user.transporterID });
            await getSocketAuthorization()
            return () => {
                socket.off('join')
            }
        }
    }

    React.useEffect(() => {
        const getHomeDatas = async () => {
            setPreparing(true)
            await initializeSocketIO()
            await getNotificationPermission()
            GetAppSettings()
            await getMenu(user, auth, dispatch);
            await getDashboardData()
            setPreparing(false)
        }
        getHomeDatas()
    }, [])

    const getNotificationPermission = async () => {
        const permission = await Notification.requestPermission()
        if (permission == 'granted') {
            await saveToken()
        } else {
            console.log('perission denied')
        }
        setLoading(false)
    }

    const style = {
        bgImage: {
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `url(${rpBg})`,
        },
        cursorPointer: {
            cursor: 'pointer'
        }
    }

    const [feedback, setFeedback] = React.useState('')
    const handleFeedbackOnChange = (e: any) => {
        if (e.target.value) {
            setDisableFeedbackBtn(false)
        } else {
            setDisableFeedbackBtn(true)
        }
        setFeedback(e.target.value)
    }

    return (
        <>
            {loading ? (
                <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, margin: 0, position: 'absolute', zIndex: 100 }} className="allowNotify">
                    <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, margin: 0, position: 'absolute', zIndex: 100 }} className="allowNotify">

                        <div style={{ backgroundColor: 'white', padding: 50, borderRadius: 10, display: 'flex' }}>
                            <img src={require('../../assets/images/notify.png')} alt="" style={{ width: 250, marginRight: 30, borderRadius: 10 }} />
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 20 }}>
                                    <div className="spinner-border text-success" role="status" ></div>
                                </div>
                                <p className="p-0 m-0 mt-4 text-center">please wait ...</p>
                                <h5 className="p-0 m-0 mt-2 text-center">Allow notification</h5>
                                <div className="d-flex justify-content-center px-3" onClick={() => window.location.reload()}>
                                    <input type="button" value="Reload" className="form-control mt-4 btn btn-sm btn-success rounded-pill" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            ) : <></>}

            <Container fluid style={{ width: '100%', height: '100vh' }} className="contentArea">
                {preparing ? (
                    <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, margin: 0, position: 'absolute', zIndex: 100 }} className="allowNotify">
                        <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, margin: 0, position: 'absolute', zIndex: 100 }} className="allowNotify">

                            <div style={{ backgroundColor: 'white', padding: 50, borderRadius: 10, display: 'flex' }}>
                                <div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 20 }}>
                                        <div className="spinner-border text-success" role="status" ></div>
                                    </div>
                                    <p className="p-0 m-0 mt-4 text-center">please wait ...</p>
                                    <h5 className="p-0 m-0 mt-2 text-center">Preparing your data ...</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                ) : <></>}

                <Header breadCrumb='Home' />
                <Row>
                    <SideBarMenu selectedPath='Home' />
                    <Col className="p-2 contentArea" style={{ backgroundColor: '#f5f5f5', overflowY: 'scroll', height: '90vh' }}>
                        <Card className="border-0 contentArea" >
                            <Card.Body className="m-0">

                                <Row>
                                    <Col xl={8}>
                                        <Row className=" mt-2">
                                            <Col xl={4} md={6}>
                                                <Card className="p-4 d-flex justify-content-center border-0 mb-2" style={{ ...style.bgImage, ...style.cursorPointer }} title={first_card_level} onClick={() => navigate('/trips', { state: { index: 0 } })}>
                                                    <FaTruckPickup size={40} color={CommonColors.PRIMARY} />
                                                    <span className="d-flex align-items-center">
                                                        <h5 className="pt-2 m-0 pe-2">{homeData.reportingPendingCount} {first_card_text}</h5>
                                                        <p className="pt-2 m-0">{first_card_level}</p>
                                                    </span>
                                                </Card>
                                            </Col>
                                            <Col xl={4} md={6}>
                                                <Card className="p-4 d-flex justify-content-center mb-2" style={{ ...style.cursorPointer }} title={secend_card_level} onClick={() => navigate('/trips', { state: { index: 1 } })}>
                                                    <FaTruckPickup size={40} color="gray" />
                                                    <span className="d-flex align-items-center">
                                                        <h5 className="pt-2 m-0 pe-2">{homeData.readyForDispatchCount} {secend_card_text}</h5>
                                                        <p className="pt-2 m-0 ">{secend_card_level}</p>
                                                    </span>
                                                </Card>
                                            </Col>
                                            {user.userType !== 'SecuirityOfficer' ? (
                                                <Col xl={4} md={6}>
                                                    <Card className="p-4 d-flex justify-content-center mb-2" style={{ ...style.cursorPointer }} title={third_card_level} onClick={() => navigate('/trips', { state: { index: 2 } })}>
                                                        <FaTruckPickup size={40} color="gray" />
                                                        <span className="d-flex align-items-center">
                                                            <h5 className="pt-2 m-0 pe-2">{homeData.inTransitCount} {third_card_text}</h5>
                                                            <p className="pt-2 m-0">{third_card_level}</p>
                                                        </span>
                                                    </Card>
                                                </Col>
                                            ) : <></>}
                                        </Row>

                                        <Row className="">
                                            <Col>
                                                <Card className="p-2">
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="d-flex align-items-center">
                                                                <img src={require('../../assets/images/profile.png')} alt="profile" style={{ width: 40, height: 40 }} />
                                                                <div className="ps-2 align-items-center">
                                                                    <h5 className="p-0 m-0">{user.userName}</h5>
                                                                    <p className="p-0 m-0" style={CommonStyles.font_14}>+91 {user.userPhone}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {user.userType == 'Transporter' ? (
                                                            <Col className="d-flex justify-content-end align-items-center" md={6}>
                                                                <div>
                                                                    <span className="d-flex justify-content-end">
                                                                        {[...Array(5)].map((item: any, index: any) => {
                                                                            return <FaStar color={index + 1 <= user.rating ? "green" : "lightgray"} size={18} style={{ margin: 5 }} key={index} />
                                                                        })}
                                                                    </span>
                                                                    <p className="p-0 m-0">You are rated {user.rating} of 5 </p>
                                                                </div>
                                                            </Col>
                                                        ) : <></>}
                                                    </Row>

                                                    <Row className="mt-1">
                                                        <Col md={6} className="mt-2">
                                                            <div className="d-flex align-items-center" style={{ border: `1px solid gray`, cursor: 'pointer', borderRadius: 5 }} onClick={() => window.location.href = `mailto:${emailSupportAddress}`} title="email support">
                                                                <img src={require('../../assets/icons/email_support.png')} alt="email supprt" style={{ width: 50, height: 40 }} />
                                                                <p className="m-0 p-0" >Get our Email Support</p>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="mt-2">
                                                            <div className="d-flex align-items-center" style={{ border: `1px solid gray`, cursor: 'pointer', borderRadius: 5 }} onClick={() => window.location.href = `tel:${callSupportNumber}`} title="call support">
                                                                <img src={require('../../assets/icons/call_support.png')} alt="email supprt" style={{ width: 60, height: 40 }} />
                                                                <p className="m-0 p-0" >Get our Call Support</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                <div className="d-flex my-2 align-items-center">
                                                    <img src={require('../../assets/images/playStore.png')} alt="shipment" style={{ width: 30, height: 30 }} />
                                                    <h6 className="p-0 my-2">Get our apps on play store</h6>
                                                </div>
                                                <Col className="d-flex mt-2">
                                                    <div>
                                                        <a target='_blank' href="https://play.google.com/store/apps/details?id=com.abis.scm.mobile">
                                                            <img src={require('../../assets/images/abisShipmentQr.png')} alt="shipment" style={{ width: 200, height: 200 }} />
                                                        </a>
                                                        <h6 className="text-center">ABIS SHIPMENT ADVANCE</h6>
                                                    </div>
                                                    <div className="ms-2">
                                                        <a target='_blank' href="https://play.google.com/store/apps/details?id=com.abis.aio.track.mobile">
                                                            <img src={require('../../assets/images/abisTrackQr.png')} alt="shipment" style={{ width: 200, height: 200 }} />
                                                        </a>
                                                        <h6 className="text-center">ABIS TRACK ADVANCE</h6>
                                                    </div>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="w-100">
                                        <Card className="p-2 mt-2 mb-1">
                                            <Alert show={show} variant="success" dismissible>{showAlertMsg}</Alert>
                                            <h5 className="p-0 m-0">Feedback</h5>
                                            <p className="p-0" style={CommonStyles.font_14}>Give your feedback to us</p>

                                            <textarea name="feedback" id="" cols={30} rows={5} className="form-control" placeholder="write something ..." value={feedback} onChange={handleFeedbackOnChange}></textarea>
                                            <input type="button" value="Submit" className="btn text-white mt-3" style={{ backgroundColor: disableFeedbackBtn ? 'gray' : CommonColors.PRIMARY }} onClick={SubmitAppFeedback} disabled={disableFeedbackBtn} />
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )

    async function getDashboardData() {
        const path = 'Account/getHomeData';
        const body = {
            "userType": user.userType,
            "transporterId": user.transporterID,
            "userId": user.userID,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        if (response.succeeded) {
            setHomeData(response.data);
        }
    }

    async function saveToken() {
        setPreparing(true)
        const path = 'Devices/SaveToken';

        let userLength = user.userID.toString().length
        let str = userLength == 5 ? '' : (userLength == 4 ? '0' : (userLength == 3 ? '00' : (userLength == 2 ? '000' : '')))

        // let token: any = await getDeviceToken()
        // const body = {
        //     "application": "Abis Shipment",
        //     "userType": user.userType,
        //     "userId": user.userID,
        //     "clientId": `3fa85f64-5717-4562-b3fc-2c963f6${str + user.userID}`,
        //     "platform": "Web",
        //     "version": '',
        //     "buildNumber": '',
        //     "tokenString": token
        // }
        // const header = {
        //     "Content-Type": 'application/json',
        //     // "UserType": user.userType,
        //     "Authorization": auth.jwToken
        // }
        // await post(path, body, header);
    }

    async function GetAppSettings() {
        const path = 'Settings/GetAppSettings';
        const body = {
            "transporterId": user.transporterID
        }
        const header = {}
        var response = await get(path, body, header);
        if (response.succeeded) {
            setCallSupportNumber(response.data.CallSupportNumber);
            setEmailSupportAddress(response.data.EmailSupportAddress);
        }
    }

    async function SubmitAppFeedback() {
        const path = 'Settings/SubmitAppFeedback';
        const body = {
            "comment": feedback
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await post(path, body, header);
        if (response.succeeded) {
            setShowAlertMsg(response.message)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 5000)
        }
    }
}

export default Home