import React from 'react'
import { Card, Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { RootState } from '../../../../store/Index';
import { get } from '../../../../config/services/api/ApiServices';
import TripCard from '../../components/trip_card/Index';
import { CommonStyles } from '../../../../common/styles/CommonStyles';
import NotFound from '../../../notfound/Indesx';
import { FetchUserData } from '../../../../common/functions/CommonFunctions';
import TableHeader from '../../components/table_format/Index';
import moment from 'moment';

const PodPending = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const searchText = useAppSelector((state: RootState) => state.search.text);
    const apply:any = useAppSelector((state: RootState) => state.apply);

    const [trips, setTrips] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        getAllTrips()
    }, [searchText, apply.apply])

    const getAllTrips = async () => {
        setLoading(true)
        const path = 'Trips/GetTrips';
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "UserType": user.userType,
            "Type": 'in-transit',
            "Page": 1,
            "FromDate": moment(apply.fromDate).format('YYYY-MM-DDT00:00:00'),
            "ToDate": moment(apply.toDate).format('YYYY-MM-DDT00:00:00'),
            "IsPodGenerated": false,
            "search": searchText,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);

        setLoading(false)
        if (response.succeeded) {
            // var data = response.data.allTrips;
            // response.data.allTrips.map((item: any) => {
            //     var today = moment(new Date())
            //     var expectedDate = moment(item.expectedLoadingDate, 'DD MMM, YYYY')
            //     expectedDate = moment(expectedDate, 'DD MM YYYY').add(24, 'hours')
            //     item.isDelayed = today.isSameOrAfter(expectedDate)
            // })
            setTrips(response.data.allTrips);
        }
    }

    return (
        <Container fluid>
            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Row>
                    {trips.length > 0 ? (
                        <>
                            <Table>
                                <TableHeader />
                                <tbody className='contentArea'>
                                    {trips.map((item: any, index: any) => <TripCard data={item} tabName='inTransit' key={index} />)}
                                </tbody>
                            </Table>
                        </>
                    ) : <NotFound />}
                </Row>
            )}

        </Container>
    )
}

export default PodPending