import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface CounterState {
    jwToken: String,
    refreshToken: String,
    jwTokenExpiryDate: String,
    refreshTokenExpiryDate: String,
}

const initialState: CounterState = {
    jwToken: '',
    refreshToken: '',
    jwTokenExpiryDate: '',
    refreshTokenExpiryDate: '',
}

const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            // console.log(action.payload.jwTokenExpiryDate);
            state.jwToken = "Bearer " + action.payload.jwToken;
            state.refreshToken = action.payload.refreshToken;
            state.jwTokenExpiryDate = action.payload.jwTokenExpiryDate
            state.refreshTokenExpiryDate = action.payload.refreshTokenExpiryDate
        },
        removeAuth: () => {
            return initialState;
        }
    }
});

export default authSlice.reducer;
export const { setAuth, removeAuth } = authSlice.actions;
