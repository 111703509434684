export const Style = {
    rankBox: {
        // width: 40,
        // height: 40,
        borderRadius: 50,
        // backgroundColor: 'gray',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 3,
        paddingInline: 5
    }
}