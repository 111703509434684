import { createSlice } from "@reduxjs/toolkit";

interface CounterState {
    socketObj: any,
    socketAuth: any
}

const initialState: CounterState = {
    socketObj: null,
    socketAuth: null
}

const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        setSocket: (state, action) => {
            if(action.type == 'socket/setSocket'){
                state.socketObj = action.payload;
            }
        },
        setSocketAuth: (state, action) => {
            state.socketAuth = action.payload
        }
    }
});

export default socketSlice.reducer;
export const { setSocket, setSocketAuth } = socketSlice.actions;
