import React from "react";
import { Col, Container, Row, Card, Modal, ToastContainer, Toast, Table } from "react-bootstrap";
import SideBarMenu from "../../components/sideBarMenu/Index";
import { Header } from "../../components/header/Index";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/Index";
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import CommonColors from "../../common/colors/CommonColor";
import moment from "moment";
import { get, post } from "../../config/services/api/ApiServices";
import NotFound from "../notfound/Indesx";
import { FetchUserData } from "../../common/functions/CommonFunctions";
import Loader from "../trips/components/loader/Index";
import InputView from "../trips/components/input_disable_view/Index";
import { CommonStyles } from "../../common/styles/CommonStyles";
import Style from "../trips/components/trip_card/Style";
import Confirm from "../../components/confirm/Index";

const Contract = (props: any) => {

    const dispatch = useAppDispatch();
    FetchUserData(dispatch)

    const [contracts, setContracts] = React.useState([])
    const [contractDetails, setcontractDetails] = React.useState<any>({});

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const [disable, setDisable] = React.useState(false)

    const [isModalVisible, setIsModalVisible] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const [showToast, setShowToast] = React.useState(false)
    const [msg, setMsg] = React.useState('')
    const [regions, setRegions] = React.useState<any>({})
    const [unloadingPointCount, setUnloadingPointsCounts] = React.useState<any>(0)

    React.useEffect(() => {
        getAllContracts();
    }, [])

    const setRegionName = (response: any) => {
        let responseData = response;
        let newPoints: any = {}
        let newRegions: any = {}

        if (responseData.item.stateName.length > 0) {
            responseData.item.stateName.map((item: any) => {
                newRegions[item.unloadingPoint.replaceAll(' ', '_')] = item.region
            })
        } else if (responseData.item.regionName.length > 0) {
            responseData.item.regionName.map((item: any) => {
                newRegions[item.unloadingPoint.replaceAll(' ', '_')] = item.stateName
            })
        }

        setRegions(newRegions)

        responseData?.unloadingData.map((items: any) => {
            let keyPoint = items.location.replaceAll(' ', '_')

            let obj = {
                location: items.location,
                quantity: parseFloat(items.quantity),
                materialType: items.materialType,
                deliveryPointCount: items.deliveryPointCount
            }

            if (newPoints[keyPoint]) {
                newPoints[keyPoint].map((points: any) => {
                    if (points.location == obj.location) {
                        points.quantity += obj.quantity
                    } else {
                        newPoints[keyPoint].push(obj)
                    }
                })
            } else {
                newPoints[keyPoint] = [obj]
            }
        })

        responseData.points = newPoints;
        setUnloadingPointsCounts(Object.values(newPoints).length)        
        setcontractDetails(responseData)
    }

    const getAllContracts = async () => {
        setLoading(true)
        const path = 'Contracts/getAllContracts';
        const body = {
            "Type": user.type,
            "TransporterID": user.transporterID,
            "UserID": user.userID,
            "userType": user.userType,
            "Page": 1,
            "Search": '',
            "Sort": "",
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);        
        if (response.succeeded) {

            setContracts(response.data);
        }
        setLoading(false)
    }

    const hideModal = () => setIsModalVisible(false)

    const postContract = async (data:any) => {
        setDisable(true)
        const path = 'Contracts/PostContract';
        const body = {
            "biddingID": data?.item?.id || '',
            "transporterID": user.transporterID,
            "userID": user.userID,
            "userType": user.userType,
            "isConfirmLoad": true,
            "ApplicationType": "TMS",
            "committedVehicleSize": data?.item?.commitedVehicleSize == null ? "" : data?.item?.commitedVehicleSize
        }
        const header = {
            "Content-Type": 'application/json',
            "Authorization": auth.jwToken
        }        

        var response = await post(path, body, header);
        if (response.succeeded) {
            setMsg(response.message)
            setShowToast(true)
            setTimeout(() => {
                setIsModalVisible(false)
                getAllContracts()
            }, 2000)
        } else {
            setMsg(response.message)
            setShowToast(true)
            window.alert(response.message || 'Something went wrong')
        }
        setTimeout(() => {
            setShowToast(false)
        }, 2000)
    }

    const confirmContract = (data:any) => {
        if (window.confirm('Are you sure to accept this contract') == true) {
            postContract(data)
        }
    }

    const result = React.useCallback((isAccepted: any) => {
        // console.log(isAccepted)
    }, [])

    return (
        <Container fluid style={{ width: '100%', height: '100%' }}>

            {/* <Confirm title='Are you sure to accept the contract' result={result}/> */}

            {showToast ? (
                // <ToastContainer position="bottom-start" className="p-0 text-white m-2" style={{ zIndex: 1, position: 'fixed' }}>
                //     <Toast className='p-2 border-0 bg-success text-white' style={{ width: 400 }}>
                //         <Toast.Header className='border-0 bg-success'>
                //             <h6 className="me-auto text-white p-0 m-0">{msg}</h6>
                //             <small className="text-muted"></small>
                //         </Toast.Header>
                //     </Toast>
                // </ToastContainer>
                <></>
            ) : <></>}

            <Header breadCrumb='contracts' />
            <Row>
                <SideBarMenu selectedPath='Contracts' />
                <Col className="px-3 contentArea" style={{ backgroundColor: '#f5f5f5' }}>
                    <Row className="px-2">
                        <Card className="border-0" style={{ height: (window.innerHeight - 120) }}>
                            {loading ? <Loader /> : (
                                <Card.Body>
                                    {contracts.length > 0 ? (
                                        <>
                                            <Row >
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Contract/Bid Number</th>
                                                            <th>Loading point</th>
                                                            <th>Loading Date</th>
                                                            <th>Unloading points</th>
                                                            <th>Unloading Area</th>
                                                            <th>Delivery Date</th>
                                                            <th>Material Type (Quantity)</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {contracts.map((items: any) => {
                                                            // console.log(items)
                                                            return (
                                                                <tr className="hoverTr" style={{ cursor: 'pointer' }}>
                                                                    <td onClick={() => {
                                                                        setRegionName(items)
                                                                        setIsModalVisible(true)
                                                                    }}>
                                                                        <small className="text-primary" style={{ cursor: 'pointer' }}>{items.item.contractNumber}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.item.loadingPoint}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.item.loadingDate}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.item.unloadingPointCount < 10 ? '0' + items.item.unloadingPointCount : items.item.unloadingPointCount}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.item.unloadingArea}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.item.deliveryDate}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.item.materialType} ( {items.item.totalQuantity} )</small>
                                                                    </td>
                                                                    <td>
                                                                        <input type="button" value="Accept" className="btn btn-success btn-sm w-100" onClick={() => {
                                                                            confirmContract(items)
                                                                        }} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </>
                                    ) : <NotFound />}
                                </Card.Body>
                            )}
                        </Card>
                    </Row>
                </Col>
            </Row>

            <Modal show={isModalVisible} onHide={hideModal} animation={true} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton>
                    <Modal.Title>Contract Details </Modal.Title>
                </Modal.Header>
                <Modal.Body className="contentArea">
                    <Row>
                        <InputView label={contractDetails?.auctionType == "Bidding" ? 'Bidding Number' : 'Contract Number'} value={contractDetails?.item?.contractNumber} />
                        <InputView label={'Loading point'} value={contractDetails?.item?.loadingPoint || '-'} />
                        <InputView label={'Loading Date'} value={contractDetails?.item?.loadingDate || '-'} />
                        <InputView label={'Material Type (Quantity)'} value={(contractDetails?.item?.materialType + ` (${contractDetails?.item?.totalQuantity})`) || '-'} />
                        <InputView label={'Vehicle Type'} value={contractDetails?.item?.vehicleType || '-'} />
                        <InputView label={'Vehicle Capacity'} value={contractDetails?.item?.vehicleSize || '-'} />
                        <InputView label={'No of delivery stations'} value={unloadingPointCount || '-'} />
                        <InputView label={'Unloading Area'} value={contractDetails?.item?.unloadingArea || '-'} />
                        <InputView label={'Expected delivery date'} value={moment(contractDetails?.item?.deliveryDate).format('DD MMM, YYYY')} />

                        <Row>
                            <Col>
                                <strong>Note</strong>
                                <p className="p-0 m-0">{contractDetails?.item?.notes || '-'}</p>
                            </Col>
                        </Row>
                    </Row>

                    <Row className="mt-2"><h6>Unloading Points ( {contractDetails?.unloadingData?.length} )</h6></Row>

                    <Row>
                        <Col>
                            <Card className='p-2 mb-1 mt-2'>
                                <Row className='p-0 m-0'>
                                    <Col><h6>Delivery Station</h6></Col>
                                    <Col><h6>Unloading Point</h6></Col>
                                    <Col><h6>Material type</h6></Col>
                                    <Col><h6>Quantity</h6></Col>
                                </Row>
                                <hr className='p-0 m-1' />

                                {contractDetails.hasOwnProperty('points') && Object.keys(contractDetails?.points).map((pointskey: any) => {
                                    return (
                                        <Row className='p-0 m-0 mb-1'>
                                            <Col className="d-flex align-items-center">
                                                <p className='p-0 m-0 me-2' style={CommonStyles.circle}></p>
                                                <p className='p-0 m-0 me-2'>{pointskey.replaceAll('_', ' ')}</p>
                                                <p className='px-2 m-0 mx-2 rounded-pill text-white' style={{ backgroundColor: 'orange', fontSize: 12 }}>{regions && regions[pointskey]}</p>
                                            </Col>
                                            {contractDetails?.points?.hasOwnProperty(pointskey) && contractDetails?.points[pointskey].map((item: any) => {
                                                return (
                                                    <>
                                                        <Col>{item.deliveryPointCount}</Col>
                                                        <Col>{item.materialType}</Col>
                                                        <Col>{item.quantity} MT</Col>
                                                    </>
                                                )
                                            })}
                                        </Row>
                                    )
                                })}

                                {/* {contractDetails?.unloadingData?.map((items: any) => (
                                    <Row className='p-0 m-0 mb-1'>
                                        <Col className='d-flex align-items-center'><p className='p-0 m-0 me-2' style={CommonStyles.circle}></p>{items.location}</Col>
                                        <Col className='d-flex align-items-center'>{items.deliveryPointCount}</Col>
                                        <Col>{items.materialType}</Col>
                                        <Col>{items.quantity} MT</Col>
                                    </Row>
                                ))} */}
                            </Card>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer className="border-0">
                    <input type="button" value="Cancel" onClick={() => setIsModalVisible(false)} className="btn text-white bg-secondary" disabled={disable} />
                    <input type="button" value="Accept Contract" onClick={confirmContract} className="btn text-white " style={{ backgroundColor: disable ? 'gray' : CommonColors.PRIMARY }} disabled={disable} />
                </Modal.Footer>
            </Modal >
        </Container >
    )
}

export default Contract