import React from "react";
import { CommonStyles } from "../../common/styles/CommonStyles";
import { FaBus, FaFileContract, FaHammer, FaHome, FaMedal } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/hooks";
import { selectAssignCount, selectBidCount, selectContractsCount, selectHomeCount, selectTripsCount } from "../../store/slices/menuCount";

const SideBarMenu = (props: any) => {

    const dispatch = useDispatch()

    const [selectedMenu, setselectedMenu] = React.useState(props.selectedPath)
    const navigate = useNavigate()
    const user = useAppSelector((state: any) => state.user);

    const [hideMenu, setHideMenu] = React.useState(true)

    var isonSize = 18

    const menuItems:any = [
        {
            title: 'Home',
            path: '/home',
            count: useAppSelector(selectHomeCount),
            isMenuvisibleToUser: true,
            icon: <FaHome color={selectedMenu == 'Home' ? 'green' : 'white'} size={isonSize} />
        },
        {
            title: 'Bidding',
            path: '/bidding',
            count: useAppSelector(selectBidCount),
            isMenuvisibleToUser: user.userType == 'Transporter' ? true : false,
            icon: <FaHammer color={selectedMenu == 'Bidding' ? 'green' : 'white'} size={isonSize} />
        },
        {
            title: 'Contracts',
            path: '/contracts',
            count: useAppSelector(selectContractsCount),
            isMenuvisibleToUser: user.userType == 'Transporter' ? true : false,
            icon: <FaFileContract color={selectedMenu == 'Contracts' ? 'green' : 'white'} size={isonSize} />
        },
        {
            title: 'Assigns',
            path: '/assigns',
            count: useAppSelector(selectAssignCount),
            isMenuvisibleToUser: user.userType == 'Transporter' ? true : false,
            icon: <FaMedal color={selectedMenu == 'Assigns' ? 'green' : 'white'} size={isonSize} />
        },
        {
            title: 'Trips',
            path: '/trips',
            count: useAppSelector(selectTripsCount),
            isMenuvisibleToUser: true,
            icon: <FaBus color={selectedMenu == 'Trips' ? 'green' : 'white'} size={isonSize} />
        },
        {
            title: 'Account Statement',
            path: '/account-statement',
            count: 0,
            isMenuvisibleToUser: true,
            icon: <FaFileContract color={selectedMenu == 'Account Statement' ? 'green' : 'white'} size={isonSize} />
        },

    ]

    const changeTab = (items: any) => {
        navigate(items.path)
    }

    return (
        <>
            {hideMenu ? <div style={CommonStyles.sideBarContainer} className="pt-2" id="sideMenu">
                {menuItems && menuItems.map((items: any) => {
                    return (
                        <span key={items.title}>
                            {items.isMenuvisibleToUser ? (
                                <div className="p-1 my-3 d-flex justify-content-center align-items-center" style={{ backgroundColor: selectedMenu == items.title ? 'white' : '', borderRadius: 5, cursor: 'pointer', position: 'relative' }} onClick={() => changeTab(items)}>
                                    <div className="text-center pt-1" style={{ position: 'relative' }}>
                                        {items.icon}
                                        <p className="p-0 m-0 pt-1" style={{ color: selectedMenu == items.title ? 'green' : 'white', fontSize: 14 }}>{items.title}</p>
                                    </div>
                                    {items.count ? (
                                        <span style={{ position: 'absolute', right: 5, top: 5 }} className="justify-content-end">
                                            <p className="bg-warning rounded-pill px-1" style={CommonStyles.font_14}>{items.count < 10 ? '0' + items.count : items.count}</p>
                                        </span>
                                    ) : <></>}
                                </div>
                            ) : (<></>)}
                        </span>
                    )
                })}
            </div> : <></>}
        </>
    )
}

export default SideBarMenu