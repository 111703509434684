import React from 'react'
import { Card, Col, Container, Row, Spinner, Form, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/Index';
import { useAppSelector } from '../../../../store/hooks';
import { get, post } from '../../../../config/services/api/ApiServices';
import TripCard from '../../components/trip_card/Index';
import { CommonStyles } from '../../../../common/styles/CommonStyles';
import NotFound from '../../../notfound/Indesx';
import { OpenToast } from '../../../../components/toast/Index';
import { TrampaulinCovered, loadingDocuments } from '../../../../assets/temp/RadioButtonsList';
import CommonColors from '../../../../common/colors/CommonColor';

export const TripBaseCard = (props: any) => {

    const item = props.data

    const [tripStatus, setTripStatus] = React.useState('')
    const [checkStatus, setCheckeStatus] = React.useState(false)

    const openStatusModal = (status: any) => {
        setTripStatus(status)
        setCheckeStatus(true)
        if (status == 'Gate-Out Inspection') {
            props.openParkInInspectionModal(item, status)
        }
        setTimeout(() => {
            setCheckeStatus(false)
        }, 2000)
    }

    return (
        <Col xl={4} md={6} className='mb-1 p-2'>
            {checkStatus ? <OpenToast text={tripStatus} /> : <></>}
            <Card className='p-3'>
                <Row>
                    <TripCard data={item} openStatusModal={openStatusModal} />
                </Row>
            </Card>
        </Col>
    )
}

export const CheckBoxPrint = React.memo((props: any) => {

    const [keyItem, setKeyItem] = React.useState<any>(props.label || '')
    const [selectedValue, setSelectedValue] = React.useState<any>(props?.radioArray[0].label || '')

    const handleRadioChange = (e: any) => {
        setSelectedValue(e)
        props.changeData(keyItem, e)
    }

    return (
        <Row className='mt-2 px-1'>
            <Card className='p-2'>
                <h6>{props?.title}</h6>
                <Col>
                    {props?.radioArray.map((radios: any) => {
                        return (
                            <Form.Check
                                label={radios?.label}
                                type='radio'
                                style={{ paddingRight: 10, marginRight: 20 }}
                                checked={selectedValue == radios?.label ? true : false}
                                onChange={() => handleRadioChange(radios?.label)}
                            />
                        )
                    })}
                </Col>
            </Card>
        </Row>
    )
})

const GateOutPendingForparkingUser = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const searchText = useAppSelector((state: RootState) => state.search.text);

    const [trips, setTrips] = React.useState<any>([])
    const [loading, setLoading] = React.useState(false)

    const [gateOutInspectionModal, setGateOutInspectionModal] = React.useState(false)
    const [tripData, setTripData] = React.useState<any>({})

    const [remark, setRemark] = React.useState('')
    const [disable, setDisable] = React.useState(false)

    const [msg, setMsg] = React.useState('')
    const [showMsg, setShowMsg] = React.useState(false)
    const [color, setColor] = React.useState('danger')

    const [selectedData, setSelectedData] = React.useState<any>([])

    const array = [
        {
            name: 'dispatchDocuments',
            selected: loadingDocuments
        },
        {
            name: 'hamalClearance',
            selected: loadingDocuments
        },
        {
            name: 'tarpaulinCoveredProperlyOrNot',
            selected: TrampaulinCovered
        },
        {
            name: 'tarpaulinSealsAvailable',
            selected: loadingDocuments
        },
        {
            name: 'driverIdentification',
            selected: loadingDocuments
        },
        {
            name: 'vehicleFrisking',
            selected: TrampaulinCovered
        },
        {
            name: 'stepneyHandedOverToDriver',
            selected: TrampaulinCovered
        }
    ]

    React.useEffect(() => {
        array.map((item: any) => {
            selectedData[item?.name] = {
                selectedValue: item?.selected[0].label,
                name: item?.name
            }
        })
        setSelectedData(selectedData)
    }, [])

    React.useEffect(() => {
        getAllTrips()
    }, [searchText])

    async function getAllTrips() {
        const path = 'Trips/GetTrips';
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "UserType": user.userType,
            "Type": 'Gate Out Pending',
            "Page": 1,
            "search": searchText,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        setLoading(true);
        var response = await get(path, body, header);
        setLoading(false);

        if (response.succeeded) {
            setTrips(response.data.allTrips);
        }
    }

    const closeGateOutInspectionModal = () => setGateOutInspectionModal(false)
    const openGateOutInspectionModal = () => setGateOutInspectionModal(true)

    const openGateOutInspection = React.useCallback((tripData: any, status: any) => {
        setTripData(tripData)
        if (status == 'Gate-Out Inspection' && user.userType == 'Plant Secuirity Officer') {
            openGateOutInspectionModal()
        }
    }, [])

    const changeSelectedData = React.useCallback((keyItem: any, data: any) => {
        if (selectedData.hasOwnProperty(keyItem)) {
            selectedData[keyItem].selectedValue = data
            setSelectedData(selectedData)
        }
    }, [])

    async function GateOutInspectionSubmit() {
        if (window.confirm('Are you sure to proceed') == true) {
            setDisable(true)
            const path = 'Trips/GateOutInspection';
            const body = {
                "userID": user.userID,
                "userType": user.userType,
                "tripID": tripData?.id,
                "dispatchDocuments": selectedData['dispatchDocuments'].selectedValue || '',
                "driverIdentification": selectedData['dispatchDocuments'].selectedValue || '',
                "hamalClearance": selectedData['dispatchDocuments'].selectedValue || '',
                "tarpaulinCoveredProperlyOrNot": selectedData['dispatchDocuments'].selectedValue || '',
                "tarpaulinSealsAvailable": selectedData['dispatchDocuments'].selectedValue || '',
                "stepneyHandedOverToDriver": selectedData['dispatchDocuments'].selectedValue || '',
                "vehicleFrisking": selectedData['dispatchDocuments'].selectedValue || '',
                "remark": remark ? remark : ''
            }
            const header = {
                "Content-Type": 'application/json',
                // "UserType": user.userType,
                "Authorization": auth.jwToken
            }
            var response = await post(path, body, header);
            setDisable(false)
            if (response.succeeded) {
                setColor('success')
                setMsg(response.message)
                setShowMsg(true)
                setTimeout(() => {
                    closeGateOutInspectionModal()
                }, 2000)
            }
            else {
                setColor('danger')
                setMsg(response.message || 'Something went wrong')
                setShowMsg(true)
            }

            setTimeout(() => {
                setShowMsg(false)
            }, 2000)
        }
    }

    return (
        <Container fluid>
            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Row>
                    {trips.length > 0 ? (
                        <>
                            {trips.map((item: any) => <TripBaseCard data={item} key={item.id} openParkInInspectionModal={openGateOutInspection} />)}
                        </>
                    ) : <NotFound />}
                </Row>
            )}

            <Modal show={gateOutInspectionModal} onHide={closeGateOutInspectionModal} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton>
                    <Modal.Title>Gate Out Inspection</Modal.Title>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    {showMsg ? <div className={`alert alert-${color} text-center`}>{msg}</div> : <></>}
                    <Row>
                        <Col>
                            <h5>Trip Number</h5>
                            <Form.Control value={tripData?.tripNumber || '-'} disabled />
                        </Col>
                        <Col>
                            <h5>Vehicle Number</h5>
                            <Form.Control value={tripData?.vehicleNumber || '-'} disabled />
                        </Col>
                        <Col>
                            <h5>Driver's Name</h5>
                            <Form.Control value={tripData?.driverName || '-'} disabled />
                        </Col>
                        <Col>
                            <h5>Driver's Mobile Number</h5>
                            <Form.Control value={tripData?.driverPhone || '-'} disabled />
                        </Col>
                    </Row>

                    <Row className='mt-3 p-0 pt-1 mx-1' style={{ backgroundColor: 'lightgray' }}>
                        <Col>
                            <h5>Documents</h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='mx-2'>
                            <Row>
                                <Col>
                                    <CheckBoxPrint title={'Dispatch Documents (Invoice, Freight Slip, E-Waybill, Biliti, Gate Out, Farmer Slip)'} label={'dispatchDocuments'} radioArray={loadingDocuments} changeData={changeSelectedData} />
                                </Col>
                                <Col>
                                    <CheckBoxPrint title={'Hamal Clearance'} label={'hamalClearance'} radioArray={loadingDocuments} changeData={changeSelectedData} />
                                </Col>
                                <Col>
                                    <CheckBoxPrint title={'Driver Identification (Biliti, License)'} label={'driverIdentification'} radioArray={loadingDocuments} changeData={changeSelectedData} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-3 p-0 pt-1 mx-1' style={{ backgroundColor: 'lightgray' }}>
                        <Col>
                            <h5>Vehicle</h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='mx-2'>
                            <Row>
                                <Col>
                                    <CheckBoxPrint title={'Tarpaulin Covered Properly or Not'} label={'tarpaulinCoveredProperlyOrNot'} radioArray={TrampaulinCovered} changeData={changeSelectedData} />
                                </Col>
                                <Col>
                                    <CheckBoxPrint title={'Stepney Handed Over to Driver'} label={'stepneyHandedOverToDriver'} radioArray={TrampaulinCovered} changeData={changeSelectedData} />
                                </Col>
                                <Col>
                                    <CheckBoxPrint title={'Tarpaulin Seals Available'} label={'tarpaulinSealsAvailable'} radioArray={loadingDocuments} changeData={changeSelectedData} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <CheckBoxPrint title={'Vehicle Frisking (As per guidlines)'} label={'vehicleFrisking'} radioArray={TrampaulinCovered} changeData={changeSelectedData} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='my-2'>
                        <Col md={4}>
                            <h5>Remark</h5>
                            <Form.Control type='text' placeholder='remark' value={remark} onChange={(e: any) => setRemark(e.target.value)} />
                        </Col>
                    </Row>

                    <Row className='my-1'>
                        <Col className='d-flex justify-content-end'>
                            <input type="button" value="Submit" style={{ backgroundColor: disable ? 'gray' : CommonColors.PRIMARY }} className='btn text-white px-5 mt-3 ' onClick={GateOutInspectionSubmit} disabled={disable} />
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default GateOutPendingForparkingUser