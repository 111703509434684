import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Style from "./Style";
import NotParticipated from "./not_participated/Index";
import Participated from "./participated/Index";

const Ongoing = (props: any) => {

    const ongoingTabs: any = {
        'Not Participated': <NotParticipated />,
        'Participated': <Participated />
    }

    const [selectedTab, setSelectedTab] = React.useState('Not Participated')
    const [selectedComponent, setSelectedComponent] = React.useState<any>(<NotParticipated />)

    const changeTab = (items: any) => {
        setSelectedTab(items)
        setSelectedComponent(ongoingTabs[items])
    }

    return (
        <>
            <Row className="p-0 m-0 ">
                <hr className="p-0" />
                <Col className="d-flex p-0 m-0 ">
                    {Object.keys(ongoingTabs).map((items: any) => {
                        return (
                            <p className="me-2 px-3 py-1 text-center" style={{ backgroundColor: items == selectedTab ? 'green' : '#ebebeb', color: items == selectedTab ? 'white' : 'black', cursor: 'pointer', borderRadius: 4}} onClick={() => changeTab(items)} key={items}>{items}</p>
                        )
                    })}
                </Col>
            </Row>
            <Row className="p-0 m-0">
                <Col className="p-0 m-0">
                    {selectedComponent}
                </Col>
            </Row>
        </>
    )
}

export default Ongoing