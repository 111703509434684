import { Toast, ToastContainer } from "react-bootstrap"

export const OpenToast = (props: any) => {
    return (
        <ToastContainer position="top-end" className="p-0 text-white" style={{ zIndex: 1 }}>
            <Toast className='p-1 border-0'>
                <Toast.Header className='border-0'>
                    <h5 className="me-auto p-0 m-0">Trip is in {props?.text}</h5>
                </Toast.Header>
            </Toast>
        </ToastContainer>
    )
}