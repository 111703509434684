import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBarMenu from "../../../components/sideBarMenu/Index";
import { Header } from "../../../components/header/Index";
import BidCard from "../components/bidcard/BidCard";
import { bidList } from "../../../assets/temp/bid";
import NotFound from "../../notfound/Indesx";

const Archieve = (props:any) => {
    const [archieveBids, setArchievedBids] = React.useState([])
    return(
        <Row style={{ overflow: 'scroll', height: '76vh' }} className="contentArea">
            {archieveBids.length > 0 ? <>{archieveBids.map((bids: any) => <BidCard data={bids} key={bids.id}/>)}</> : <NotFound />}
        </Row>
    )
}

export default Archieve