import React from 'react'
import { Card, Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { RootState } from '../../../../store/Index';
import TripCard from '../../components/trip_card/Index';
import NotFound from '../../../notfound/Indesx';
import { get } from '../../../../config/services/api/ApiServices';
import { CommonStyles } from '../../../../common/styles/CommonStyles';
import { TripBaseCard } from '../../parkingUser/gate_in_pending/Index';
import { FetchUserData } from '../../../../common/functions/CommonFunctions';

const UnderLoadingForTransporter = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const searchText = useAppSelector((state: RootState) => state.search.text);

    const [trips, setTrips] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        getAllTrips()
    }, [searchText])

    const getAllTrips = async () => {
        setLoading(true)
        const path = 'Trips/GetTrips';
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "UserType": user.userType,
            "Type": 'Ready for Dispatch',
            "Page": 1,
            "search": searchText,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        setLoading(false)
        if (response.succeeded) {
            // var data = response.data.allTrips;
            // response.data.allTrips.map((item: any) => {
            //     var today = moment(new Date())
            //     var expectedDate = moment(item.expectedLoadingDate, 'DD MMM, YYYY')
            //     expectedDate = moment(expectedDate, 'DD MM YYYY').add(24, 'hours')
            //     item.isDelayed = today.isSameOrAfter(expectedDate)
            // })
            setTrips(response.data.allTrips);
        }
    }

    return (
        <Container fluid>
            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Row>
                    {trips.length > 0 ? (
                        <>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Shipment No</th>
                                        <th>Vehicle No</th>
                                        <th>Trip Date</th>
                                        <th>Unloading Points</th>
                                        <th>Unloading Area</th>
                                        <th>Material Type (Quantity)</th>
                                        <th>Delivery Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody className='contentArea'>
                                    {trips.map((item: any, index:any) => <TripCard data={item} key={index}/>)}
                                </tbody>
                            </Table>
                        </>
                    ) : <NotFound />}
                </Row>
            )}

        </Container>
    )
}

export default UnderLoadingForTransporter