import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useAppSelector } from '../../../../store/hooks';
import { RootState } from '../../../../store/Index';
import { get } from '../../../../config/services/api/ApiServices';
import TextView from '../text_view/Index';
import InputView from '../input_disable_view/Index';

const AdvancePaymentDetails = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);

    const [paymentDetails, setPaymentDetails] = React.useState<any>({})

    React.useEffect(() => {
        getPaymentDetails()
    }, [])

    async function getPaymentDetails() {
        const path = 'Payments/AdvancePaymentDetails';
        const body = {
            "TripId": props.tripId,
            "UserID": user.userID,
            "Usertype": user.userType
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }

        var response = await get(path, body, header);
        if (response) {
            setPaymentDetails(response.data[0])
        } else {
            window.alert(response.message || 'Something went wrong')
        }
    }

    return (
        <Container fluid className='p-0 m-0'>
            <Row className='mb-3'>
                <Col className='d-flex justify-content-between align-items-center'>
                    <span>
                        <h6>Total Trip Changes</h6>
                        <h4 className='text-success'>Rs. {paymentDetails.tripAmount} /-</h4>
                    </span>
                    <h6 className='p-2 px-4 rounded-pill text-end' style={{ backgroundColor: paymentDetails.statusColor }}>{paymentDetails.status}</h6>
                </Col>
            </Row>

            <Row>
                <InputView label='Shippment Number' value={paymentDetails.shipmentNumber || '-'} />

                {paymentDetails.typeOfPaymentTransporter == 'amount' ? (
                    <InputView label='Advance Amount' value={'Rs. ' + paymentDetails.advanceAmount || '0' + '/-'} />
                ) : (
                    <InputView label='Amount Percentage' value={paymentDetails.percentage || '0' + ' %'} />
                )}

                <InputView label='Remaining Amount' value={'Rs. ' + paymentDetails.pendingAmount || '0' + '/-'} />
                <InputView label='Payment done on' value={paymentDetails.createdOn || '-'} />
                <InputView label='Mode of payment (Transporter)' value={paymentDetails.modeOfpaymentTransporter || '-'} />
                <InputView label='Type of payment (Transporter)' value={paymentDetails.typeOfPaymentTransporter || '-'} />
                <InputView label="Driver's name" value={paymentDetails.driverName || '-'} />
                <InputView label="Driver's mobile number" value={paymentDetails.phoneNumber} />
                <InputView label='Mode of payment (Driver)' value={paymentDetails.modeOfPaymentDriver || '-'} />
                <InputView label='Type of payment (Driver)' value={paymentDetails.typeOfPaymentDriver || '-'} />
                <InputView label="Alternate driver's name" value={paymentDetails.alternativeDriverName || '-'} />
                <InputView label="Alternate driver's mobile number" value={paymentDetails.alternativeDriverPhone || '-'} />
            </Row>

        </Container>
    )
}

export default AdvancePaymentDetails