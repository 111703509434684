import React from 'react'
import { Modal, Col, Row, Table, Toast, ToastContainer } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/Index';
import { useAppSelector } from '../../../../store/hooks';
import { get, post } from '../../../../config/services/api/ApiServices';
import InputView from '../input_disable_view/Index';
import Loader from '../loader/Index';
import moment from 'moment';
import { setTripCardDetails } from '../../../../store/slices/tripCardDetails';

const TripDetails = (props: any) => {

    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);

    const [loading, setLoading] = React.useState(false)

    const [tripDetails, setTripDetails] = React.useState<any>([])
    const extraData = props?.data
    const tabName = props?.tabName

    const [msg, setMsg] = React.useState('')
    const [otp, setOtp] = React.useState('')
    const [deliveryId, setDeliveryId] = React.useState('')

    const [otpError, setOtpError] = React.useState(false)

    const [showToast, setShowToast] = React.useState(false)
    const [showOtpInputModalVisiable, setShowOtpInputModalVisiable] = React.useState(false)

    const handleOpenReassignVehicle = () => props.closeModal('reassign', extraData)
    const handleOpenAdvancePayment = () => props.closeModal('advancePayment', extraData)
    const handleOpenAdvancePaymentDetails = () => props.closeModal('advancePaymentDetails', extraData)
    // end 

    React.useEffect(() => {
        getTripData()
    }, [])

    const handleOpenViewPOD = (tripData: any, podModalOpen: any) => props.closeModal(podModalOpen, tripData, tripDetails?.tripDetails)

    const getTripData = async () => {
        setLoading(true)
        const path = 'Trips/GetTripsDetails';
        const body = {
            "ID": props.data.id,
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "UserType": user.userType,
            "TabName": props.tabName ? props.tabName : 'Gate Out Pending',
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        setLoading(false)
        if (response.succeeded) {
            setTripDetails(response.data);
            dispatch(setTripCardDetails(response.data.tripDetails))
        }
    }

    const verifyDriverOtp = async () => {
        var tripId = tripDetails?.tripDetails?.id
        const path = `POD/VerifyOTP?TripId=${tripId}&DeliveryAddressId=${deliveryId}&OTP=${otp}`;
        const body = {}
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await post(path, body, header);
        if (response.succeeded) {
            setMsg(response.message)
            showMsgToast()
            setShowOtpInputModalVisiable(false)
            hideMsgToast()
        } else {
            setOtpError(true)
        }
    }

    const hideMsgToast = () => {
        setTimeout(() => {
            setShowToast(false)
            getTripData()
        }, 2000)
    }

    const showMsgToast = () => {
        setShowToast(true)
    }

    const updateTripStatus = async (status: any, shippingID: any) => {
        const path = 'TripDetails/UpdateTripStatus'
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        const body = {
            ID: tripDetails?.tripDetails?.id,
            ShippingID: shippingID,
            Status: status
        }

        var response = await get(path, body, header);

        if (response.succeeded) {
            setMsg(response.message)
            showMsgToast()
            // setAlertMessage(response.message)
            // setEnableAlert(true)
            // setTimeout(() => {
            //     setEnableAlert(false)
            //     getTripData(viewDetailsData.tripDetails.id)
            // }, 2000);
            hideMsgToast()
        } else window.alert(response.message || 'Something Went wrong')
        // setVerifyOtp(false)

    }

    const enterPodVerifyOtp = async (deliveryId: any) => {
        setOtp('')
        await resend(deliveryId)
        setDeliveryId(deliveryId)
        setShowOtpInputModalVisiable(true)
    }

    const validateOtp = () => {
        if (otp.length != 4) {
            setOtpError(true)
        } else {
            verifyDriverOtp()
        }
    }

    const resend = async (shippingId: any) => {
        // const path = `TripDetails/ResendCustomerOtp?ID=${tripDetails?.tripDetails?.id}&ShippingID=${shippingId}&Status=${tripDetails?.tripDetails?.status}`
        const path = 'POD/TriggerCustomerOtp'

        const body = {
            "ID": 5493,
            "ShippingID": 5249,
            "Status": "Dispatched",
            "materials": []
        }
        const header = {
            'Content-Type': 'application/json',
            'Authorization': auth.jwToken
        }
        var response = await get(path, body, header)
        if (response.succedded) {
            setMsg(response.message)
            showMsgToast()
            setTimeout(() => {
                setShowToast(false)
            }, 2000)
            return true
        } else {
            setMsg(response.message || 'Failed to send OTP')
            showMsgToast()
            setTimeout(() => {
                setShowToast(false)
            }, 2000)
            return false
        }
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <Row>
                    {extraData?.tabStatus == 'Reporting Pending' && user.userType == 'Transporter' ? (
                        <div className='d-flex justify-content-end mb-2'>
                            <input type="button" value='Re-Assign Vehicle' className='btn btn-sm ms-1 btn-primary' style={{ border: `1px solid gray` }} onClick={handleOpenReassignVehicle} />

                            {extraData?.isPaymentRequested ? (
                                <input type="button" value='View Payment Details' className='btn btn-sm ms-1 btn-warning' style={{ border: `1px solid gray` }} onClick={handleOpenAdvancePaymentDetails} />
                            ) : (
                                <input type="button" value='Request Advance' className='btn btn-sm ms-1 btn-success ' style={{ border: `1px solid gray` }} onClick={handleOpenAdvancePayment} />
                            )}
                        </div>
                    ) : <></>}

                    {showToast ? (
                        <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
                            <Toast style={{ backgroundColor: 'green' }}>
                                <Toast.Header>
                                    <strong className="me-auto">{msg}</strong>
                                    <small className="text-muted"></small>
                                </Toast.Header>
                            </Toast>
                        </ToastContainer>
                    ) : <></>}

                    <InputView label='Trip Number' value={tripDetails?.tripDetails?.tripNumber || '-'} />
                    <InputView label='Bidding Number' value={extraData?.biddingNumber || '-'} />
                    <InputView label='Bid rate' value={'Rs. ' + (tripDetails?.tripDetails?.bidRate || '0')} />
                    <InputView label='Trip Start Date' value={tripDetails?.tripDetails?.tripStartsOn || '-'} />
                    <InputView label='Shipment Number' value={extraData?.shipmentNumber || '-'} />
                    <InputView label='Loading point' value={extraData?.loadingPoint || '-'} />
                    <InputView label='Loading Date' value={moment(extraData?.loadingDate).format('DD MMM, YYYY')} />
                    <InputView label='Total Load' value={tripDetails?.tripDetails?.totalMaterial || '-'} />
                    <InputView label='Vehicle Number' value={tripDetails?.tripDetails?.vehicleNumber || '-'} />
                    <InputView label='Driver Name' value={tripDetails?.tripDetails?.driverName || '-'} />
                    <InputView label='Driver Mobile Number' value={tripDetails?.tripDetails?.driverPhone || '-'} />
                    <InputView label='No of Delivery Stations' value={tripDetails?.tripDetails?.unloadingPointCount || '-'} />

                    <Row>
                        <Col>
                            <strong>Note</strong>
                            <p className="p-0 m-0">{tripDetails?.tripDetails?.notes || '-'}</p>
                        </Col>
                    </Row>

                    <strong className='mt-2'>Delivery Stations ({tripDetails?.tripDetails?.unloadingPoints.length})</strong>

                    <Row>
                        <Col>
                            <Table className='table'>
                                <thead>
                                    <tr>
                                        <th>Station Name</th>
                                        <th style={{ width: 200 }}>Address</th>
                                        <th>Material Type</th>
                                        <th>Customer Name</th>
                                        <th>Customer Phone</th>
                                        <th>Party Name</th>
                                        <th>Unloading Point</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tripDetails?.tripDetails?.unloadingPoints.map((delievrySations: any) => {
                                        return (
                                            <>
                                                {delievrySations?.deliveryAddress.map((points: any, index: any) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <small>{delievrySations?.unloadingPoint}
                                                                    <small style={{ backgroundColor: points?.statusColor }} className='p-1 px-2 rounded text-white mx-2'>{points?.currentStatus}</small>
                                                                </small>

                                                            </td>
                                                            <td><small>{points?.address}</small></td>
                                                            <td><small>{points?.materialType}</small></td>
                                                            <td><small>{points?.customerName}</small></td>
                                                            <td><small style={{ cursor: 'pointer', color: 'blue', textDecorationLine: 'underline' }}>{points?.customerPhone}</small></td>
                                                            <td><small>{points?.partyName}</small></td>
                                                            <td><small>{points?.unloadingPointName}</small></td>
                                                            <td className='p-0 m-0 py-1'>
                                                                <Row>
                                                                    {user.userType == 'Transporter' && tabName == 'inTransit' ? (
                                                                        <Col className='align-items-center'>
                                                                            {points?.arrivalButtonStatus == 'Completed' || points?.arrivalButtonText != 'Arrival' ? (
                                                                                <></>
                                                                            ) : (
                                                                                <input type="button" value={points?.arrivalButtonText} className={`btn btn-sm ms-1 mb-1 ${points?.arrivalButtonStatus != 'Active' ? 'btn-secondary' : 'btn-success'}`} disabled={points?.arrivalButtonStatus != 'Active' ? true : false} onClick={() => updateTripStatus(points.arrivalButtonText == 'Arrival' ? 'arrived' : 'unloaded', points.id)} />
                                                                            )}

                                                                            {points.uploadPODStatus == 'Completed' ? (
                                                                                <input type="button" value={points?.uploadPODText} className={`btn ms-1 btn-sm mb-1 btn-success`} onClick={() => handleOpenViewPOD({ shippingId: points.id, tripId: tripDetails?.tripDetails?.id }, false)} />
                                                                            ) : (
                                                                                <input type="button" value={points?.uploadPODText} className={`btn ms-1 btn-sm mb-1 btn-success`} onClick={() => handleOpenViewPOD(delievrySations, true)} />
                                                                            )}
                                                                        </Col>
                                                                    ) : <></>}
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Modal show={showOtpInputModalVisiable} onHide={() => setShowOtpInputModalVisiable(false)}>
                        <Modal.Header closeButton className='p-1 px-3'>
                            <h5>OTP Verification</h5>
                        </Modal.Header>
                        <Modal.Body className='contentArea'>

                            {otpError && <div className="alert alert-danger p-1 px-2" role="alert">
                                Incorrect OTP. Please try again after sometimes.
                            </div>}

                            <input type="number" value={otp} placeholder='x x x x' maxLength={4} className='form-control' style={{ border: `1px solid gray` }} onChange={(text: any) => {
                                setOtp(text.target.value.toString())
                            }} />

                            <input type="button" value='close' className='btn btn-sm ms-1 btn-secondary mt-3' style={{ border: `1px solid gray` }} onClick={() => {
                                setShowOtpInputModalVisiable(false)
                                setOtp('')
                                setOtpError(false)
                            }} />

                            <input type="button" value='Submit' className='btn btn-sm ms-1 btn-success mt-3' style={{ border: `1px solid gray` }} onClick={validateOtp} />
                        </Modal.Body>
                    </Modal>
                </Row>
            )}
        </>
    )
}

export default TripDetails