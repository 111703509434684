import { Action, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
interface CounterState {
    apply: boolean,
    fromDate: String,
    toDate: String
}

const initialState: CounterState = {
    apply: false,
    fromDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD')
}

const applyFilterSlice = createSlice({
    name: 'apply_filter',
    initialState: initialState,
    reducers: {
        applyFilter: (state, action) => {
            state.apply = action.payload
        },
        setFromDate: (state, action) => {
            state.fromDate = action.payload
        },
        setToDate: (state, action) => {
            state.toDate = action.payload
        },
    }
});

export default applyFilterSlice.reducer;

export const {
    applyFilter,
    setFromDate,
    setToDate
} = applyFilterSlice.actions;
