import React from 'react'
import { Card, Col, Container, Modal, Row, Spinner, Table } from 'react-bootstrap'
import TripCard from '../../components/trip_card/Index'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { RootState } from '../../../../store/Index'
import { get } from '../../../../config/services/api/ApiServices'
import NotFound from '../../../notfound/Indesx'
import { CommonStyles } from '../../../../common/styles/CommonStyles'
import ReassignVehicle from '../../components/reassignVehicle/Index'
import AdvancePayment from '../../components/advance_payment/Index'
import AdvancePaymentDetails from '../../components/advance_details/Index'
import { OpenToast } from '../../../../components/toast/Index'
import { FetchUserData } from '../../../../common/functions/CommonFunctions'
import TableHeader from '../../components/table_format/Index'

const TripBaseCard = (props: any) => {

    const item = props.data

    const [tripStatus, setTripStatus] = React.useState('')
    const [checkStatus, setCheckeStatus] = React.useState(false)

    const [advancePaymentModalVisible, setAdvancePaymentModalVisible] = React.useState(false);
    const [advancePaymentDetailsModalVisible, setAadancePaymentDetailsModalVisible] = React.useState(false);
    const [reAssignVehicleModalVisible, setReassignVehicleModalVisible] = React.useState(false);

    const handleCloseReassignVehicle = () => setReassignVehicleModalVisible(false)
    const handleOpenReassignVehicle = () => setReassignVehicleModalVisible(true)

    const handleCloseAdvancePayment = () => setAdvancePaymentModalVisible(false)
    const handleOpenAdvancePayment = () => setAdvancePaymentModalVisible(true)

    const handleCloseAdvancePaymentDetails = () => setAadancePaymentDetailsModalVisible(false)
    const handleOpenAdvancePaymentDetails = () => {
        setAadancePaymentDetailsModalVisible(true)
    }

    const closeModal = () => handleCloseReassignVehicle

    const closeAdvancePaymentModal = () => {
        setAdvancePaymentModalVisible(false)
        props.refresh()
    }

    return (
        <>
            <tr>
                <TripCard data={item} />
                <td>
                    <input type="button" value='Re-Assign Vehicle' className='btn btn-sm ms-1 text-black' style={{ border: `1px solid gray` }} onClick={handleOpenReassignVehicle} />

                    {item?.isPaymentRequested ? (
                        <input type="button" value='View Payment Details' className='btn btn-sm ms-1 text-black' style={{ border: `1px solid gray` }} onClick={handleOpenAdvancePaymentDetails} />
                    ) : (
                        <input type="button" value='Request Advance' className='btn btn-sm ms-1 text-black ' style={{ border: `1px solid gray` }} onClick={handleOpenAdvancePayment} />
                    )}
                </td>
            </tr>
            {checkStatus ? <OpenToast text={tripStatus} /> : <></>}
            {/* <Card className='p-2'>
                <Row>
                    <TripCard data={item} />
                </Row>
                <Row className='mt-2'>
                    <Col className='d-flex justify-content-end'>

                        <input type="button" value='Re-Assign Vehicle' className='btn btn-sm ms-1 text-black' style={{ border: `1px solid gray` }} onClick={handleOpenReassignVehicle} />

                        {item?.isPaymentRequested ? (
                            <input type="button" value='View Payment Details' className='btn btn-sm ms-1 text-black' style={{ border: `1px solid gray` }} onClick={handleOpenAdvancePaymentDetails} />
                        ) : (
                            <input type="button" value='Request Advance' className='btn btn-sm ms-1 text-black ' style={{ border: `1px solid gray` }} onClick={handleOpenAdvancePayment} />
                        )}

                    </Col>
                </Row>
            </Card> */}

            

        </>
    )
}

const ReportingPendingForTransporter = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const searchText = useAppSelector((state: RootState) => state.search.text);

    const [trips, setTrips] = React.useState<any>([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        getAllTrips()
    }, [searchText])

    const getAllTrips = async () => {
        setLoading(true)
        const path = 'Trips/GetTrips';
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "UserType": user.userType,
            "Type": 'Reporting Pending',
            "Page": 1,
            "search": searchText,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        setLoading(false)
        if (response.succeeded) {
            // var data = response.data.allTrips;
            // response.data.allTrips.map((item: any) => {
            //     var today = moment(new Date())
            //     var expectedDate = moment(item.expectedLoadingDate, 'DD MMM, YYYY')
            //     expectedDate = moment(expectedDate, 'DD MM YYYY').add(24, 'hours')
            //     item.isDelayed = today.isSameOrAfter(expectedDate)
            // })
            setTrips(response.data.allTrips);
        }
    }

    const refresh = React.useCallback(() => {
        getAllTrips()
    }, [])

    return (
        <Container fluid>
            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Row>
                    {trips.length > 0 ? (
                        <>
                            <Table>
                                <TableHeader />
                                <tbody className='contentArea'>
                                    {trips.map((item: any, index:any) => <TripCard data={item} refresh={refresh} key={index}/>)}
                                </tbody>
                            </Table>
                        </>
                    ) : <NotFound />}
                </Row>
            )}

        </Container>
    )
}

export default ReportingPendingForTransporter