import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/Index';

interface CounterState {
    userID: number,
    transporterID: number,
    transporterName: String,
    transporterPhone: String,
    transporterEmail: String,
    userName: String,
    userPhone: String,
    userEmail: String,
    userType: String,
    rating: number,
    userLabel: String,
    type: String,
}

const initialState: CounterState = {
    userID: 0,
    transporterID: 0,
    transporterName: '',
    transporterPhone: '',
    transporterEmail: '',
    userName: '',
    userPhone: '',
    userEmail: '',
    userType: '',
    rating: 0,
    userLabel: '',
    type: '',
}

const userSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        addUser: (state, action) => {
            state.userID = action.payload.userID;
            state.transporterID = action.payload.transporterID;
            state.transporterName = action.payload.transporterName
            state.transporterPhone = action.payload.transporterPhone
            state.transporterEmail = action.payload.transporterEmail
            state.userName = action.payload.userName
            state.userPhone = action.payload.userPhone
            state.userEmail = action.payload.userEmail
            state.userType = action.payload.userType
            state.rating = action.payload.rating
            state.userLabel = action.payload.userLabel
            state.type = action.payload.type
        },
        deleteUser: () => {
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('authorization');
            return initialState;
        }
    }
});

export default userSlice.reducer;
export const { addUser, deleteUser } = userSlice.actions;
