import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppContainer from './config/services/navigation/AppContainer';
import store from './store/Index';
import { Provider } from 'react-redux';
// import firebase from 'firebase/app';
// import 'firebase/messaging';
// import { getToken, getMessaging } from 'firebase/messaging';
// import { getDeviceToken } from './firebase';

const App = () => {

  return (
    <Provider store={store}>
      <AppContainer />
    </Provider>
  )
}

export default App;
