import CommonColors from "../colors/CommonColor";

export const CommonStyles = {
    sideBarContainer:{
        height: window.innerHeight,
        backgroundColor: 'green',
        width: 90
    },
    loginLogos:{
        height: 60,
        paddingRight: 10
    },
    buttonColor: {
        backgroundColor: CommonColors.PRIMARY
    },
    loader:{
        height: window.innerHeight / 2
    },
    medalStyle:{
        marginTop: 80, 
        borderRadius: 10, 
        paddingBottom: 80
    },
    circle:{
        width: 15,
        height: 15,
        borderRadius: 30,
        backgroundColor: CommonColors.PRIMARY
    },
    font_14:{
        fontSize: 14
    }
}