import React from 'react'
import { Card, Col, Row, Form } from 'react-bootstrap'
import Style from './Style'
import TextView from '../text_view/Index'
import CommonColors from '../../../../common/colors/CommonColor'
import { useSelector } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import { RootState } from '../../../../store/Index'
import { post } from '../../../../config/services/api/ApiServices'
import { FaArrowRight } from 'react-icons/fa'

const AdvancePayment = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);

    const tripDetails: any = props.tripDetails

    const [paymentType, setPaymentType] = React.useState('CASH')
    const [isPaymentRequired, setIsPaymentRequired] = React.useState(false)

    const [transporterAmount, setTransporterAmount] = React.useState('')
    const [driverAmount, setDriverAmount] = React.useState('')
    const [advanceType, setAdvanceType] = React.useState<any>('amount');
    const threesoldAmount = (parseInt(tripDetails?.committedPrice) * 0.7).toFixed(2)

    const [showAlert, setShowAlert] = React.useState(false)

    const [disableBtn, setDisableBtn] = React.useState(false)

    const validateAmount = () => {
        if (advanceType == 'amount') {
            if (transporterAmount == '' || parseFloat(transporterAmount) > parseFloat(threesoldAmount)) {
                setShowAlert(true)
            } else {
                isChecked()
            }
        } else {
            if (transporterAmount == '' || parseFloat(transporterAmount) > 70) {
                setShowAlert(true)
            } else {
                isChecked()
            }
        }

        setTimeout(() => {
            setShowAlert(false)
        }, 3000);
    }

    const isChecked = () => {
        if (isPaymentRequired) {
            if (driverAmount == '' || parseFloat(driverAmount) > parseFloat(threesoldAmount)) {
                setShowAlert(true)
            } else {
                confirmPayAdvance()
            }
        } else {
            confirmPayAdvance()
        }
    }

    const confirmPayAdvance = async () => {
        setDisableBtn(true)
        const path = 'Payments/RequestAdvancePayment';
        const body = {
            "tripId": tripDetails.id,
            "userID": user.userID,
            "transporterID": user.transporterID,
            "typeOfPaymentTransporter": advanceType,   //amount or percentage
            "modeOfpaymentTransporter": paymentType,    // cash any other
            "transporterAmount": transporterAmount,
            "driverAmount": driverAmount,
            "isDriverPaymentRequested": isPaymentRequired
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }

        var response = await post(path, body, header);
        setDisableBtn(false)
        if (response.succeeded) {
            window.alert(response.message)
            props.closeModal()
        } else {
            window.alert(response.messasge || 'Something went wrong')
        }
    }

    return (
        <Row>
            <Col>

                {showAlert ? (
                    <Row>
                        <Col>
                            <div className="alert alert-danger">The Advance amount requested should be maximum 70% of the total trip.</div>
                        </Col>
                    </Row>
                ) : (<></>)}

                <Row className='my-3'>
                    <Col md={2}>
                        <h4 className='text-success'>Rs. {tripDetails.committedPrice} /-</h4>
                        <h6>Total Trip Changes</h6>
                    </Col>
                    <Col className='d-flex align-items-center'>
                        <div className='me-5'>
                            <h5 className='p-0 m-0'>{tripDetails.loadingPoint}</h5>
                            <small>{tripDetails.expectedLoadingDate}</small>
                        </div>
                        <div className='me-5 text-center d-flex align-items-center'>
                            <FaArrowRight size={18} color={CommonColors.PRIMARY} />
                        </div>
                        <div className='me-5 text-center d-flex align-items-center'>
                            <div>
                                <h5 className='m-0 p-0'>{tripDetails.unloadingPointCount}</h5>
                            </div>
                        </div>
                        <div className='me-5 text-center d-flex align-items-center'>
                            <FaArrowRight size={18} color={CommonColors.PRIMARY} />
                        </div>
                        <div className='me-5'>
                            <h5 className='p-0 m-0'>{tripDetails.unloadingArea}</h5>
                            <small>{tripDetails.committedDeliveryDate}</small>
                        </div>
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Row className='mb-3'>
                        <Col md={4} className='mb-3'>
                            <h6>Mode Of Payment</h6>
                            <Col>
                                <select name="paymentType" id="paymentType" disabled className='form-control'>
                                    <option value={paymentType}>CASH</option>
                                </select>
                            </Col>
                        </Col>
                        <Col md={4} className='mb-3'>
                            <h6>Type of Advance</h6>
                            <Col>
                                <select name="" id="" className='form-control' value={advanceType} onChange={(e: any) => setAdvanceType(e.target.value)}>
                                    <option value='amount'>Amount</option>
                                    <option value='percentage'>Percentage %</option>
                                </select>
                            </Col>
                        </Col>
                        <Col md={4} className='mb-3'>
                            <h6>Transporter's Amount ( max : {advanceType === 'amount' ? threesoldAmount : '70%'} )</h6>
                            <Col>
                                <Form.Control type="number" size='sm' placeholder="transporter's amount" maxLength={6} value={transporterAmount} onChange={(e: any) => {
                                    setDriverAmount('')
                                    setTransporterAmount(e.target.value)
                                }} />
                            </Col>
                        </Col>
                        <Col md={4} className=' d-flex mb-3' >
                            <Form.Check
                                inline
                                label="Driver Advance Required?"
                                name="needAdvance"
                                checked={isPaymentRequired}
                                onChange={() => setIsPaymentRequired(!isPaymentRequired)}
                            />
                        </Col>

                        {isPaymentRequired ? (
                            <Col md={4} className='mb-3'>   
                                <h6>Driver's Amount</h6>
                                <Col>
                                    <Form.Control size='sm' type="number" placeholder="driver's amount" maxLength={6} value={driverAmount} onChange={(e: any) => setDriverAmount(e.target.value)} />
                                </Col>
                            </Col>
                        ) : (<></>)}
                    </Row>



                    <Row>
                        <Col className='text-end'>
                            <input type="button" value='Submit' className='btn text-white' style={{ backgroundColor: disableBtn ? 'gray' : CommonColors.PRIMARY, cursor: 'pointer' }} onClick={validateAmount} disabled={disableBtn} />
                        </Col>
                    </Row>
                </Row>
            </Col>
        </Row>
    )
}

export default AdvancePayment