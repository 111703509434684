export const loadingDocuments = [
    {
        "label": "Available",
        "value": "Available"
    },
    {
        "label": "Not Available",
        "value": "Not Available",
    }
]

export const RegistrationCertificate = [
    {
        "label": "Available",
        "value": "Available",
    },
    {
        "label": "Not Available",
        "value": "Not Available" 
    },
    {
        "label": "Validity Expired",
        "value": "Validity Expired"
    },
    {
        "label": "Vehicle No. Mismatch",
        "value": "Vehicle No. Mismatch"
    },
]

export const InsuranceCertificate = [
    {
        "label": "Available",
        "value": "Available",
        
        
    },
    {
        "label": "Not Available",
        "value": "Not Available",
        
        
    },
    {
        "label": "Validity Expired",
        "value": "Validity Expired",
        
        
    },
    {
        "label": "Vehicle/Engine/Chassis No. Mismatch",
        "value": "Vehicle/Engine/Chassis No. Mismatch",
        
        
    },
]

export const FitnessCertificate = [
    {
        "label": "Available",
        "value": "Available",
        
        
    },
    {
        "label": "Not Available",
        "value": "Not Available",
        
        
    },
    {
        "label": "Validity Expired",
        "value": "Validity Expired",
        
        
    },
    {
        "label": "Vehicle No. Mismatch",
        "value": "Vehicle No. Mismatch",
        
        
    },
]

export const RoadTax = [
    {
        "label": "Available",
        "value": "Available",
        
        
    },
    {
        "label": "Not Available",
        "value": "Not Available",
        
        
    },
    {
        "label": "Validity Expired",
        "value": "Validity Expired",
        
        
    },
    {
        "label": "Vehicle No. Mismatch",
        "value": "Vehicle No. Mismatch",
        
        
    },
]

export const Puc = [
    {
        "label": "Available",
        "value": "Available",
        
        
    },
    {
        "label": "Not Available",
        "value": "Not Available",
        
        
    },
    {
        "label": "Validity Expired",
        "value": "Validity Expired",
        
        
    },
    {
        "label": "Vehicle No. Mismatch",
        "value": "Vehicle No. Mismatch",
        
        
    },
]

export const Permit = [
    {
        "label": "Available",
        "value": "Available",
        
        
    },
    {
        "label": "Not Available",
        "value": "Not Available",
        
        
    },
    {
        "label": "Validity Expired",
        "value": "Validity Expired",
        
        
    },
    {
        "label": "Vehicle No. Mismatch",
        "value": "Vehicle No. Mismatch",
        
        
    },
]

export const DrivingLicense = [
    {
        "label": "Available",
        "value": "Available"
    },
    {
        "label": "Not Available",
        "value": "Not Available"
    },
    {
        "label": "Validity Expired",
        "value": "Validity Expired"
    },
    {
        "label": "Non-Competent Licenence",
        "value": "Non-Competent Licenence"
    },
    {
        "label": "Photo Not Clear",
        "value": "Photo Not Clear"
    },
    {
        "label": "Fake License",
        "value": "Photo Not Clear"
    },
]

export const WindScreen = [
    {
        "label": "Condition Ok",
        "value": "Condition Ok",
        
        
    },
    {
        "label": "Broken",
        "value": "Broken",
        
        
    },
]

export const BreathAlcoholic = [
    {
        "label": "Condition Ok",
        "value": "Condition Ok",
        
        
    },
    {
        "label": "Condition Not Ok",
        "value": "Condition Not Ok",
        
        
    },
]

export const EngineSpillage = [
    {
        "label": "Condition Ok",
        "value": "Condition Ok",
        
        
    },
    {
        "label": "Spillage Found",
        "value": "Spillage Found",
        
        
    },
]

export const NumberPlate = [
    {
        "label": "Available",
        "value": "Available",
        
        
    },
    {
        "label": "Not Available",
        "value": "Not Available",
        
        
    },
    {
        "label": "Not Printed",
        "value": "Not Printed",
        
        
    },
    {
        "label": "Non Visible/Partiality Visible",
        "value": "Non Visible/Partiality Visible",
        
        
    },
]

export const BodyCondition = [
    {
        "label": "Available",
        "value": "Available",
 
    },
    {
        "label": "Floor Damaged",
        "value": "Floor Damaged",
        
        
    },
    {
        "label": "Floor Contaminated",
        "value": "Floor Contaminated",
        
        
    },
    {
        "label": "Not Approximate Size for Loading",
        "value": "Not Approximate Size for Loading",
        
        
    },
]

export const Stepney = [
    {
        "label": "Available",
        "value": "Available",
        
        
    },
    {
        "label": "Floor Damaged",
        "value": "Floor Damaged",
        
        
    }
]

export const Covering = [
    {
        "label": "Condition Ok",
        "value": "Condition Ok",
        
        
    },
    {
        "label": "Condition Not Ok",
        "value": "Condition Not Ok",
        
        
    },
    {
        "label": "Partiality Ok",
        "value": "Partiality Ok",
        
        
    }
]

export const ForFloor = [
    {
        "label": "Condition Ok",
        "value": "Condition Ok",
        
        
    },
    {
        "label": "Condition Not Ok",
        "value": "Condition Not Ok",
        
        
    },
    {
        "label": "Partiality Ok",
        "value": "Partiality Ok",
        
        
    }
]

export const DispatchDocument = [
    {
        "label": "Available",
        "value": "Available",
        
        
    },
    {
        "label": "Not Available",
        "value": "Not Available",
        
        
    },
]

export const TrampaulinCovered = [
    {
        "label": "Yes",
        "value": "Yes",
        
        
    },
    {
        "label": "No",
        "value": "No",
        
        
    },
]

export const VehicleFirsking = [
    {
        "label": "Available",
        "value": "Available",
        
        
    },
    {
        "label": "Not Available",
        "value": "Not Available",
        
        
    }
    // {
    //  
    //     "label": "N/A",
    //     "value": "N/A",
    //     
    //     
    // },
]

export const Inflamable = [
    {
        "label": "Yes",
        "value": "Yes",
        
        
    },
    {
        "label": "No",
        "value": "No",
        
        
    },
    {
        "label": "Deposited and Allowed",
        "value": "Deposited and Allowed",
        
        
    },
]

