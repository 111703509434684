import React, { useEffect } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { Header } from "../../components/header/Index";
import SideBarMenu from "../../components/sideBarMenu/Index";
import Upcoming from "./upcoming/Index";
import Ongoing from "./ongoing/Index";
import Closed from "./closed/Index";
import { FetchUserData, getMenu } from "../../common/functions/CommonFunctions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { postSocketApi } from "../../config/services/api/ApiServices";
import { RootState } from "../../store/Index";
import { setSocket, setSocketAuth } from "../../store/slices/socketSlice";
import { SocketUrl } from "../../config/constants/Constants";
import { io } from "socket.io-client";

const Bidding = (props: any) => {

    const dispatch = useAppDispatch();
    const user = useAppSelector((state: any) => state.user);
    FetchUserData(dispatch)

    async function initializeSocketIO() {
        const socket = await io(SocketUrl);
        if (socket) {
            dispatch(setSocket(socket));
            socket.emit("join", { "name": user.userName, "id": user.userID, "TransporterID": user.transporterID });
            return () => {
                socket.off('join')
            }
        }
    }

    useEffect(() => {
        initializeSocketIO()
    }, [])

    const topTabs: any = ({
        'Upcoming': <Upcoming />,
        'Ongoing': <Ongoing />,
        'Closed': <Closed />,
        // 'Archieve': <Archieve />,
    })

    const [selectedTab, setSelectedTab] = React.useState('Upcoming')
    const [selectedComponent, setSelectedComponent] = React.useState<any>(<Upcoming />)

    const changeTab = (items: any) => {
        setSelectedTab(items)
        setSelectedComponent(topTabs[items])
    }

    return (
        <Container fluid style={{ width: '100%', height: '100%' }}>

            <Header breadCrumb='Bidding' />
            <Row>
                <SideBarMenu selectedPath='Bidding' />
                <Col className="px-3" style={{ backgroundColor: '#f5f5f5', width: (window.innerWidth - 110) }}>
                    <Row className="p-2">
                        <Card className="border-0">
                            <Card.Body>
                                <Row>
                                    <Col className="d-flex p-0 m-0">
                                        {Object.keys(topTabs).map((items: any) => {
                                            return (
                                                <p className="me-2 px-3 py-1 text-center" style={{ backgroundColor: items == selectedTab ? 'green' : '#ebebeb', color: items == selectedTab ? 'white' : 'black', cursor: 'pointer', borderRadius: 4 }} onClick={() => changeTab(items)} key={items}>{items}</p>
                                            )
                                        })}
                                    </Col>
                                </Row>
                                {selectedComponent}
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Bidding