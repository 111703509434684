import { createSlice } from "@reduxjs/toolkit";

interface CounterState {
    text: String,
}

const initialState: CounterState = {
   text : '',
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setText: (state, action) => {
            state.text = action.payload;
        }
    }
});

export default searchSlice.reducer;
export const {setText} = searchSlice.actions;
