import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import Style from './Style'
import { FaCopy, FaTruck } from 'react-icons/fa'
import TripDetails from '../trip_detail_modal/Index'
import ViewPOD from '../POD/view/Index'
import UploadPOD from '../POD/upload/Index'
import CommonColors from '../../../../common/colors/CommonColor'
import { OpenToast } from '../../../../components/toast/Index'
import { CommonStyles } from '../../../../common/styles/CommonStyles'
import { useDispatch, useSelector } from 'react-redux'
import ReassignVehicle from '../reassignVehicle/Index'
import AdvancePayment from '../advance_payment/Index'
import AdvancePaymentDetails from '../advance_details/Index'
import { setCardDetails } from '../../../../store/slices/tripCardDetails'

const TripCard = (props: any) => {

    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.user);

    const tripsData = props.data
    const tabName = props?.tabName || ''
    const [showDetailModal, setShowDetailModal] = React.useState(false)
    const [isViewPodModalVisible, setIsViewModalVisible] = React.useState(false)
    const [shippingId, setShippingId] = React.useState('')
    const [tripId, setTripId] = React.useState('')
    const [checkStatus, setCheckeStatus] = React.useState(false)
    const [podDetails, setPodDetails] = React.useState<any>([])
    const [tripDetails, setTripDetails] = React.useState<any>(null)
    const [uploadPodModalVisible, setUploadPodModalVisible] = React.useState(false)
    const [copied, setCopied] = React.useState(false)
    const [tripStatus, setTripStatus] = React.useState('')

    const handleCloseUploadPOD = () => setUploadPodModalVisible(false)
    const handleOpenUploadPOD = () => setUploadPodModalVisible(true)

    const handleCloseViewPOD = () => setIsViewModalVisible(false)

    const handleOpenViewPOD = () => {
        setIsViewModalVisible(true)
    }

    const handleClose = () => setShowDetailModal(false)
    const handleOpenDetails = () => {
        dispatch(setCardDetails(props.data))
        setShowDetailModal(true)
    }

    // modal 
    const [advancePaymentModalVisible, setAdvancePaymentModalVisible] = React.useState(false);
    const [advancePaymentDetailsModalVisible, setAadancePaymentDetailsModalVisible] = React.useState(false);
    const [reAssignVehicleModalVisible, setReassignVehicleModalVisible] = React.useState(false);

    const handleCloseReassignVehicle = () => setReassignVehicleModalVisible(false)
    const handleOpenReassignVehicle = () => setReassignVehicleModalVisible(true)

    const handleCloseAdvancePayment = () => setAdvancePaymentModalVisible(false)
    const handleOpenAdvancePayment = () => setAdvancePaymentModalVisible(true)

    const handleCloseAdvancePaymentDetails = () => setAadancePaymentDetailsModalVisible(false)
    const handleOpenAdvancePaymentDetails = () => setAadancePaymentDetailsModalVisible(true)

    const closeModal = React.useCallback(() => {
        props.refresh()
    }, [])

    const closeDetailModal = React.useCallback((openBlockName:any, data:any, tripDetails = {}) => {

        if(data.tabStatus == 'Reporting Pending' && user.userType == 'Transporter'){
            setTripDetails(data)
            if(openBlockName == 'reassign'){
                handleOpenReassignVehicle()
            }else if(openBlockName == 'advancePayment'){
                handleOpenAdvancePayment()
            }else if(openBlockName == 'advancePaymentDetails'){
                handleOpenAdvancePaymentDetails()
            }
        }else if(openBlockName == true){
            setPodDetails(data)
            handleOpenUploadPOD()
        }else if(openBlockName == false){
            setShippingId(data.shippingId)
            setTripId(data.tripId)
            handleOpenViewPOD()
        }
        handleClose()
    }, [])

    const closeUploadModal = React.useCallback(() => {
        handleCloseUploadPOD()
    }, [])

    const handleOpenStatus = (status: any) => {
        props.openStatusModal(status)
    }

    const copyToClipboard = (text: any) => {
        navigator.clipboard.writeText(text)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }

    return (
        <>
            <tr onClick={handleOpenDetails} className='hoverTr' style={{cursor: 'pointer'}}>
                {checkStatus ? <OpenToast text={tripStatus} /> : <></>}

                <td>
                    <small style={{ cursor: 'pointer' }} className='text-primary'>{tripsData?.shipmentNumber || '-'}</small>
                </td>
                <td>
                    <small>{tripsData?.vehicleNumber || '-'}</small>
                </td>
                <td>
                    <small>{tripsData?.tripsDate || '-'}</small>
                </td>
                <td>
                    <small>{tripsData?.unloadingPointCount < 10 ? '0' + tripsData?.unloadingPointCount : tripsData?.unloadingPointCount}</small>
                </td>
                <td>
                    <small>{tripsData?.unloadingArea || '-'}</small>
                </td>
                <td>
                    <small>{tripsData?.materialType} ( {tripsData?.totalQuantity} )</small>
                </td>
                <td>
                    <small>{tripsData?.committedDeliveryDate || '-'}</small>
                </td>
                <td>
                    <small style={{ backgroundColor: tripsData?.color || '' }} className='p-1 px-2 rounded text-white'>{tripsData?.status || '-'}</small>
                </td>
            </tr>

            <Modal show={showDetailModal} onHide={handleClose} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton className='p-1 px-3'>
                    <h5>Trip Details ( {tripsData.status} )</h5>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    <TripDetails data={tripsData} tabName={tabName} closeModal={closeDetailModal} />
                </Modal.Body>
            </Modal>

            <Modal show={isViewPodModalVisible} onHide={handleCloseViewPOD} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton className='px-2 p-1'>
                    <Modal.Title>View POD Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    <ViewPOD shippingId={shippingId} tripId={tripId} />
                </Modal.Body>
            </Modal>

            <Modal show={uploadPodModalVisible} onHide={handleCloseUploadPOD} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton className='px-2 p-1'>
                    <Modal.Title>Upload POD</Modal.Title>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    <UploadPOD tripDetails={tripDetails} data={tripsData} extraData={podDetails} closeUploadModal={closeUploadModal} />
                </Modal.Body>
            </Modal>

            <Modal show={reAssignVehicleModalVisible} onHide={handleCloseReassignVehicle} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton className='px-2 p-1'>
                    <Modal.Title>Re-Assign Vehicle</Modal.Title>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    <ReassignVehicle assignDetails={tripDetails} flag='reassign' closeModal={closeModal} />
                </Modal.Body>
            </Modal>

            <Modal show={advancePaymentModalVisible} onHide={handleCloseAdvancePayment} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton className='px-2 p-1'>
                    <Modal.Title>Request Advance Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    <AdvancePayment tripDetails={tripDetails} closeModal={closeModal} />
                </Modal.Body>
            </Modal>

            <Modal show={advancePaymentDetailsModalVisible} onHide={handleCloseAdvancePaymentDetails} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton className='px-2 p-1'>
                    <Modal.Title>Payment Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    <AdvancePaymentDetails tripId={tripDetails?.id} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TripCard