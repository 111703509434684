import React from 'react'
import { Col, Row, } from 'react-bootstrap'
import PodPending from './podPending';
import PodCompleted from './podComplete';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { applyFilter, setFromDate, setToDate } from '../../../../store/slices/applyFilter';
import { RootState } from '../../../../store/Index';

const InTransitForTransporter = (props: any) => {

    const dispatch = useAppDispatch()

    const apply:any = useAppSelector((state: RootState) => state.apply);

    const [tripTabs, settripsTab] = React.useState<any>({
        'POD Pending': <PodPending />,
        'POD Completed': <PodCompleted />
    })

    const [selectedTab, setSelectedTab] = React.useState('POD Pending')
    const [selectedComponent, setSelectedComponent] = React.useState<any>(<PodPending />)

    const changeTab = (items: any) => {
        setSelectedTab(items)
        setSelectedComponent(tripTabs[items])
        dispatch(applyFilter(!apply.apply))
    }

    const applyChange = () => {
        dispatch(applyFilter(!apply.apply))
    }

    return (
        <>
            <Row className="p-0 m-0 ">
                <hr className="p-0" />
                <Col className="d-flex p-0 m-0">
                    {Object.keys(tripTabs).map((items: any) => {
                        return (
                            <p className="me-2 px-3 py-1 text-center" style={{ backgroundColor: items == selectedTab ? 'green' : '#ebebeb', color: items == selectedTab ? 'white' : 'black', cursor: 'pointer', borderRadius: 4 }} onClick={() => changeTab(items)} key={items}>{items}</p>
                        )
                    })}
                </Col>
                <Col>
                    <div className="row p-0 m-0">
                        <div className="col-2 p-0 m-0">
                            <p className='text-center'>From date</p>
                        </div>
                        <div className="col-3 p-0 m-0">
                            <input
                                type="date"
                                name="fromDate"
                                value={apply.fromDate}
                                min={"1970-01-01"}
                                max={moment().subtract(7, 'd').format('YYYY-MM-DD')}
                                onChange={(event: any) => {
                                    dispatch(setFromDate(event.target.value))
                                    dispatch(setToDate(moment(event.target.value).add(7, 'd').format('YYYY-MM-DD')))
                                }}
                                id="fromDate"
                                className='form-control'
                                style={{ height: '35px' }}
                            />
                        </div>
                        <div className="col-2 p-0 m-0">
                            <p className='text-center'>To date</p>
                        </div>
                        <div className="col-3 p-0 m-0">
                            <input
                                type="date"
                                name="toDate"
                                value={apply.toDate}
                                min={apply.fromDate}
                                max={moment(apply.fromDate).add(7, 'd').format('YYYY-MM-DD')}
                                onChange={(event: any) => {
                                    dispatch(setToDate(event.target.value))
                                }}
                                id="toDate"
                                className='form-control'
                                style={{ height: '35px' }}
                            />
                        </div>
                        <div className="col-2">
                            <input
                                type="button"
                                value="Apply"
                                className='form-control btn btn-sm btn-success'
                                onClick={applyChange}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="p-0 m-0">
                <Col className="p-0 m-0">
                    {selectedComponent}
                </Col>
            </Row>
        </>
    )
}

export default InTransitForTransporter