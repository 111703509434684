import { Container } from "react-bootstrap"
import CommonColors from "../../common/colors/CommonColor"
import { useNavigate } from "react-router-dom";

export const NoPageFound = () => {

    const navigate = useNavigate();

    return (
        <Container fluid className="d-flex justify-content-center align-items-center min-vh-100 p-0 m-0">
            <div className="text-center" style={{fontFamily: 'sans-serif'}}>
                <h1 style={{ fontSize: 200, fontWeight: 'bold'}}>404</h1>
                <h4 style={{fontWeight: 'bold'}}>404 - PAGE NOT FOUND</h4>
                <p>The page you are looking for might have been removed.</p>
                <input type="button" value="GO BACK" className="rounded p-2 text-white px-4" style={{outline: 'none', border: 0, backgroundColor: CommonColors.PRIMARY}} onClick={() => navigate(-1)}/>
            </div>
        </Container>
    )
} 