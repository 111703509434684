import React from "react";
import { Row, Table } from "react-bootstrap";
import BidCard from "../components/bidcard/BidCard";
import { get, getSocketApi } from "../../../config/services/api/ApiServices";
import NotFound from "../../notfound/Indesx";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/Index";
import Loader from "../../trips/components/loader/Index";
import { FetchUserData, getMenu } from "../../../common/functions/CommonFunctions";
import TableHeader from "../components/Tableheader";
import { setSocketAuth } from "../../../store/slices/socketSlice";

const Upcoming = (props: any) => {

    const dispatch = useAppDispatch();
    FetchUserData(dispatch)

    const user = useAppSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const socketAuth = useAppSelector((state: RootState) => state.socket.socketAuth);

    const [upcomingBids, setUpcomingBids] = React.useState([])

    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        const getMenuCount = async () => {
            await getMenu(user, auth, dispatch);
        }
        getUpcomingBids()
        getMenuCount()
    }, [])

    const getUpcomingBids = async () => {
        setLoading(true)
        const path = 'auction'
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "Type": "upcoming",
            "Page": 1,
            "Search": '',
            "Sort": "",
        }

        let auth: any = localStorage.getItem('socketAuth')
        let headerAuth = socketAuth
        if (auth) {
            dispatch(setSocketAuth(JSON.parse(auth)))
            headerAuth = JSON.parse(auth)
        }

        const response = await getSocketApi(path, body, headerAuth)
        setLoading(false)
        if (response.status == 200) {
            setUpcomingBids(response.data)
        }
    }

    return (
        <Row className="contentArea p-0 m-0 ">
            {loading ? <Loader /> : (
                <>
                    {upcomingBids.length > 0 ? (
                        <>
                            <Table className="table">
                                <TableHeader />
                                <tbody>
                                    {upcomingBids.map((bids: any) => <BidCard data={bids} key={bids.id} tabName='upcoming' />)}
                                </tbody>
                            </Table >
                        </>
                    ) : <NotFound />}
                </>)
            }
        </Row>
    )
}

export default Upcoming