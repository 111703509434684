import React from "react";
import { Card, Col, Container, Modal, Table, Row } from "react-bootstrap";
import SideBarMenu from "../../components/sideBarMenu/Index";
import { Header } from "../../components/header/Index";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Index";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import moment from "moment";
import { get } from "../../config/services/api/ApiServices";
import { FaArrowRight, FaTruck, FaTruckLoading, FaTruckMonster, FaTruckPickup } from "react-icons/fa";
import CommonColors from "../../common/colors/CommonColor";
import NotFound from "../notfound/Indesx";
import ReassignVehicle from "../trips/components/reassignVehicle/Index";
import { FetchUserData } from "../../common/functions/CommonFunctions";
import Style from "../trips/components/trip_card/Style";
import InputView from "../trips/components/input_disable_view/Index";
import { CommonStyles } from "../../common/styles/CommonStyles";
import Loader from "../trips/components/loader/Index";

const Assign = (props: any) => {

    const dispatch = useAppDispatch();
    FetchUserData(dispatch)

    const [assigns, setAssigns] = React.useState([])
    const [assignDetails, setAssignDetails] = React.useState<any>({});

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const [disable, setDisable] = React.useState(false)

    const [isModalVisible, setIsModalVisible] = React.useState(false)
    const [isAssignModalVisible, setIsAssignModalVisible] = React.useState(false)

    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        const getData = async () => await getAssignes();
        getData()
    }, [])

    const getAssignes = async () => {
        setLoading(true)
        const path = 'Trips/GetAssign';
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "UserType": user.userType,
            "Page": 1
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        if (response.succeeded) {
            var response = response.data

            response.length > 0 && response.map((item: any) => {
                var today = moment(new Date())
                var difference = today.diff(item.item.loadingDateVal, 'hours')
                item.item.isDelayed = difference >= 24 ? true : false
            })

            setAssigns(response)
        };
        setLoading(false)
    }

    const showAssignModal = () => {

        setIsModalVisible(false)
        setIsAssignModalVisible(true)
    }

    const hideModal = () => setIsModalVisible(false)
    const hideAssignModal = () => setIsAssignModalVisible(false)

    const closeModal = React.useCallback((data: any) => {
        hideAssignModal()
        getAssignes()
    }, [])

    return (
        <Container fluid style={{ width: '100%', height: '100%' }}>

            <Header breadCrumb='Assigns' />
            <Row>
                <SideBarMenu selectedPath='Assigns' />

                <Col className="px-3 py-2 contentArea" style={{ backgroundColor: '#f5f5f5' }}>
                    <Row className="px-2">
                        <Card className="border-0" style={{ height: (window.innerHeight - 120) }}>
                            {loading ? <Loader /> : (
                                <Card.Body className="p-1">
                                    {assigns.length > 0 ? (
                                        <>
                                            <div className="p-2">
                                                <FaTruck color="red" size={16} />
                                                <small className="mx-2">Delay to assign vehicle</small>
                                            </div>
                                            <Table className="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Contract/Bid Number</th>
                                                        <th>Loading point</th>
                                                        <th>Unloading points</th>
                                                        <th>Unloading Area</th>
                                                        <th>Material Type (Quantity)</th>
                                                        <th>Delivery Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {assigns.map((items: any) => {
                                                        return (
                                                            <tr className="hoverTr" style={{ cursor: 'pointer' }} key={items.item.id}>
                                                                <td>{items.item.isDelayed ? <FaTruck color="red" /> : ''}</td>
                                                                <td onClick={() => {
                                                                    setAssignDetails(items)
                                                                    setIsModalVisible(true)
                                                                }} style={{ cursor: 'pointer' }} className="text-primary">
                                                                    <small>{items.item.biddingNumber}</small>
                                                                </td>
                                                                <td>
                                                                    <small>{items.item.loadingPoint}</small>
                                                                </td>
                                                                <td>
                                                                    <small>{items.item.unloadingPointCount}</small>
                                                                </td>
                                                                <td>
                                                                    <small>{items.item.unloadingArea}</small>
                                                                </td>
                                                                <td>
                                                                    <small>{items.item.materialType} ({items.item.totalQuantity})</small>
                                                                </td>
                                                                <td>
                                                                    <small>{items.item.deliveryDate}</small>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table >
                                        </>
                                    ) : <NotFound />}
                                </Card.Body>
                            )}
                        </Card>
                    </Row>
                </Col>
            </Row>

            <Modal show={isModalVisible} onHide={hideModal} animation={true} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Details </Modal.Title>
                </Modal.Header>
                <Modal.Body className="contentArea">
                    <Row>
                        <InputView label={assignDetails?.auctionType == "Bidding" ? 'Bidding Number' : 'Contract Number'} value={assignDetails?.item?.biddingNumber} />
                        <InputView label={'Bid rate'} value={'Rs. ' + assignDetails?.item?.bidRate} />
                        <InputView label={'Material type (Quantity)'} value={assignDetails?.item?.materialType + ` (${assignDetails?.item?.totalQuantity})`} />
                        <InputView label={'Vehicle type'} value={assignDetails?.item?.vehicleType} />
                        <InputView label={'Loading point'} value={assignDetails?.item?.loadingPoint} />
                        <InputView label={'Loading Date'} value={assignDetails?.item?.loadingDate} />
                        <InputView label={'Vehicle Type'} value={assignDetails?.item?.vehicleType} />
                        <InputView label={'Vehicle Capacity'} value={assignDetails?.item?.vehicleSize} />
                        <InputView label={'No of delivery stations'} value={assignDetails?.item?.unloadingPointCount} />
                        <InputView label={'Expected delivery date'} value={moment(assignDetails?.item?.deliveryDate).format('DD MMM, YYYY')} />

                        <Row>
                            <Col>
                                <strong>Note</strong>
                                <p className="p-0 m-0">{assignDetails?.item?.notes || '-'}</p>
                            </Col>
                        </Row>
                    </Row>

                    <Row className="mt-2"><strong>Delivery Stations ( {assignDetails?.unloadingData?.length} )</strong></Row>

                    <Row>
                        <Col>
                            {assignDetails?.unloadingData?.map((items: any) => (
                                <Card className='p-2 mb-1 mt-2'>
                                    <Row className='p-0 m-0'>
                                        <Col><h6>Delivery Station</h6></Col>
                                        <Col><h6>Unloading Point</h6></Col>
                                        <Col><h6>Material type</h6></Col>
                                        <Col><h6>Quantity</h6></Col>
                                    </Row>

                                    <hr className='p-0 m-1' />

                                    <Row className='p-0 m-0 mb-1'>
                                        <Col className='d-flex align-items-center'><p className='p-0 m-0 me-2' style={CommonStyles.circle}></p>{items.location}</Col>
                                        <Col className='d-flex align-items-center'>{items.deliveryPointCount}</Col>
                                        <Col>{items.materialType}</Col>
                                        <Col>{items.quantity} MT</Col>
                                    </Row>
                                </Card>
                            ))}
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <input type="button" value="Assign vehicle" className="btn text-white" style={{ backgroundColor: disable ? 'gray' : CommonColors.PRIMARY }} onClick={showAssignModal} disabled={disable} />
                </Modal.Footer>
            </Modal >

            <Modal show={isAssignModalVisible} onHide={hideAssignModal} animation={true} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Vehicle </Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-0 contentArea">
                    <ReassignVehicle assignDetails={assignDetails} flag='assign' closeModal={closeModal} />
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default Assign