import { Spinner } from "react-bootstrap"
import { CommonStyles } from "../../../../common/styles/CommonStyles"

const Loader = () => {
    return (
        <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
            <Spinner animation="border" role="status" >
            </Spinner>
        </div>
    )
}

export default Loader