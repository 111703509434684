import { addUser } from '../../store/slices/user';
import { setAssignCount, setBiddingsCount, setContractsCount, setHomeCount, setTripsCount } from '../../store/slices/menuCount';
import { get } from '../../config/services/api/ApiServices';
import { useAppDispatch } from '../../store/hooks';
import { setAuth } from '../../store/slices/authentication';
import { useNavigate } from 'react-router-dom';

export async function getMenu(user: any, auth: any, dispatch: any) {
  // console.log(user);
  const path = 'Account/getMenuNew';
  const body = {
    "userType": user.userType,
    "userId": user.userID,
  }
  const header = {
    "Content-Type": 'application/json',
    // "UserType": user.userType,
    "Authorization": auth.jwToken
  }
  var response = await get(path, body, header);
  // console.log(JSON.stringify(response));
  if (response.succeeded) {
    response.data.footerMenu.map((item: any) => {
      switch (item.menuName) {
        case 'Home':
          dispatch(setHomeCount(item.count));
          break;
        case 'Biddings':
          dispatch(setBiddingsCount(item.count));
          break;
        case 'Contracts':
          dispatch(setContractsCount(item.count));
          break;
        case 'Assign':
          dispatch(setAssignCount(item.count));
          break;
        case 'Trips':
          dispatch(setTripsCount(item.count));
          break;
        default:
          break;
      }
    });

  }
}


export function checkRefresh() {
  let data = window.performance.getEntriesByType("navigation")[0];
}

export const FetchUserData = (dispatch:any) => {
  var userDetail: any = sessionStorage.getItem('user');
  var obj = sessionStorage.getItem("authorization");

  if (userDetail != null && obj != null) {
    dispatch(addUser(JSON.parse(userDetail)));
    dispatch(setAuth(JSON.parse(obj)));
  }
}
