import { Card, Col, Container, Row, Table } from "react-bootstrap"
import { Header } from "../../components/header/Index"
import SideBarMenu from "../../components/sideBarMenu/Index"
import Loader from "../trips/components/loader/Index"
import React from "react"
import { useAppSelector } from "../../store/hooks"
import { useNavigate } from "react-router-dom"
import { RootState } from "../../store/Index"
import NotFound from "../notfound/Indesx"
import { apiLink, get } from "../../config/services/api/ApiServices"
import moment from "moment"
import { useDispatch } from "react-redux"
import { addUser } from "../../store/slices/user"
import { setAuth } from "../../store/slices/authentication"
import { getMenu } from "../../common/functions/CommonFunctions"
import * as XLSX from 'xlsx';

const AccountStatement = (props: any) => {

    const Days = 90

    const dispatch = useDispatch()

    const navigate = useNavigate()
    const searchText = useAppSelector((state: RootState) => state.search.text);

    React.useEffect(() => {
        let data: any = sessionStorage.getItem('user')
        let authData: any = sessionStorage.getItem('user')
        const userDetails = JSON.parse(data)
        const authDetails = JSON.parse(authData)

        dispatch(setAuth(authDetails));
        dispatch(addUser(userDetails));

        const getData = async () => {
            await getMenu(userDetails, authDetails, dispatch)
        }
        getData()
    }, [])

    const [statement, setStatement] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(false)

    const [fromDate, setFromDate] = React.useState(moment().subtract(Days, 'd').format('YYYY-MM-DD'))
    const [toDate, setToDate] = React.useState(moment().format('YYYY-MM-DD'))

    const [applyChange, setApplyChange] = React.useState(false)

    const accountStatements = async () => {
        let data: any = sessionStorage.getItem('user')
        let authData: any = sessionStorage.getItem('user')
        const userDetails = JSON.parse(data)
        const authDetails = JSON.parse(authData)

        const path = 'Transporters/AccountStatements'
        const body = {
            TransporterID: userDetails.transporterID,
            FromDate: moment(fromDate).format('YYYY-MM-DDT00:00:00'),
            ToDate: moment(toDate).format('YYYY-MM-DDT00:00:00'),
            Search: searchText
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": authDetails.jwToken
        }
        const response = await get(path, body, header)
        if (response.succeeded) {
            setStatement(response.data);
        }
    }

    const exportExcel = async () => {
        let userdata: any = sessionStorage.getItem('user')
        let authData: any = sessionStorage.getItem('user')
        const userDetails = JSON.parse(userdata)
        const authDetails = JSON.parse(authData)

        const path = apiLink + `Transporters/downloadStatement?FromDate=${moment(fromDate).format('YYYY-MM-DDT00:00:00')}&ToDate=${moment(toDate).format('YYYY-MM-DDT00:00:00')}&TransporterID=${userDetails.transporterID}`

        fetch(path, {
            headers: {
                'Authorization': authDetails.jwToken,
                "IsNewVersion": "true"
            }
        })
            .then(response => response.blob())
            .then(blobData => {
                const url = window.URL.createObjectURL(blobData);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Transporter_Account_Statement_Report.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error fetching blob file:', error));

    }

    const exportExcelManual = async () => {
        if (statement.length > 0) {

            const dataPoints = ['shipmentNumber', 'documentNumber', 'lrNumber', 'freightCharges', 'advanceAmount', 'pendingAmount', 'deductionAmount', 'retentionAmount', 'dispatchedDate'];

            const customHeadings = ['Shipment Number', 'Document Number', 'Lr Number', 'Freight charges', 'Paid Amount', 'Pending Amount', 'Deduction Amount', 'Retention Amount', 'Dispatched Date'];

            const data = statement.map((obj: any) => dataPoints.map(key => obj[key]));

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet([customHeadings, ...data]);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
            XLSX.writeFile(workbook, 'account-statement.xlsx');
        }
    }

    React.useEffect(() => {
        accountStatements()
    }, [applyChange])

    return (
        <Container fluid style={{ width: '100%', height: '100%' }}>
            <Header breadCrumb='account-statement' />
            <Row>
                <SideBarMenu selectedPath='Account Statement' />
                <Col className="px-3 contentArea" style={{ backgroundColor: '#f5f5f5' }}>
                    <Row className="px-2">
                        <Card className="border-0 mt-2" style={{ height: (window.innerHeight - 120) }}>
                            <div className="row p-0 m-0 pt-3">
                                <div className="col-2 p-0 m-0">
                                    <p className='text-center'>From date</p>
                                </div>
                                <div className="col-2 p-0 m-0">
                                    <input
                                        type="date"
                                        name="fromDate"
                                        value={fromDate}
                                        min={"1970-01-01"}
                                        max={moment().subtract(7, 'd').format('YYYY-MM-DD')}
                                        onChange={(event: any) => {
                                            setFromDate(event.target.value)
                                            setToDate(moment(event.target.value).add(Days, 'd').format('YYYY-MM-DD'))
                                        }}
                                        id="fromDate"
                                        className='form-control'
                                        style={{ height: '35px' }}
                                    />
                                </div>
                                <div className="col-2 p-0 m-0">
                                    <p className='text-center'>To date</p>
                                </div>
                                <div className="col-2 p-0 m-0">
                                    <input
                                        type="date"
                                        name="toDate"
                                        value={toDate}
                                        min={"1970-01-01"}
                                        max={moment().format('YYYY-MM-DD')}
                                        onChange={(event: any) => {
                                            setToDate(event.target.value)
                                            setFromDate(moment(event.target.value).subtract(Days, 'd').format('YYYY-MM-DD'))
                                        }}
                                        id="toDate"
                                        className='form-control'
                                        style={{ height: '35px' }}
                                    />
                                </div>
                                <div className="col-2">
                                    <input
                                        type="button"
                                        value="Apply"
                                        className='form-control btn btn-sm btn-success'
                                        onClick={() => setApplyChange(!applyChange)}
                                    />
                                </div>
                                <div className="col-2">
                                    <input
                                        type="button"
                                        value="Export"
                                        className='form-control btn btn-sm btn-primary'
                                        onClick={exportExcel}
                                    />
                                </div>
                            </div>
                            {loading ? <Loader /> : (
                                <Card.Body
                                    style={{ overflowY: 'scroll', maxHeight: '90vh' }}
                                    className="contentArea">
                                    {statement.length > 0 ? (
                                        <>
                                            <Row >
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Shipment Number</th>
                                                            <th>LR No</th>
                                                            <th>Freight Charges</th>
                                                            <th>Paid Amount</th>
                                                            <th>Pending Amount</th>
                                                            <th>Deduction Amount</th>
                                                            <th>Retention Amount</th>
                                                            <th>Deduction Remark</th>
                                                            <th>Dispatch Date</th>
                                                            <th>Document Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {statement.map((items: any) => {
                                                            // console.log(items)
                                                            return (
                                                                <tr className="hoverTr" style={{ cursor: 'pointer' }}>
                                                                    <td>
                                                                        <small className="text-primary" style={{ cursor: 'pointer' }}>{items.shipmentNumber}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.lrNumber}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.freightCharges}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.advanceAmount}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.pendingAmount}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.deductionAmount}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.retentionAmount}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.deductionRemarks || '-'}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.dispatchedDate || '-'}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{items.documentDate || '-'}</small>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </>
                                    ) : <NotFound />}
                                </Card.Body>
                            )}
                        </Card>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default AccountStatement