import React from "react";
import { Table, Row, Toast } from "react-bootstrap";
import BidCard from "../../components/bidcard/BidCard";
import { get, getSocketApi } from "../../../../config/services/api/ApiServices";
import NotFound from "../../../notfound/Indesx";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import store, { RootState } from "../../../../store/Index";
// import { onMessageListener } from "../../../../firebase";
import { changeBidData } from "../../../../store/slices/participatedBids";
import Loader from "../../../trips/components/loader/Index";
import { FetchUserData } from "../../../../common/functions/CommonFunctions";
import TableHeader from "../../components/Tableheader";

const Participated = (props: any) => {

    const dispatch = useAppDispatch();
    FetchUserData(dispatch)

    const user = useAppSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const socketAuth = useAppSelector((state: RootState) => state.socket.socketAuth);

    const [participatedBids, setParticipatedBids] = React.useState([])

    const [show, setShow] = React.useState(false)
    const [notificationTitle, setNotificationTitle] = React.useState('')
    const [notificationMsg, setNotificationMsg] = React.useState('')
    const [notificationBgColor, setNotificationBgColor] = React.useState('')
    const [notificationTextColor, setNotificationTextColor] = React.useState('')

    const [notificationArray, setNotificationArray] = React.useState<any>([])

    const [loading, setLoading] = React.useState(true)

    // React.useEffect(() => {
    // }, [participatedData])
    // onMessageListener().then((payload: any) => {
    //     var data = payload.data;

    //     participatedBids.map((element: any) => {

    //         if (element.id == data.BiddingId) {

    //             setShow(true)
    //             setNotificationMsg(data.rank)
    //             setNotificationTitle(element.auctionNumber)
    //             setNotificationBgColor(data.rankColor)
    //             setNotificationTextColor(data.rankTextColor)

    //             element.rank = data.rank;
    //             element.rankColor = data.rankColor;
    //             element.rankTextColor = data.rankTextColor;
    //             element.lastBidRate = data.lastBidRate;
    //             element.overallTransportersLastBidRate = data.lastBidRate

    //             return element;
    //         }

    //         return element;
    //     })
    //     store.dispatch(changeBidData({}));
    // }).catch((err: any) => console.log('failed: ', err));

    React.useEffect(() => {
        getParticipatedBids()
        setNotificationArray([])
    }, [])

    const getParticipatedBids = async () => {
        setLoading(true)
        const path = 'auction'
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "Type": "on going",
            "Page": 1,
            "Search": '',
            "Sort": "participated",
        }

        const response = await getSocketApi(path, body, socketAuth)
        setLoading(false)
        if (response.status == 200) {
            var pdata = response.data.filter((item: any) => item.rank != null);
            setParticipatedBids(pdata)
        }
    }

    return (
        <Row className="contentArea p-0 m-0 ">

            <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
                position: 'absolute',
                top: 20,
                right: 20
            }}>
                <Toast.Header style={{ justifyContent: 'space-between' }}>
                    <div className="d-flex align-items-center">
                        <div className="rounded-pill d-flex justify-content-center align-items-center me-3" style={{ width: 30, height: 30, backgroundColor: notificationBgColor, color: notificationTextColor }}>{notificationMsg}</div>
                        <strong className="mr-auto">{notificationTitle}</strong>
                    </div>
                </Toast.Header>
            </Toast>

            {loading ? <Loader /> : (
                <>
                    {participatedBids.length > 0 ? (
                        <>
                            <Table className="table">
                                <TableHeader />
                                <tbody>
                                    {participatedBids.map((bids: any) => <BidCard data={bids} key={bids.id} tabName='participated' />)}
                                </tbody>
                            </Table >
                        </>) : <NotFound />}
                </>
            )}
        </Row>
    )
}

export default Participated