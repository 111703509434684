import React from 'react'
import { Card, Col, Container, Modal, Row, Spinner } from 'react-bootstrap'
import { useAppSelector } from '../../../../store/hooks';
import { RootState } from '../../../../store/Index';
import { useSelector } from 'react-redux';
import { get } from '../../../../config/services/api/ApiServices';
import TripCard from '../../components/trip_card/Index';
import { CommonStyles } from '../../../../common/styles/CommonStyles';
import NotFound from '../../../notfound/Indesx';
import { OpenToast } from '../../../../components/toast/Index';
import Inspection from '../../components/gate_and_parkIn_inspection/Index';
import ParkingIn from '../../components/parking_in/Index';

export const TripBaseCard = (data: any) => {


    const item = data.data

    const [tripStatus, setTripStatus] = React.useState('')
    const [checkStatus, setCheckeStatus] = React.useState(false)

    const openStatusModal = (status: any) => {
        if (status == 'Gate In Inspection' || status == 'Gate Out Inspection' || status == 'Post Loading Check') {
            data.openStatusModal(item, status)
        } else {
            setTripStatus(status)
            setCheckeStatus(true)
            setTimeout(() => {
                setCheckeStatus(false)
            }, 2000)
        }
    }

    return (
        <Col xl={4} md={6} className='mb-1 p-2'>
            {checkStatus ? <OpenToast text={tripStatus} /> : <></>}
            <Card className='p-3'>
                <Row>
                    <TripCard data={item} openStatusModal={openStatusModal} />
                </Row>
            </Card>
        </Col>
    )
}


const UnderLoadingForParkingUser = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const searchText = useAppSelector((state: RootState) => state.search.text);

    const [trips, setTrips] = React.useState<any>([])
    const [loading, setLoading] = React.useState(false)

    const [showStatusModal, setShowStatusModal] = React.useState(false)
    const [showParkinModal, setShowParkinModal] = React.useState(false)

    const [tripData, setTripData] = React.useState<any>(null)

    const [status, setStatus] = React.useState('')

    React.useEffect(() => {
        getAllTrips()
    }, [searchText])

    const getAllTrips = async () => {
        setLoading(true)
        const path = 'Trips/GetTrips';
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "UserType": user.userType,
            "Type": 'Ready for Dispatch',
            "Page": 1,
            "search": searchText,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        setLoading(false)
        if (response.succeeded) {
            // var data = response.data.allTrips;
            // response.data.allTrips.map((item: any) => {
            //     var today = moment(new Date())
            //     var expectedDate = moment(item.expectedLoadingDate, 'DD MMM, YYYY')
            //     expectedDate = moment(expectedDate, 'DD MM YYYY').add(24, 'hours')
            //     item.isDelayed = today.isSameOrAfter(expectedDate)
            // })
            setTrips(response.data.allTrips);
        }
    }

    const openStatusModal = React.useCallback((data: any, status: any) => {
        setStatus(status)
        setTripData(data)
        if (user.userType == 'Plant Secuirity Officer' && status == 'Gate In Inspection') {
            handleOpenInspectionModal()
        } else if (status == 'Post Loading Check') {
            handleOpenParkinModal()
        }
    }, [])

    const handleCloseInspectionModal = () => setShowStatusModal(false)
    const handleOpenInspectionModal = () => setShowStatusModal(true)

    const handleCloseParkinModal = () => setShowParkinModal(false)
    const handleOpenParkinModal = () => setShowParkinModal(true)

    const closeModal = React.useCallback(() => {
        handleCloseInspectionModal()
        handleCloseParkinModal()
        getAllTrips()
    }, [])

    return (
        <Container fluid>
            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Row>
                    {trips.length > 0 ? (
                        <>
                            {trips.map((item: any) => <TripBaseCard data={item} key={item.id} openStatusModal={openStatusModal} />)}
                        </>
                    ) : <NotFound />}
                </Row>
            )}

            <Modal show={showStatusModal} onHide={handleCloseInspectionModal} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton>
                    <Modal.Title>{status}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    <Inspection data={tripData} closeModal={closeModal} status={status} />
                </Modal.Body>
            </Modal>

            <Modal show={showParkinModal} onHide={handleCloseParkinModal} fullscreen style={CommonStyles.medalStyle}>
                <Modal.Header closeButton>
                    <Modal.Title>{status}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='contentArea'>
                    <ParkingIn closeModal={closeModal} data={tripData} status={status} />
                </Modal.Body>
            </Modal>

        </Container>
    )
}

export default UnderLoadingForParkingUser