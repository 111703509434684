import React from "react";
import { Card, Container } from "react-bootstrap";
import notfoundImage from '../../assets/images/noData.png'

const NotFound = (props:any) => {
    return (
        <Container className="d-flex w-100 justify-content-center align-items-center "  style={{height: 500}}>
            <img src={notfoundImage} alt="No Data" style={{width: 300}}/>
        </Container>
    )
}

export default NotFound