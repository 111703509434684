import { Action, createSlice } from "@reduxjs/toolkit";
import type { RootState } from '../../store/Index';
interface CounterState {
    changeBid: boolean,
    bidData: object,
}

const initialState: CounterState = {
    changeBid: false,
    bidData: {}
}

const participatedDataSlice = createSlice({
    name: 'participatedBids',
    initialState : initialState,
    reducers: {
        bidChangeState: (state) => {
            state.changeBid = !state.changeBid
        },
        changeBidData: (state, action) => {
            state.bidData = action.payload
        }
    }
});

export default participatedDataSlice.reducer;

export const {
    bidChangeState, changeBidData
} = participatedDataSlice.actions;
