import React from "react";
import { Card, Col, Form, Image, InputGroup, Row } from "react-bootstrap";
import { Style } from "./Style";
import { FaBars, FaFacebookMessenger, FaFile, FaMobile, FaMobileAlt, FaSearch, FaStar, FaVoicemail } from "react-icons/fa";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/Index";
import CommonColors from "../../common/colors/CommonColor";
import { useDispatch } from "react-redux";
import { addUser, deleteUser } from "../../store/slices/user";
import { removeAuth, setAuth } from "../../store/slices/authentication";
import { useNavigate } from "react-router-dom";
import { setText } from "../../store/slices/search";

export const Header = (props: any) => {

    const [hideShowMenu, setHideShowMenu] = React.useState(false)

    var navigate = useNavigate()

    const user = useAppSelector((state: any) => state.user);
    const dispatch = useDispatch()

    const [showProfile, setShowProfile] = React.useState(false)

    function hideMenu() {
        setHideShowMenu(!hideShowMenu)
        var sideMenuId = document.getElementById('sideMenu')
        if (sideMenuId) {
            sideMenuId.style.display = hideShowMenu ? "none" : "block"
        }
    }

    const logout = () => {
        setShowProfile(false)
        if (window.confirm('Are you Sure to Logout?') == true) {
            dispatch(deleteUser())
            dispatch(removeAuth())
            navigate('/')
        }
    }

    return (
        <Row className="headerFix">
            <Col className="p-0 m-0">
                <Row className="py-2 px-3 m-0 align-items-center" style={{ borderBottom: '2px solid #e3e3e3' }}>
                    <Col xl={7} md={3} sm={6} xs={2} className="d-flex justify-content-start align-items-center m-0 p-0">
                        <div className="me-3 bars d-sm-none" onClick={hideMenu}><FaBars size={20} /></div>
                        <Image src={require('../../assets/images/ib_logo.png')} style={Style.logo} className="d-sm-block d-none" />
                        <Image src={require('../../assets/images/abis_logo.png')} style={Style.logo} className="d-sm-block d-none" />
                    </Col>
                    <Col xl={5} md={9} sm={6} xs={10}>
                        <Row className=" m-0 justify-content-end">
                            {window.location.pathname != '/home' ? (
                                <Col xxl={7} xl={6} md={6} sm={10} xs={10} className="p-0 m-0 justify-content-end">
                                    <InputGroup className="w-100">
                                        <Form.Control
                                            placeholder="Search here ..."
                                            aria-label="search"
                                            aria-describedby="basic-addon1"
                                            className="w-50"
                                            size="sm"
                                            onChange={(e: any) => dispatch(setText(e.target.value))}
                                        />
                                        <InputGroup.Text id="basic-addon1"><FaSearch /></InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            ) : <></>}
                            <Col className="align-items-center p-0 m-0" xxl={5} xl={6} md={6} sm={2} xs={2}>
                                <Row>
                                    <Col className="d-flex align-items-center justify-content-end p-0 m-0 ">
                                        <h6 className="px-4 d-md-block d-none text-center m-0">{user.userName}</h6>
                                        <Image src={require('../../assets/images/profile.png')} rounded style={Style.header_profile_image} onMouseOver={() => setShowProfile(true)} onClick={() => setShowProfile(!showProfile)} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {showProfile ? (
                    <Row onMouseLeave={() => setShowProfile(false)}>
                        <Col>
                            <Card className="p-2 m-0" style={{ position: 'absolute', right: 30, top: 60, zIndex: 10 }}>
                                <Row className="mb-2 p-0">
                                    <Col sm={2} className="justify-content-center ">
                                        <Image src={require('../../assets/images/profile.png')} rounded style={Style.header_profile_image} className="ms-2"/>
                                    </Col>
                                    <Col sm={10} className="d-flex align-items-center">
                                        <h6 className="px-4">{user.userName}</h6>
                                    </Col>
                                </Row>

                                {user.userType == 'Transporter' ? (
                                    <span className="mb-2">
                                        <span className="d-flex justify-content-center">
                                            {[...Array(5)].map((item: any, index: any) => {
                                                return <FaStar color={index + 1 <= user.rating ? "green" : "lightgray"} size={20} style={{ margin: 5 }} key={index}/>
                                            })}
                                        </span>
                                        <p className="p-0 m-0 text-center">You are rated {user.rating} of 5</p>
                                    </span>
                                ) : <></>}
                                <input type="button" value="Logout" className="btn" style={{ backgroundColor: CommonColors.PRIMARY, color: 'white' }} onClick={logout} />
                            </Card>
                        </Col>
                    </Row>
                ) : (<></>)}

            </Col>
        </Row>
    )
}