import React, { useState } from 'react'
import TextView from '../../text_view/Index'
import { Button, Card, Col, Form, Modal, Row, Toast, ToastContainer } from 'react-bootstrap'
import moment from 'moment'
import CommonColors from '../../../../../common/colors/CommonColor'
import { useAppSelector } from '../../../../../store/hooks'
import { RootState } from '../../../../../store/Index'
import { get, post, uploadMedia } from '../../../../../config/services/api/ApiServices'

export const Reddot = () => <span className='text-danger h4'>*</span>

const Materials = (props: any) => {

    const { isDisableVerifyButton, setIsDisableVerifyButton, status } = props

    const [materialJson, setMaterialJson] = React.useState(props.materialJson)

    const materialData: any = props.data

    const requestedNumber = materialData?.requestedNumber
    const averageWeight = materialData?.averageWeight

    const [deliveredQtyMt, setDeliveredQtyMt] = React.useState('0')
    const [deliveredQtyNo, setDeliveredQtyNo] = React.useState(materialData?.requestedNumber || '0')

    const [damagedQtyMt, setDamagedQtyMt] = React.useState('0')
    const [damagedQtyNo, setDamagedQtyNo] = React.useState('0')

    const [shortageQtyMt, setShortageQtyMt] = React.useState('0')
    const [shortageQtyNo, setShortageQtyNo] = React.useState('0')

    const [showError, setShowError] = React.useState(false)
    const [error, setError] = React.useState('')

    var values = materialJson[materialData.id] || {};
    React.useEffect(() => {
        if (materialJson.hasOwnProperty(materialData.id)) {
            setDeliveredQtyMt(values['deliveredQuantity'] || '0')
            setDamagedQtyMt(values['damagedQuantity'] || '0')
            setShortageQtyMt(values['shortageQuantity'] || '0')
        }
        handleDeliveryChange('0')
    }, [])

    const handleDeliveryChange = (value: any) => {
        const regex = /^[0-9]+$/;
        
        setDamagedQtyNo('0')
        setDamagedQtyMt('0')

        if (regex.test(value) || value === '') {
            if (parseFloat(value) > requestedNumber) {
                setError('Delivred quantity must be less than requested quantity')
                setShowError(true)
            } else {
                setShortageQtyNo(value)
                setShortageQtyMt((parseFloat(value || '0') * averageWeight / 1000).toFixed(2).toString())
                if (materialJson.hasOwnProperty(materialData.id)) {
                    values['shortageQuantity'] = value || '0'
                    // values['shortageQuantity'] = (parseFloat(value || '0') * averageWeight / 1000).toFixed(2).toString()
                }
                // value(bags) * average / 1000
                var shortage = parseFloat(requestedNumber) - parseFloat(value || '0')
                setDeliveredQtyNo(shortage.toFixed(2).toString())
                setDeliveredQtyMt((shortage * averageWeight / 1000).toFixed(2).toString())
                if (materialJson.hasOwnProperty(materialData.id)) {
                    values['deliveredQuantity'] = shortage || '0'
                    // values['deliveredQuantity'] = (shortage * averageWeight / 1000).toFixed(2).toString()
                }

                props.changeMaterialData(values)
            }
        }
        setTimeout(() => setShowError(false), 3000)
    }

    const handleDamagedChange = (event: any) => {
        const regex = /^[0-9]+$/;
        let data = event.target.value
        if (regex.test(data) || data === '') {
            if (parseFloat(data) > parseFloat(deliveredQtyNo || '0')) {
                setError('Damaged quantity must me less than delivered quantity')
                setShowError(true)
            } else {
                setDamagedQtyNo(data)
                setDamagedQtyMt((parseFloat(data || '0') * averageWeight / 1000).toFixed(2).toString())
                if (materialJson.hasOwnProperty(materialData.id)) {
                    values['damagedQuantity'] = data || '0'
                    // values['damagedQuantity'] = (parseFloat(event.target.value || '0') * averageWeight / 1000).toFixed(2).toString()
                }
            }
        }

        setTimeout(() => setShowError(false), 3000)
    }

    return (
        <div className='my-1'>
            {showError ? (
                <ToastContainer position="bottom-start" className="p-0 text-white" style={{ zIndex: 1, position: 'fixed' }}>
                    <Toast className='p-2 border-0 bg-danger text-white' style={{ width: 400 }}>
                        <Toast.Header className='border-0 bg-danger'>
                            <h6 className="me-auto text-white p-0 m-0">{error}</h6>
                            <small className="text-muted"></small>
                        </Toast.Header>
                    </Toast>
                </ToastContainer>
            ) : <></>}
            <Row>
                <Col>
                    {materialData?.materialName} ({requestedNumber})
                </Col>
                <Col>
                    <Row className='d-flex align-items-center'>
                        <Col md={8}>
                            <Form.Control
                                placeholder='0'
                                type='number'
                                value={shortageQtyNo}
                                onChange={(event: any) => handleDeliveryChange(event.target.value)}
                                disabled={status == 'Completed' ? true : false}
                            />
                        </Col>
                        <Col>
                            <p className='p-0 m-0'>({shortageQtyMt} MT)</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className='d-flex align-items-center'>
                        <Col md={8}>
                            <Form.Control
                                placeholder='0'
                                type='number'
                                disabled={status == 'Completed' ? true : false}
                                value={damagedQtyNo}
                                onChange={handleDamagedChange}
                            />
                        </Col>
                        <Col>
                            <p className='p-0 m-0'>({damagedQtyMt} MT)</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className='d-flex align-items-center'>
                        <Col md={8}>
                            <Form.Control
                                placeholder='0'
                                type='number'
                                value={deliveredQtyNo}
                                disabled
                                onChange={() => { }} />
                        </Col>
                        <Col>
                            <p className='p-0 m-0'>({deliveredQtyMt} MT)</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

const UploadPOD = (props: any) => {

    const user = useAppSelector((state: RootState) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const tripCardDetails = useAppSelector((state: RootState) => state.tripCard);

    const [tripData, setTripData] = React.useState(props.data)
    const [extraData, setExtraData] = React.useState<any>(props.extraData)
    const [tripDetails, setTripDetails] = React.useState(props.tripDetails)

    const [materialJson, setMaterialJson] = React.useState<any>({})

    var reportingTime = extraData?.deliveryAddress[0]?.reportingTime ? moment(extraData?.deliveryAddress[0].reportingTime) : moment()

    const [podFileName, setPodFileName] = useState<any>(null)

    const [unloadingPersonName, setUnloadingPersonName] = useState('');
    const [unloadingPersonContact, setUnloadingPersonContact] = useState('');

    const [error, setError] = React.useState('')
    const [showError, setShowError] = React.useState(false)

    const [disable, setDisable] = React.useState(false)

    const [msg, setMsg] = React.useState('')
    const [otp, setOtp] = React.useState('')
    const [deliveryId, setDeliveryId] = React.useState('')

    const [otpError, setOtpError] = React.useState(false)

    const [isDisableVerifyButton, setIsDisableVerifyButton] = React.useState(false)
    const [showToast, setShowToast] = React.useState(false)
    const [showOtpInputModalVisiable, setShowOtpInputModalVisiable] = React.useState(false)

    const [status, setStatus] = React.useState(extraData?.deliveryAddress[0]?.verifiedButtonStatus)

    React.useEffect(() => {
        extraData?.deliveryAddress[0]?.materials.map((item: any, index: any) => {
            var obj = {
                "id": item.id,
                "materialName": item.materialName,
                "deliveredQuantity": item.requestedNumber,
                "damagedQuantity": '0',
                'averageWeight': item.averageWeight,
                "shortageQuantity": '0',
                "damagedImageVisible": false,
                "damagedMaterials": [
                    {
                        "damagedMaterial": ''
                    }
                ]
            }
            materialJson[item.id] = obj
        })
        setMaterialJson(materialJson)
    }, [])

    function validateMaterials() {

        var data = Object.values(materialJson);

        var check = data.every((element: any) => {
            return element.deliveredQuantity == '' || element.deliveredQuantity == '0' ? false : true
        })

        return check ? data : false
    }

    const validate = () => {
        let res = Object.values(materialJson).every((e: any) => {
            if (e.deliveredQuantity == '' || e.deliveredQuantity == '0') {
                setMsg('Please enter delivered quantity')
                setShowToast(true)
                return false
            } else if (parseFloat(e.damagedQuantity) >= parseFloat(e.deliveredQuantity)) {
                setMsg('Damaged quantity must me less than delivered quantity')
                setShowToast(true)
                return false
            } else {
                setShowToast(false)
                setDisable(false)
                return e
            }
        })

        return res
    }

    const validatePOD = async () => {

        if (validate()) {
            if (podFileName == null) {
                setError('Please upload POD document')
                setShowError(true)
            } else {
                // uploadPOD()
                await resend(deliveryId)
            }
        }

        setTimeout(() => {
            setShowError(false)
        }, 2000)
    }

    const uploadPOD = async () => {
        setShowOtpInputModalVisiable(false)
        setDisable(true)
        const path = 'POD/VerifyOTP';

        var newArray = Object.values(materialJson).map((item: any) => {
            item.deliveredQuantity = item.deliveredQuantity != '' ? ((parseFloat(item.deliveredQuantity) * (item.averageWeight)) / 1000).toString() : '0'
            item.shortageQuantity = item.shortageQuantity != '' ? ((parseFloat(item.shortageQuantity) * (item.averageWeight)) / 1000).toString() : '0'
            item.damagedQuantity = item.damagedQuantity != '' ? ((parseFloat(item.damagedQuantity) * (item.averageWeight)) / 1000).toString() : '0'
            return item
        })

        const body = {
            "id": tripData?.id,
            "OTP": otp,
            "shippingID": extraData?.deliveryAddress[0]?.id,
            "userID": user.userID,
            "userType": user.userType,
            "podAttachment": await uploadImagesToServer('POD Document upload', podFileName),
            "gateInSlipNumber": tripDetails?.gateInSlipNumber || '',
            "invoiceNumber": extraData?.deliveryAddress[0]?.invoiceNumber,
            "reportingOn": reportingTime ? reportingTime.format('YYYY-MM-DD hh:mm:ss') : moment().format('YYYY-MM-DD hh:mm:ss'),
            "unloadingOn": moment().format('YYYY-MM-DD hh:mm:ss'),
            "feedBack": '',
            "remarks": '',
            "unloadingPerson": unloadingPersonName,
            "unloadingPersonSign": '',
            "unloadingPersonPhoto": '',
            "unloadingPersonPhone": unloadingPersonContact,
            "uploadedPerson": tripData?.driverName || '',
            "uploadedPersonSign": '',
            "uploadedPersonPhoto": '',
            "unloadingPersonName": unloadingPersonName,
            "materials": newArray,
            "ApplicationType": "TMS",
        }

        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        setDisable(false)
        var response = await post(path, body, header);
        if (response.succeeded) {
            window.alert(response.message)
            props.closeUploadModal()
        } else {
            window.alert(response.message || 'Something went wrong !!!')
        }
    }

    async function uploadImagesToServer(page: any, file: any) {
        var image: any = file != null ? await uploadMedia(page, file, { 'Content-Type': 'multipart/form-data' }) : ''
        return image.succeeded ? image.data[0].url : ''
    }

    const changeMaterialData = React.useCallback((data: any) => {
        setMaterialJson((previous: any) => ({ ...previous, [data.id]: data }))
    }, [])

    const enterPodVerifyOtp = async (deliveryId: any) => {
        setOtp('')
        // console.log(validate())
        if (validate()) {
            // setIsDisableVerifyButton(true)
            setDeliveryId(deliveryId)
            setShowOtpInputModalVisiable(true)
            await resend(deliveryId)
        } else {
            setError('Please enter delivered quantity')
            setShowError(true)
            // setIsDisableVerifyButton(false)
        }
    }

    const resend = async (shippingId: any) => {
        setShowOtpInputModalVisiable(true)
        const path = 'POD/TriggerCustomerOtp'

        const body = {
            "ID": tripData?.id,
            "ShippingID": extraData?.deliveryAddress[0].id,
            "Status": tripData?.status,
            "materials": validateMaterials()
        }
        const header = {
            'Content-Type': 'application/json',
            'Authorization': auth.jwToken
        }
        var response = await post(path, body, header)
        if (response.succedded) {
            setShowOtpInputModalVisiable(false)
            setMsg(response.message)
            setTimeout(() => {
                setShowToast(false)
            }, 2000)
            return true
        } else {
            setMsg(response.message || 'Failed to send OTP')
            setTimeout(() => {
                setShowToast(false)
            }, 2000)
            return false
        }
    }

    const validateOtp = () => {
        if (otp.length != 4) {
            setOtpError(true)
        } else {
            uploadPOD()
        }
    }

    return (
        <div>
            {showToast ? (
                <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
                    <Toast style={{ backgroundColor: 'green' }}>
                        <Toast.Header>
                            <strong className="me-auto">{msg}</strong>
                            <small className="text-muted"></small>
                        </Toast.Header>
                    </Toast>
                </ToastContainer>
            ) : <></>}

            <Row className='mb-3'>
                <Col>
                    <h6>Trip Number</h6>
                    <Form.Control type='text' value={tripCardDetails?.tripDetails?.tripNumber || '-'} disabled onChange={() => { }} size='sm' />
                </Col>
                <Col>
                    <h6>Shipment Number</h6>
                    <Form.Control type='text' value={tripCardDetails?.cardDetails?.shipmentNumber || '-'} disabled onChange={() => { }} size='sm' />
                </Col>
                <Col>
                    <h6>Driver's Name</h6>
                    <Form.Control type='text' value={tripCardDetails?.tripDetails?.driverName || '-'} disabled onChange={() => { }} size='sm' />
                </Col>
                <Col>
                    <h6>Vehicle Number</h6>
                    <Form.Control type='text' value={tripData?.vehicleNumber || '-'} disabled onChange={() => { }} size='sm' />
                </Col>
            </Row>

            <h6>Material Details</h6>
            <Card className='p-2'>
                <Row>
                    <Col><strong>Material Name</strong></Col>
                    <Col><strong>Shortage</strong></Col>
                    <Col><strong>Damaged</strong></Col>
                    <Col><strong>Delivered</strong></Col>
                </Row>
                <hr />
                {extraData?.deliveryAddress[0]?.materials.map((material: any, index: any) =>
                    <Materials
                        data={material}
                        key={index}
                        indexing={index}
                        materialJson={materialJson}
                        changeMaterialData={changeMaterialData}
                        isDisableVerifyButton={isDisableVerifyButton}
                        setIsDisableVerifyButton={setIsDisableVerifyButton}
                        status={status}
                    />
                )}
            </Card>

            {/* {status != 'Completed' && <Row className='mt-2'>
                <Col className='d-flex justify-content-end'>
                    <input type="button" value="Send OTP" className={`btn ms-1 btn-sm mb-1 ${disable ? 'btn-secondary' : 'btn-success'}`} disabled={disable ? true : false} onClick={enterPodVerifyOtp} />
                </Col>
            </Row> } */}

            <Row className='my-4'>
                <Col md={6}>
                    <Row>
                        <Col>
                            <h6>Upload POD <Reddot /></h6>
                        </Col>
                    </Row>
                    <Form.Control type='file' onChange={(e: any) => setPodFileName({ file: e.target.files[0], uri: e.target.value })} />
                </Col>
            </Row>

            <Modal show={showOtpInputModalVisiable} onHide={() => setShowOtpInputModalVisiable(false)}>
                <Modal.Header closeButton className='p-1 px-3'>
                    <h5>OTP Verification</h5>
                </Modal.Header>
                <Modal.Body className='contentArea'>

                    {otpError && <div className="alert alert-danger p-1 px-2" role="alert">
                        Incorrect OTP. Please try again after sometimes.
                    </div>}

                    <input type="number" value={otp} placeholder='x x x x' maxLength={4} className='form-control' style={{ border: `1px solid gray` }} onChange={(text: any) => {
                        setOtp(text.target.value.toString())
                    }} />

                    <input type="button" value='close' className='btn btn-sm ms-1 btn-secondary mt-3' style={{ border: `1px solid gray` }} onClick={() => {
                        setShowOtpInputModalVisiable(false)
                        setOtp('')
                        setIsDisableVerifyButton(true)
                        setDisable(false)
                        setOtpError(false)
                    }} />

                    <input type="button" value='Submit' className='btn btn-sm ms-1 btn-success mt-3' style={{ border: `1px solid gray` }} onClick={validateOtp} />
                </Modal.Body>
            </Modal>

            <Modal.Footer className='p-0 m-0 border-0 me-5'>
                {showError ? (
                    <ToastContainer position="bottom-start" className="p-0 text-white" style={{ zIndex: 1, position: 'fixed' }}>
                        <Toast className='p-2 border-0 bg-danger text-white' style={{ width: 400 }}>
                            <Toast.Header className='border-0 bg-danger'>
                                <h6 className="me-auto text-white p-0 m-0">{error}</h6>
                                <small className="text-muted"></small>
                            </Toast.Header>
                        </Toast>
                    </ToastContainer>
                ) : <></>}
                <Button variant="secondary" onClick={() => props.closeUploadModal()} className={`px-5 bg-${disable ? 'secondary' : 'danger'}`} disabled={disable}>
                    Close
                </Button>

                <input type="button" value="Submit" style={{ backgroundColor: disable ? 'gray' : CommonColors.PRIMARY }} className='btn text-white px-5' onClick={validatePOD} disabled={disable} />
            </Modal.Footer>
        </div>
    )
}

export default UploadPOD