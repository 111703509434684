import moment from "moment";
import React from "react";
import store from "../../../store/Index";
import { deleteUser } from "../../../store/slices/user";
import { removeAuth } from "../../../store/slices/authentication";
import { DEV_API, QA_API, LIVE_API, SocketUrl } from "../../constants/Constants";

// const apiLink = 'https://scm-api.abisaio.com/api/v1/';
// const apiLink = 'http://15.206.93.35:8080/api/v1/';
export const apiLink = window.location.origin == 'https://tportal.abisaio-dev.com' ? DEV_API : (window.location.origin == 'https://tportal-qa.abisaio.com' ? QA_API : (window.location.origin == 'https://tms.abisaio.com' ? LIVE_API : QA_API));
export const mediaServerLink = (window.location.origin == 'https://tportal.abisaio-dev.com' || window.location.origin == 'https://tportal-qa.abisaio.com') ? "https://media.abisaio-dev.com/api/v1/Storage/Save?token=" : "https://media.abisaio.com/api/v1/Storage/Save?token="
// const apiLink = DEV_API;
// const apiLink = 'https://s2api.abisaio.com/api/v1/';

const user = store.getState()
const userId = user.user.userID

export async function authentication() {
    var obj = await sessionStorage.getItem("authorization");
    if (obj) {
        var authObj = JSON.parse(obj);
        var minDiff = moment(authObj.jwTokenExpiryDate).diff(moment(), 'minutes');
        if (minDiff < 1) {
            var refDiff = moment(authObj.refreshTokenExpiryDate).diff(moment(), 'minutes');
            if (refDiff < 1) {
                // Logout
                store.dispatch(removeAuth());
                store.dispatch(deleteUser());
            } else {
                // call Refresh token
                const path = 'Account/Refresh';
                const body = {
                    "jwToken": authObj.jwToken,
                    "refreshToken": authObj.refreshToken
                }
                const header = { 'Content-Type': 'application/json' }
                try {
                    var response = await post(path, body, header);
                    if (response.succeeded) {
                        let newAuthorization = {
                            jwToken: response.data.jwToken,
                            refreshToken: response.data.refreshToken,
                            jwTokenExpiryDate: response.data.jwTokenExpiryDate,
                            refreshTokenExpiryDate: response.data.refreshTokenExpiryDate,
                        }
                        sessionStorage.setItem('authorization', JSON.stringify(newAuthorization));
                        var authorization = "Bearer " + response.data.jwToken;
                        return authorization;
                    }
                } catch (e) {
                    log(apiLink, userId || 0, body, e, path)
                    console.log(e)
                }
            }
        } else {
            // return auth
            var authorization = "Bearer " + authObj.jwToken;
            return authorization;
        }
    }
}

export async function get(path: String, paramObject: any, headerObject: any) {

    let apiURL = apiLink + path;
    // let apiURL = 'api/v1/' + path;
    var params = "";
    Object.keys(paramObject).forEach(function (key) {
        if (params == "") {
            params += "?" + key + "=" + paramObject[key];
        } else {
            params += "&" + key + "=" + paramObject[key];
        }
    });

    try {

        headerObject['X-AppName'] = "ABIS Track"
        headerObject['X-AppBuild'] = 8
        headerObject['X-AppVersion'] = 123

        headerObject['Access-Control-Allow-Origin'] = '*'
        headerObject['Access-Control-Allow-Headers'] = 'Content-Type, Authorization'
        headerObject['Access-Control-Allow-Methods'] = 'POST'
        headerObject['Access-Control-Allow-Credentials'] = true
        headerObject['Service-Worker-Allowed'] = '/'
        headerObject['IsNewVersion'] = true

        // console.log(Date());
        const response = await fetch(apiURL + params, {
            method: 'GET',
            headers: headerObject
        });

        // if (response.status == 440) {
        //     window.alert('Someone has login to other device !!!')
        //     // showMessage({
        //     //     message: 'Someone has login to other device',
        //     //     type: 'danger',
        //     //     duration: 2000,
        //     //     icon: 'auto',
        //     //     titleStyle: { fontSize: 18, fontWeight: 'bold' },
        //     // });
        //     setTimeout(() => {
        //         store.dispatch(deleteUser());
        //         store.dispatch(removeAuth());
        //     }, 2000)
        //     // throw new Error('Request failed with status code ' + response.status);
        // } else 
        if (response.status == 401) {
            // Autentications
            let newAuth = await authentication();
            let newHeader = { ...headerObject, Authorization: newAuth }
            get(path, paramObject, newHeader);
        } else {
            const data = await response.json();
            // console.log("data...", data);
            return data;
        }
    } catch (error) {
        log(apiLink, userId || 0, paramObject, error, path)
        return { "status": 500, "error": error };
    }

}

export async function post(path: String, bodyObject: any, headerObject: any) {
    let apiURL = apiLink + path;
    // let apiURL = 'api/v1/' + path;
    try {
        headerObject['X-AppName'] = "ABIS Track"
        headerObject['X-AppBuild'] = 8
        headerObject['X-AppVersion'] = 123

        headerObject['Access-Control-Allow-Origin'] = '*'
        headerObject['Access-Control-Allow-Headers'] = 'Content-Type, Authorization'
        headerObject['Access-Control-Allow-Methods'] = 'POST'
        headerObject['Access-Control-Allow-Credentials'] = true
        headerObject['Service-Worker-Allowed'] = '/'
        headerObject['IsNewVersion'] = true

        headerObject['platform'] = "Web"

        const requestOptions: any = {
            method: 'POST',
            headers: headerObject,
            body: JSON.stringify(bodyObject)
        };
        const response = await fetch(apiURL, requestOptions);

        // if (response.status == 440) {
        //     window.alert('Someone has login to other device !!!')
        //     // console.log('----session expired logout!!----');
        //     // showMessage({
        //     //     message: 'Someone has login to other device',
        //     //     type: 'danger',
        //     //     duration: 2000,
        //     //     icon: 'auto',
        //     //     titleStyle: { fontSize: 18, fontWeight: 'bold' },
        //     // });
        //     setTimeout(() => {
        //         store.dispatch(deleteUser());
        //         store.dispatch(removeAuth());
        //     }, 2000)
        //     // throw new Error('Request failed with status code ' + response.status);
        // } else 
        if (response.status == 401) {
            // Athentications
            let newAuth = await authentication();
            let newHeader = { ...headerObject, Authorization: newAuth }
            post(path, bodyObject, newHeader);
        } else {
            const data = await response.json();
            return data;
        }
    } catch (e) {
        log(apiLink, userId || 0, bodyObject, e, path)
        return { "status": 500, "error": e };
    }
}


export async function uploadMedia(page: any, file: any, headerObject: any) {
    var responseUrl: any;
    var token: String = page == 'Parking In Inspection' ? '57331aa9-12c8-426e-a85a-6bd755beeb56' : page == 'Gate In Inspection' ? '91f7d3ab-3cf3-4ff7-9ab3-d1a21614509d' : page == 'POD - Unloading person image' ? '51d176ba-e40b-4c53-a1e8-a06655cb5575' : page == 'POD - Driver image' ? '69dfd260-b386-4c84-9684-26fbd444b44d' : page == 'POD Document upload' ? '450d12e0-6516-4c6b-910b-2f29a2e71fc9' : page == 'POD - Unloading person sign' ? '4681fc01-54cd-4506-bfc6-eddc2c06846c' : page == 'POD - Driver sign' ? '98b3b748-884c-48d9-b866-e084a8b247ea' : ''


    // 1    Parking In Inspection	Documents / Image	57331aa9-12c8-426e-a85a-6bd755beeb56					
    // 2	Gate In Inspection	Documents / Image	91f7d3ab-3cf3-4ff7-9ab3-d1a21614509d					
    // 3	POD - Unloading Person image	Image	51d176ba-e40b-4c53-a1e8-a06655cb5575				
    // 4	POD - Driver image	Image	69dfd260-b386-4c84-9684-26fbd444b44d					
    // 5	POD Document upload	Documents / Image	450d12e0-6516-4c6b-910b-2f29a2e71fc9					
    // 6	POD - Unloading person sign	Image	4681fc01-54cd-4506-bfc6-eddc2c06846c					
    // 7	POD - Driver sign	Image	98b3b748-884c-48d9-b866-e084a8b247ea

    var formdata = new FormData();

    // var imageFormData: any = {
    //     'uri': file.uri,
    //     'type': file.file.type,
    //     'name': file.file.name
    // }

    const path = mediaServerLink + token;

    var auth = await sessionStorage.getItem("authorization");
    if (auth) {
        var authorization = "Bearer " + JSON.parse(auth).jwToken;
        headerObject['Authorization'] = authorization;
    }
    formdata.append("files", file.file);

    try {
        var requestOptions: any = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        const response = await fetch(path, requestOptions);
        const data = await response.json();
        responseUrl = data

    } catch (e) {
        return { "status": 500, "error": e };
    }
    return responseUrl
}

const log = async (apiLink: any, userId: any, bodyData: any, logData: any, apiUrl: any) => {
    const path = 'https://be3wbif5zb.execute-api.ap-south-2.amazonaws.com/Prod'

    const body = {
        "userId": userId.toString(),
        "createdAt": moment(),
        "log": {
            'body': JSON.stringify(bodyData),
            'error': logData
        },
        "path": apiUrl,
        "source": "web"
    }

    const header = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Content-Type": "application/json"
    }

    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(body),
        header: header
    }

    if (apiLink == 'https://scm-api.abisaio.com/api/v1/') {
        fetch(path, requestOptions).catch((e: any) => console.log(e))
    }
}

export async function getSocketApi(path: String, paramObject: any, socketAuth: any) {
    let apiURL = SocketUrl + path;
    var params = "";
    var headerObject: any = {}
    Object.keys(paramObject).forEach(function (key) {
        if (params == "") {
            params += "?" + key + "=" + paramObject[key];
        } else {
            params += "&" + key + "=" + paramObject[key];
        }
    });
    try {

        headerObject['X-AppName'] = "ABIS Track"
        headerObject['X-AppBuild'] = 8
        headerObject['X-AppVersion'] = 123
        headerObject['IsNewVersion'] = "true"
        if (socketAuth?.accessToken?.token) {
            headerObject['Authorization'] = 'Bearer ' + socketAuth?.accessToken?.token
        }
        headerObject['Content-Type'] = 'application/json'

        const response = await fetch(apiURL + params, {
            method: 'GET',
            headers: headerObject
        });
        const data = await response.json();
        return data;
    } catch (error) {
        log(apiLink, userId || 0, paramObject, error, path)
        return { "status": 500, "error": error };
    }

}

export async function postSocketApi(path: String, bodyObject: any, socketAuth: any) {
    let apiURL = SocketUrl + path;
    var headerObject: any = {}
    try {
        headerObject['X-AppName'] = "ABIS Track"
        headerObject['X-AppBuild'] = 8
        headerObject['X-AppVersion'] = 123

        headerObject['IsNewVersion'] = "true"
        headerObject['platform'] = 'TMS'
        if (socketAuth?.accessToken?.token) {
            headerObject['Authorization'] = 'Bearer ' + socketAuth?.accessToken?.token
        }
        headerObject['Content-Type'] = 'application/json'

        const requestOptions = {
            method: 'POST',
            headers: headerObject,
            body: JSON.stringify(bodyObject)
        };
        const response = await fetch(apiURL, requestOptions);
        
        const data = await response.json();
        
        return data;
    } catch (e) {
        log(apiLink, userId || 0, bodyObject, e, path)
        return { "status": 500, "error": e };
    }
}
