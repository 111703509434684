import React from 'react'
import { Col, Row, Spinner, Form } from 'react-bootstrap'
import { CommonStyles } from '../../../../common/styles/CommonStyles'
import TextView from '../text_view/Index'
import CommonColors from '../../../../common/colors/CommonColor'
import { post, uploadMedia } from '../../../../config/services/api/ApiServices'
import { useAppSelector } from '../../../../store/hooks'
import { RootState } from '../../../../store/Index'
import { useSelector } from 'react-redux'
import InputView from '../input_disable_view/Index'

const ParkingIn = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);

    const [loading, setLoading] = React.useState(false)
    const [remark, setRemark] = React.useState('')
    const [tripData, setTripData] = React.useState<any>(props?.data || {})

    const [isTrampaulinChecked, setIsTrampaulinChecked] = React.useState('Yes')
    const [image, setImage] = React.useState<any>('')

    const [disable, setDisable] = React.useState(false)
    const [showAlert, setShowAlert] = React.useState(false)
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [msg, setMsg] = React.useState('')
    const [lorryNumber, setLorryNumber] = React.useState('')

    const validate = (isApproved: any) => {
        if (props.status == 'parkIn') {
            if (lorryNumber == '' || lorryNumber.length != 10) {
                setMsg('Please enter valid lorry number')
                setShowAlert(true)
            } else if (image == '' && !image.hasOwnProperty('uri')) {
                setMsg('Please upload lorry image')
                setShowAlert(true)
            } else {
                postParkIn(isApproved)
            }
        } else {
            if (isTrampaulinChecked == 'Yes') {
                if (image != '' && image.hasOwnProperty('uri')) {
                    if (props.status == 'Post Loading Check') {
                        postLoadingCheck(isApproved)
                    }
                } else {
                    setMsg('Please upload trampaulin image')
                    setShowAlert(true)
                }
            } else {
                if (props.status == 'Post Loading Check') {
                    postLoadingCheck(isApproved)
                }
            }
        }
        setTimeout(() => {
            setShowAlert(false)
        }, 3000)
    }

    async function postLoadingCheck(isApproved: any) {
        setDisable(true)
        const path = 'Trips/PostLoadingCheck';
        const upload: any = image != '' ? await uploadMedia('Parking In Inspection', image, { 'Content-Type': 'multipart/form-data' }) : ''
        var lrImage: any = '';
        if (upload && upload?.succeeded) {
            lrImage = upload?.data[0].url || ''
        }
        const body = {
            "userID": user.userID,
            "userType": 0,
            "tripID": tripData?.id,
            "remark": remark,
            "isTarpaulinQualityChecked": isTrampaulinChecked == 'Yes' ? true : false,
            "upload": lrImage
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }

        var response = await post(path, body, header);
        setDisable(false)
        if (response.succeeded) {
        setMsg(response.message)
        setShowSuccess(true)
            setTimeout(() => {
                props.closeModal()
            }, 2000)
        } else {
            window.alert(response.message || 'Something went wrong')
        }
    }

    async function postParkIn(isApproved: any) {
        setDisable(true)
        const path = 'Trips/ParkingIn';

        const upload: any = image != '' ? await uploadMedia('Parking In Inspection', image, { 'Content-Type': 'multipart/form-data' }) : ''
        var lrImage: any = '';
        if (upload && upload?.succeeded) {
            lrImage = upload?.data[0].url || ''
        }

        const body = {
            "userID": user.userID,
            "userType": user.userType,
            "tripID": tripData?.id,
            "driverName": tripData?.driverName,
            "driverPhone": tripData?.driverPhone,
            "alternativeDriver": tripData?.alternativeDriver,
            "alternativeDriverPhone": tripData?.alternativePhone,
            "vehicleNumber": tripData?.vehicleNumber,
            "lrNumber": lorryNumber,
            "remark": remark,
            "vehicleStatus": isApproved,
            // "lrImage": 'media.png'
            "lrImage": lrImage 
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await post(path, body, header);

        setDisable(false)
        if (response.succeeded) {
            setMsg(response.message)
            setShowSuccess(true)
            setTimeout(() => {
                props.closeModal()
            }, 2000)

        } else {
            setMsg(response.message || 'Something went wrong')
            setShowSuccess(true)
            setTimeout(() => {
                props.closeModal()
            }, 2000)
        }
    }

    return (
        <>
            {showAlert ? <div className="alert alert-danger">{msg}</div> : <></>}
            {showSuccess ? <div className="alert alert-success">{msg}</div> : <></>}

            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Row>
                    <Col>

                        <Row>
                            <InputView label='Trip Number' value={tripData?.tripNumber || '-'} />
                            <InputView label='Bid / Contract Number' value={tripData?.biddingNumber || '-'} />
                            <InputView label='Shipment Number' value={tripData?.tripNumber || '-'} />
                            <InputView label="Driver's Name" value={tripData?.driverName || '-'} />
                            <InputView label="Driver's Phone Name" value={tripData?.driverPhone || '-'} />
                            <InputView label="Dispatched Quantity" value={tripData?.totalQuantity || '-'} />
                        </Row>

                        <Row className='my-2 mt-3'>
                            <Col>
                                <h6>{props.status == 'parkIn' ? 'Lorry Reciept Number *' : 'Trampaulin Packing Quality Checked'}</h6>
                                {props.status == 'parkIn' ? (
                                    <Form.Control placeholder='Enter lorry number' value={lorryNumber} onChange={(e: any) => setLorryNumber(e.target.value)} />
                                ) : (
                                    <div className='d-flex '>
                                        <Form.Check
                                            label="Yes"
                                            name="yes"
                                            type='radio'
                                            style={{ paddingRight: 10, marginRight: 20 }}
                                            checked={isTrampaulinChecked == 'Yes' ? true : false}
                                            onChange={(e: any) => setIsTrampaulinChecked('Yes')}
                                        />
                                        <Form.Check
                                            label="No"
                                            name="no"
                                            type='radio'
                                            checked={isTrampaulinChecked == 'No' ? true : false}
                                            onChange={(e: any) => setIsTrampaulinChecked('No')}
                                        />
                                    </div>
                                )}

                            </Col>
                            <Col>
                                <h6>{props.status == 'parkIn' ? 'Image *' : 'Upload Trampaulin Image'}</h6>
                                <Form.Control type="file" name="image" id="" onChange={(e: any) => setImage({ file: e.target.files[0], uri: e.target.value })} />
                            </Col>
                        </Row>

                        <h6>Remarks</h6>
                        <Form.Control placeholder='remarks' value={remark} onChange={(e: any) => setRemark(e.target.value)} className='w-50'/>

                        <Row>
                            <Col className='d-flex justify-content-end'>
                                {props.status == 'parkIn' ? (
                                    <span>
                                        <input type="button" value="Reject" style={{ backgroundColor: disable ? 'gray' : 'red' }} className='btn text-white px-5 mt-3 me-2' onClick={() => validate('Rejected')} disabled={disable} />
                                        <input type="button" value="Accept" style={{ backgroundColor: disable ? 'gray' : CommonColors.PRIMARY }} className='btn text-white px-5 mt-3 ' onClick={() => validate('Approved')} disabled={disable} />
                                    </span>
                                ) : (
                                    <input type="button" value="Submit" style={{ backgroundColor: disable ? 'gray' : CommonColors.PRIMARY }} className='btn text-white px-5 mt-3 ' onClick={() => validate('')} disabled={disable} />
                                )}
                            </Col>
                        </Row>
                    </Col >
                </Row >
            )}
        </>
    )
}

export default ParkingIn