import React from 'react'
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/Index';
import { useAppSelector } from '../../../../store/hooks';
import { get } from '../../../../config/services/api/ApiServices';
import TripCard from '../../components/trip_card/Index';
import { CommonStyles } from '../../../../common/styles/CommonStyles';
import NotFound from '../../../notfound/Indesx';
import { OpenToast } from '../../../../components/toast/Index';


export const TripBaseCard = (data: any) => {

    const item = data.data

    const [tripStatus, setTripStatus] = React.useState('')
    const [checkStatus, setCheckeStatus] = React.useState(false)

    const openStatusModal = (status: any) => {
        setTripStatus(status)
        setCheckeStatus(true)
        setTimeout(() => {
            setCheckeStatus(false)
        }, 2000)
    }

    return (
        <Col xl={4} md={6} className='mb-1 p-1'>
            {checkStatus ? <OpenToast text={tripStatus} /> : <></>}
            <Card className='p-2'>
                <Row>
                    <TripCard data={item} openStatusModal={openStatusModal} />
                </Row>
            </Card>
        </Col>
    )
}

const GateInPendingForparkingUser = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const searchText = useAppSelector((state: RootState) => state.search.text);

    const [trips, setTrips] = React.useState<any>([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        getAllTrips()
    }, [searchText])

    const getAllTrips = async () => {
        setLoading(true)
        const path = 'Trips/GetTrips';
        const body = {
            "UserID": user.userID,
            "TransporterID": user.transporterID,
            "UserType": user.userType,
            "Type": 'Gate In Pending',
            "Page": 1,
            "search": searchText,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        setLoading(false)
        if (response.succeeded) {
            // var data = response.data.allTrips;
            // response.data.allTrips.map((item: any) => {
            //     var today = moment(new Date())
            //     var expectedDate = moment(item.expectedLoadingDate, 'DD MMM, YYYY')
            //     expectedDate = moment(expectedDate, 'DD MM YYYY').add(24, 'hours')
            //     item.isDelayed = today.isSameOrAfter(expectedDate)
            // })
            setTrips(response.data.allTrips);
        }
    }

    return (
        <Container fluid>

            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Row>
                    {trips.length > 0 ? (
                        <>
                            {trips.map((item: any) => <TripBaseCard data={item} key={item.id} />)}
                        </>
                    ) : <NotFound />}
                </Row>
            )}
        </Container>
    )
}

export default GateInPendingForparkingUser