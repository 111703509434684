import React from 'react'
import { RootState } from '../../../../../store/Index';
import { useAppSelector } from '../../../../../store/hooks';
import { useSelector } from 'react-redux';
import { get } from '../../../../../config/services/api/ApiServices';
import TextView from '../../text_view/Index';
import { Card, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import { CommonStyles } from '../../../../../common/styles/CommonStyles';
import ImageViewer from 'react-simple-image-viewer';
import InputView from '../../input_disable_view/Index';

const ViewPOD = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);

    const [podDetails, setPodDetails] = React.useState<any>({})

    const [imageViewer, setImageViewer] = React.useState(false)
    const [image, setImage] = React.useState('')

    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        getPodDetails()
    }, [])

    const getPodDetails = async () => {
        setLoading(true)
        const path = 'POD/GetPodDetails';
        const body = {
            "ID": props.tripId,
            "UserID": user.userID,
            "ShippingID": props.shippingId,
            "UserType": user.userType,
        }
        const header = {
            "Content-Type": 'application/json',
            // "UserType": user.userType,
            "Authorization": auth.jwToken
        }
        var response = await get(path, body, header);
        if (response.succeeded) {
            if (response.data.length > 0) {
                setPodDetails(response.data[0]);
                
            } else {
                window.alert('No Data found for this PDO')
            }
        }
        setLoading(false)
    }

    const handleOpenImage = (url: any) => {
        setImage(url)
        setImageViewer(true)
    }

    const handleCloseImageViewer = () => setImageViewer(false)

    return (
        <div className='w-100 p-0 m-0'>
            {loading ? (
                <div style={CommonStyles.loader} className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status" >
                    </Spinner>
                </div>
            ) : (
                <div style={{ position: 'relative' }}>
                    <Row>
                        <InputView label='Trip Number' value={podDetails?.tripNumber || '-'} />
                        <InputView label='Gate in slip number' value={podDetails?.gateInSlipNumber || '-'} />
                        <InputView label="Transporter's name" value={podDetails?.transporterName || '-'} />
                        <InputView label='Vehicle Number' value={podDetails?.vehicleNumber || '-'} />
                        <InputView label='Invoice Number' value={podDetails?.invoiceNumber || '-'} />
                        <InputView label='Customer Name' value={podDetails?.customerName || '-'} />
                        <InputView label='Reporting Date & Time' value={podDetails?.reportingOn || '-'} />
                        <InputView label='Unloading Date & Time' value={podDetails?.unloadingOn || '-'} />
                        {/* <InputView label='Unloading Person Name' value={podDetails?.unloadingPersonName} />
                        <InputView label='Unloading Person Contact Number' value={podDetails?.unloadingPersonPhone} /> */}
                    </Row>

                    <Card className='my-3 p-2'>
                        <Row>
                            <Col><h6>Material Name</h6></Col>
                            <Col><h6>Shortage Quantity</h6></Col>
                            <Col><h6>Damaged Quantity</h6></Col>
                            <Col><h6>Delivered Quantity</h6></Col>
                        </Row>
                        <hr className='p-0 m-0'/>
                        {podDetails?.materials?.length > 0 && podDetails?.materials.map((materials: any, index:any) => {
                            return (
                                <Row className='mt-1' key={index}>
                                    <Col>
                                        <p className='m-0 p-0'>{materials?.materialName}</p>
                                    </Col>
                                    <Col>
                                        <InputView label='' value={materials?.shortageNumber}/>
                                    </Col>
                                    <Col>
                                        <InputView label='' value={materials?.damagedNumber}/>
                                    </Col>
                                    <Col>
                                        <InputView label='' value={materials?.deliveredNumber}/>
                                    </Col>
                                </Row>
                            )
                        })}
                    </Card>

                    <Row className='my-2'>
                        <h6>POD Attached</h6>
                        <Col lg={4} md={6} onClick={() => handleOpenImage(podDetails?.attachedPOD)}>
                            <img src={podDetails?.attachedPOD} alt="POD Attached" style={{ width: 200, height: 200 }} />
                        </Col>
                    </Row>
                </div>
            )
            }

            {
                imageViewer && <ImageViewer
                    src={[image || '']}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={handleCloseImageViewer}
                    backgroundStyle={{ backgroundColor: '#000000b3', padding: '10%' }}
                />
            }
        </div >
    )
}

export default ViewPOD